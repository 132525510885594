import CountUp from "react-countup";
import { Row, Col, Card, Space, Typography, Avatar } from "antd";
import { getCoreValue } from "utils";

const { Text } = Typography;
const CoreValue = ({ data }) => {
  return (
    <Card title="Core Value" bordered={false}>
      <Row gutter={[8, 8]}>
        {data.map((item, index) => (
          <Col span={24} key={index}>
            <Row justify={"space-between"} align={"middle"}>
              <Col>
                <Avatar src={`/core_value/${index + 1}.svg`} size={"large"} />
              </Col>
              <Col align="right">
                <Space direction="vertical" size={2}>
                  <Text type="secondary">
                    <small>{getCoreValue(index)}</small>
                  </Text>
                  <Text strong>
                    <CountUp end={item[`core_${index + 1}`]} separator="," />
                  </Text>
                </Space>
              </Col>
            </Row>
          </Col>
        ))}
      </Row>
    </Card>
  );
};

export default CoreValue;
