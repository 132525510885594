import "./index.css";
import { useEffect, useState } from "react";
import { PageContainer } from "@ant-design/pro-components";
import {
  Row,
  Col,
  Space,
  Avatar,
  Typography,
  Image,
  Tooltip,
  Card,
  Table,
  Tabs,
} from "antd";
import moment from "moment-timezone";
import { Api } from "services";
import { generateUsername } from "utils";
import { CoreValue, NFTMission, Loading } from "components";
import CountUp from "react-countup";
import { useDocumentTitle } from "hooks";

const { Title, Text, Link } = Typography;
const items = [
  {
    label: "Core Value",
    key: "core_value",
  },
  {
    label: "NFT Mission",
    key: "nft_mission",
  },
];

export default function Page() {
  useDocumentTitle("Leaderboard");
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState("core_value");
  const [listCore, setListCore] = useState([]);
  const [listNFT, setListNFT] = useState([]);
  const [totalCore, setTotalCore] = useState();
  const [totalNFT, setTotalNFT] = useState();

  useEffect(() => {
    setLoading(true);
    if (selected === "core_value") {
      fetchDataCoreValue();
    }
    if (selected === "nft_mission") {
      fetchDataNFTMission();
    }
  }, [selected]);

  const fetchDataCoreValue = async () => {
    const response = await Api.Get(`/leaderboard/core_value`);
    if (response.data.status === true) {
      setListCore(response.data.data.list);
      setTotalCore(response.data.data.total);
      setLoading(false);
    }
  };

  const fetchDataNFTMission = async () => {
    const response = await Api.Get(`/leaderboard/nft_mission`);
    if (response.data.status === true) {
      setListNFT(response.data.data.list);
      setTotalNFT(response.data.data.total);
      setLoading(false);
    }
  };

  const columnsCore = [
    {
      title: "Place",
      dataIndex: "",
      key: "",
      align: "center",
      render: (_, __, index) => <Text strong>{index + 4}</Text>,
    },
    {
      title: "Rank",
      dataIndex: "rank",
      key: "rank",
      align: "center",
      render: (item) => (
        <Tooltip title={item.toUpperCase()}>
          <Image
            src={`/rank/${item}.png`}
            alt={item}
            preview={false}
            width={40}
          />
        </Tooltip>
      ),
    },
    {
      title: "Username",
      dataIndex: "",
      key: "",
      width: "250px",
      render: (_, record) => (
        <Space>
          <Avatar src={record.avatar} size={"large"} />
          <Link href={`/profile/${record._id}`} target="_blank">
            {generateUsername(record.company_email)}
          </Link>
        </Space>
      ),
    },
    {
      title: "Core Value",
      dataIndex: "core_value",
      key: "core_value",
      render: (item) => (
        <Space>
          {item?.map((item, index) => (
            <Space key={index} direction="vertical" size={0} align="center">
              <Avatar src={`/core_value/${index + 1}.svg`} size={20} />
              <Text>
                <small>
                  <CountUp end={item} separator="," />
                </small>
              </Text>
            </Space>
          ))}
        </Space>
      ),
    },
    {
      title: "Pts",
      dataIndex: "total",
      key: "total",
      align: "center",
      render: (item) => (
        <Title level={5} style={{ margin: 0 }}>
          <CountUp end={item} separator="," />
        </Title>
      ),
    },
  ];

  const columnsNFT = [
    {
      title: "Place",
      dataIndex: "",
      key: "",
      align: "center",
      render: (_, __, index) => <Text strong>{index + 4}</Text>,
    },
    {
      title: "Username",
      dataIndex: "",
      key: "",
      width: "250px",
      render: (_, record) => (
        <Space>
          <Avatar src={record.avatar} size={"large"} />
          <Link href={`/profile/${record._id}`} target="_blank">
            {generateUsername(record.company_email)}
          </Link>
        </Space>
      ),
    },
    {
      title: "NFT Mission",
      dataIndex: "nft_mission",
      key: "nft_mission",
      render: (item) => (
        <Space>
          {item?.map((item, index) => (
            <Space key={index} direction="vertical" size={0} align="center">
              <Image
                src={`/nft/${item.rarity}.png`}
                alt={item.rarity}
                preview={false}
                width={20}
              />
              <Text>
                <small>
                  <CountUp end={item.total} separator="," />
                </small>
              </Text>
            </Space>
          ))}
        </Space>
      ),
    },
    {
      title: "Pts",
      dataIndex: "total",
      key: "total",
      align: "center",
      render: (item) => (
        <Title level={5} style={{ margin: 0 }}>
          <CountUp end={item} separator="," />
        </Title>
      ),
    },
  ];

  return (
    <PageContainer className="kuber-leaderboard-page">
      <Row gutter={[16, 16]}>
        <Col span={24} align="center">
          <Card bordered={false} bodyStyle={{ padding: "32px 32px 0 " }}>
            <Space direction="vertical">
              <Title level={3} style={{ margin: 0 }}>
                Leaderboard
              </Title>
              <Text type="secondary">
                <small>{`Last updated ${moment().format(
                  "DD/MM/YYYY, HH:mm"
                )}`}</small>
              </Text>
              <Tabs
                activeKey={selected}
                centered
                onChange={(key) => setSelected(key)}
                items={items}
              />
            </Space>
          </Card>
        </Col>

        {loading === true ? (
          <Col span={24}>
            <Loading />
          </Col>
        ) : (
          <Col span={24}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Row gutter={[16, 16]}>
                  <Col
                    span={8}
                    align="center"
                    style={{ marginBlockStart: "32px" }}
                  >
                    <Space direction="vertical">
                      <Image
                        src="/leaderboard/silver.png"
                        alt="silver"
                        preview={false}
                        width={"50%"}
                      />
                      <div className="avatar">
                        {selected === "core_value" ? (
                          <>
                            <Avatar src={listCore[1]?.avatar} />
                            <div className="rank">
                              <Tooltip title={listCore[1]?.rank.toUpperCase()}>
                                <Avatar
                                  src={`/rank/${listCore[1]?.rank}.png`}
                                />
                              </Tooltip>
                            </div>
                          </>
                        ) : (
                          <Avatar src={listNFT[1]?.avatar} />
                        )}
                      </div>
                      <Text>
                        {selected === "core_value" ? (
                          <Link
                            href={`/profile/${listCore[1]?._id}`}
                            target="_blank"
                          >
                            {generateUsername(
                              listCore[1] ? listCore[1].company_email : ""
                            )}
                          </Link>
                        ) : (
                          <Link
                            href={`/profile/${listNFT[1]?._id}`}
                            target="_blank"
                          >
                            {generateUsername(
                              listNFT[1] ? listNFT[1].company_email : ""
                            )}
                          </Link>
                        )}
                      </Text>
                      <Title level={4} style={{ margin: 0 }}>
                        {selected === "core_value" ? (
                          <CountUp end={listCore[1]?.total} separator="," />
                        ) : (
                          <CountUp end={listNFT[1]?.total} separator="," />
                        )}{" "}
                        Pts
                      </Title>

                      <Space>
                        {selected === "core_value"
                          ? listCore[1]?.core_value.map((item, index) => (
                              <Space
                                key={index}
                                direction="vertical"
                                size={0}
                                align="center"
                              >
                                <Avatar
                                  src={`/core_value/${index + 1}.svg`}
                                  size={20}
                                />
                                <Text>
                                  <small>
                                    <CountUp end={item} separator="," />
                                  </small>
                                </Text>
                              </Space>
                            ))
                          : listNFT[1]?.nft_mission.map((item, index) => (
                              <Space
                                key={index}
                                direction="vertical"
                                size={0}
                                align="center"
                              >
                                <Image
                                  src={`/nft/${item.rarity}.png`}
                                  alt={item.rarity}
                                  preview={false}
                                  width={20}
                                />
                                <Text>
                                  <small>
                                    <CountUp end={item.total} separator="," />
                                  </small>
                                </Text>
                              </Space>
                            ))}
                      </Space>
                    </Space>
                  </Col>
                  <Col span={8} align="center">
                    <Space direction="vertical">
                      <Image
                        src="/leaderboard/gold.png"
                        alt="gold"
                        preview={false}
                        width={"50%"}
                      />
                      <div className="avatar">
                        {selected === "core_value" ? (
                          <>
                            <Avatar src={listCore[0]?.avatar} />
                            <div className="rank">
                              <Tooltip title={listCore[0]?.rank.toUpperCase()}>
                                <Avatar
                                  src={`/rank/${listCore[0]?.rank}.png`}
                                />
                              </Tooltip>
                            </div>
                          </>
                        ) : (
                          <Avatar src={listNFT[0]?.avatar} />
                        )}
                      </div>
                      <Text>
                        {selected === "core_value" ? (
                          <Link
                            href={`/profile/${listCore[0]?._id}`}
                            target="_blank"
                          >
                            {generateUsername(
                              listCore[0] ? listCore[0].company_email : ""
                            )}
                          </Link>
                        ) : (
                          <Link
                            href={`/profile/${listNFT[0]?._id}`}
                            target="_blank"
                          >
                            {generateUsername(
                              listNFT[0] ? listNFT[0].company_email : ""
                            )}
                          </Link>
                        )}
                      </Text>
                      <Title level={4} style={{ margin: 0 }}>
                        {selected === "core_value" ? (
                          <CountUp end={listCore[0]?.total} separator="," />
                        ) : (
                          <CountUp end={listNFT[0]?.total} separator="," />
                        )}{" "}
                        Pts
                      </Title>

                      <Space>
                        {selected === "core_value"
                          ? listCore[0]?.core_value.map((item, index) => (
                              <Space
                                key={index}
                                direction="vertical"
                                size={0}
                                align="center"
                              >
                                <Avatar
                                  src={`/core_value/${index + 1}.svg`}
                                  size={20}
                                />
                                <Text>
                                  <small>
                                    <CountUp end={item} separator="," />
                                  </small>
                                </Text>
                              </Space>
                            ))
                          : listNFT[0]?.nft_mission.map((item, index) => (
                              <Space
                                key={index}
                                direction="vertical"
                                size={0}
                                align="center"
                              >
                                <Image
                                  src={`/nft/${item.rarity}.png`}
                                  alt={item.rarity}
                                  preview={false}
                                  width={20}
                                />
                                <Text>
                                  <small>
                                    <CountUp end={item.total} separator="," />
                                  </small>
                                </Text>
                              </Space>
                            ))}
                      </Space>
                    </Space>
                  </Col>
                  <Col
                    span={8}
                    align="center"
                    style={{ marginBlockStart: "32px" }}
                  >
                    <Space direction="vertical">
                      <Image
                        src="/leaderboard/bronze.png"
                        alt="bronze"
                        preview={false}
                        width={"50%"}
                      />
                      <div className="avatar">
                        {selected === "core_value" ? (
                          <>
                            <Avatar src={listCore[2]?.avatar} />
                            <div className="rank">
                              <Tooltip title={listCore[2]?.rank.toUpperCase()}>
                                <Avatar
                                  src={`/rank/${listCore[2]?.rank}.png`}
                                />
                              </Tooltip>
                            </div>
                          </>
                        ) : (
                          <Avatar src={listNFT[2]?.avatar} />
                        )}
                      </div>
                      <Text>
                        {selected === "core_value" ? (
                          <Link
                            href={`/profile/${listCore[2]?._id}`}
                            target="_blank"
                          >
                            {generateUsername(
                              listCore[2] ? listCore[2].company_email : ""
                            )}
                          </Link>
                        ) : (
                          <Link
                            href={`/profile/${listNFT[2]?._id}`}
                            target="_blank"
                          >
                            {generateUsername(
                              listNFT[2] ? listNFT[2].company_email : ""
                            )}
                          </Link>
                        )}
                      </Text>
                      <Title level={4} style={{ margin: 0 }}>
                        {selected === "core_value" ? (
                          <CountUp end={listCore[2]?.total} separator="," />
                        ) : (
                          <CountUp end={listNFT[2]?.total} separator="," />
                        )}{" "}
                        Pts
                      </Title>

                      <Space>
                        {selected === "core_value"
                          ? listCore[2]?.core_value.map((item, index) => (
                              <Space
                                key={index}
                                direction="vertical"
                                size={0}
                                align="center"
                              >
                                <Avatar
                                  src={`/core_value/${index + 1}.svg`}
                                  size={20}
                                />
                                <Text>
                                  <small>
                                    <CountUp end={item} separator="," />
                                  </small>
                                </Text>
                              </Space>
                            ))
                          : listNFT[2]?.nft_mission.map((item, index) => (
                              <Space
                                key={index}
                                direction="vertical"
                                size={0}
                                align="center"
                              >
                                <Image
                                  src={`/nft/${item.rarity}.png`}
                                  alt={item.rarity}
                                  preview={false}
                                  width={20}
                                />
                                <Text>
                                  <small>
                                    <CountUp end={item.total} separator="," />
                                  </small>
                                </Text>
                              </Space>
                            ))}
                      </Space>
                    </Space>
                  </Col>
                </Row>
              </Col>
              <Col md={7}>
                {selected === "core_value" && totalCore ? (
                  <CoreValue data={totalCore} />
                ) : null}
                {selected === "nft_mission" && totalNFT ? (
                  <NFTMission data={totalNFT} />
                ) : null}
              </Col>
              <Col md={17}>
                <Card
                  title="Other Place"
                  bordered={false}
                  bodyStyle={{ padding: 0, overflowX: "auto" }}
                >
                  {selected === "core_value" && listCore.length !== 0 ? (
                    <Table
                      rowKey={"_id"}
                      columns={columnsCore}
                      dataSource={listCore.slice(3, 10)}
                      pagination={false}
                    />
                  ) : null}

                  {selected === "nft_mission" && listNFT.length !== 0 ? (
                    <Table
                      rowKey={"_id"}
                      columns={columnsNFT}
                      dataSource={listNFT.slice(3, 10)}
                      pagination={false}
                    />
                  ) : null}
                </Card>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </PageContainer>
  );
}
