import "./index.css";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Typography,
  Image,
  Card,
  Space,
  Avatar,
  Button,
  Result,
} from "antd";
import { Loading } from "components";
import { Api } from "services";
import { useDocumentTitle } from "hooks";

const { Title, Text, Link } = Typography;

export default function Page() {
  useDocumentTitle("Qr");
  let { id, event } = useParams();
  let navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [code, setCode] = useState("403");
  const [info, setInfo] = useState(null);

  useEffect(() => {
    async function getData(id, event) {
      const response = await Api.Get(`/qr/scan/${id}/${event}`);
      if (response.status === 200) {
        setInfo(response.data.info);
        setCode(response.data.code);
        setLoading(false);
      } else {
        navigate("/login", { replace: true });
      }
    }
    if (id && event) {
      getData(id, event);
    }
  }, [id, event, navigate]);

  const RenderRes = (code, info) => {
    return (
      <Result
        icon={
          <i
            className={`fad ${
              code === "already_receive"
                ? "fa-check-circle fa-2x"
                : "fa-times-circle"
            }  fa-5x`}
            style={{ color: "#ffffff" }}
          ></i>
        }
        title={info.title}
        subTitle={info.subTitle}
        style={{ padding: "0" }}
        extra={[
          <Link href="https://www.bitkuber.io">
            <Button shape="round" size="large" block>
              Back to homepage
            </Button>
          </Link>,
        ]}
      />
    );
  };

  return (
    <Row
      gutter={[16, 16]}
      className="kuber-qr-page"
      justify={"center"}
      align={"middle"}
    >
      <Col xs={24} md={12} lg={8} align="center">
        <Card className="card" bordered={false}>
          <Space direction="vertical" size={"large"}>
            <Space direction="vertical">
              <Space>
                <Avatar src="/icon-512x512.png" size={"large"} />
                <Title style={{ margin: 0 }} level={3}>
                  KUBER
                </Title>
              </Space>
              <Text type="secondary">Complete FinTech Ecosystem</Text>
            </Space>

            {loading === true ? (
              <Loading color={"white"} />
            ) : code === "success" ? (
              <Result
                icon={null}
                title={`You get rewards`}
                subTitle={
                  <Space>
                    {info.map((item) => (
                      <Space direction="vertical">
                        {item.currency && (
                          <>
                            <Avatar
                              size={50}
                              src={`/token/${item.currency}.png`}
                            />
                            <Text strong>{`+${item.amount}`}</Text>
                          </>
                        )}

                        {item.item_id && (
                          <>
                            <Avatar
                              size={50}
                              shape="square"
                              src={`https://www.bitkuber.io/api/static/items/${item.item_id}.png`}
                            />
                            <Text strong>{`+${item.amount}`}</Text>
                          </>
                        )}

                        {item.nft && (
                          <>
                            <Image src={item.nft} width={"50%"} alt="" />
                            <Text strong>
                              ระบบจะทำการ Mint และ Airdrop ไปยัง wallet ของท่าน
                              ในภายหลัง
                            </Text>
                          </>
                        )}
                      </Space>
                    ))}
                  </Space>
                }
                style={{ padding: "0" }}
                extra={[
                  <Link href="https://www.bitkuber.io">
                    <Button shape="round" size="large" block>
                      Back to homepage
                    </Button>
                  </Link>,
                ]}
              />
            ) : (
              RenderRes(code, info)
            )}
          </Space>
        </Card>
      </Col>
    </Row>
  );
}
