import { useState } from "react";
import { DrawerForm, ProTable } from "@ant-design/pro-components";
import { Button, Typography } from "antd";
import { Api } from "services";
import moment from "moment-timezone";

const { Text } = Typography;

const History = ({ columns, route, trigger }) => {
  const [current, setCurrent] = useState(1);
  const [total, setTotal] = useState(1);

  async function fetchData(params) {
    var str = Object.keys(params)
      .map((key) => {
        return "" + key + "=" + params[key];
      })
      .join("&");

    try {
      const result = await Api.Get(
        route + "?" + str,
        localStorage.getItem("token")
      );

      if (result.data.status === true) {
        setCurrent(result.data.info ? result.data.info.current : 1);
        setTotal(result.data.info ? result.data.info.rowCount : 1);
        return result.data;
      }
    } catch (error) {
      alert("Please try again");
    }
  }

  return (
    <DrawerForm
      title="History"
      resize={{
        maxWidth: window.innerWidth * 0.8,
        minWidth: 400,
      }}
      width={600}
      trigger={
        trigger ? (
          trigger
        ) : (
          <Button key="history" icon={<i className="far fa-history"></i>}>
            History
          </Button>
        )
      }
      drawerProps={{
        destroyOnClose: true,
        headerStyle: {
          display: "none",
        },
        footerStyle: {
          display: "none",
        },
      }}
    >
      <ProTable
        columns={columns}
        request={async (params = {}) => {
          return fetchData(params);
        }}
        rowKey="_id"
        pagination={{
          pageSize: 10,
          current: current,
          total: total,
          showSizeChanger: false,
        }}
        size="small"
        search={false}
        dateFormatter="string"
        options={false}
        headerTitle="History"
        toolBarRender={() => [
          <Text key={"date"} type="secondary">
            <small>{`Last updated on: ${moment().format(
              "DD/MM/YYYY, HH:mm"
            )}`}</small>
          </Text>,
        ]}
      />
    </DrawerForm>
  );
};

export default History;
