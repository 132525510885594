import { PageContainer } from "@ant-design/pro-components";
import { Table } from "components";
import { Space, Tag, Avatar, Typography } from "antd";
import { useRef } from "react";
import moment from "moment-timezone";
import { useDocumentTitle } from "hooks";

const { Text } = Typography;

export default function Page() {
  useDocumentTitle("Setting Administrator");
  const actionRef = useRef();
  const columns = [
    {
      title: "Employee",
      dataIndex: "",
      key: "",
      render: (_, record) => (
        <Space>
          <Avatar src={record.avatar} alt="" />
          <Text>{record.company_email}</Text>
        </Space>
      ),
    },

    {
      title: "Entity",
      dataIndex: "",
      key: "",
      align: "center",
      render: (_, record) => <Text>{record.entity_id?.abbreviation}</Text>,
    },

    {
      title: "Emp ID",
      dataIndex: "employee_id",
      key: "emp_id",
      align: "center",
    },

    {
      title: "Employee Type",
      dataIndex: "role",
      key: "role",
      align: "center",
      render: (item) => (
        <Text>
          {item === "admin-kuber" && <Tag color="green">Admin Kuber</Tag>}
          {item === "dev-kuber" && <Tag color="blue">Dev Kuber</Tag>}
        </Text>
      ),
    },

    {
      title: "Join Date",
      dataIndex: "join_date",
      key: "join_date",
      render: (item) => <Text>{moment(item).format("YYYY-MM-DD")}</Text>,
    },

    {
      title: "Probation",
      dataIndex: "confirmation_date",
      key: "confirmation_date",
      align: "center",
      render: (item) => (
        <Text>
          <i
            className={
              new Date(item).getTime() <= new Date(item).getTime()
                ? "fas fa-check-circle primary-color"
                : "far fa-history"
            }
          ></i>
        </Text>
      ),
    },
  ];

  return (
    <PageContainer
      header={{
        title: "Administrator",
        ghost: true,
        breadcrumb: {
          items: [
            {
              href: "/setting",
              title: "Setting",
            },
            {
              title: "Administrator",
            },
          ],
        },
      }}
    >
      <Table
        columns={columns}
        route={"/administrator/list"}
        actionRef={actionRef}
        keyword={`employee`}
      />
    </PageContainer>
  );
}
