import { PageContainer } from "@ant-design/pro-components";
import { Table } from "components";
import { Space, Avatar, Typography } from "antd";
import { useRef } from "react";
import { useDocumentTitle } from "hooks";
import { addressFormat } from "utils";

const { Text, Paragraph } = Typography;

export default function Page() {
  useDocumentTitle("Setting Token");
  const actionRef = useRef();
  const columns = [
    {
      title: "Token Name",
      dataIndex: "",
      key: "",
      render: (_, record) => (
        <Space>
          <Avatar src={`/token/${record.symbol}.png`} alt="" />
          <Text>{record.name}</Text>
        </Space>
      ),
    },

    {
      title: "Symbol",
      dataIndex: "symbol",
      key: "symbol",
      align: "center",
    },

    {
      title: "Contract Address",
      dataIndex: "contractAddress",
      key: "contractAddress",
      render: (item) => (
        <Paragraph
          copyable={{
            text: item,
          }}
          style={{ margin: 0 }}
        >
          <small>{addressFormat(item, 12, 4)}</small>
        </Paragraph>
      ),
    },

    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      align: "center",
    },

    {
      title: "Balance",
      dataIndex: "",
      key: "",
      align: "right",
      render: (_, record) => (
        <Text strong>{record.balance / 10 ** record.decimals}</Text>
      ),
    },

    {
      title: "Balance",
      dataIndex: "",
      key: "",
      align: "right",
      render: (_, record) => (
        <Space>
          <Text strong>{record.balance / 10 ** record.decimals}</Text>
          <Avatar src={`/token/${record.symbol}.png`} size={"small"} />
        </Space>
      ),
    },
  ];

  return (
    <PageContainer
      header={{
        title: "Token",
        ghost: true,
        breadcrumb: {
          items: [
            {
              href: "/setting",
              title: "Setting",
            },
            {
              title: "Token",
            },
          ],
        },
      }}
    >
      <Table
        rowKey="contractAddress"
        columns={columns}
        route={"/token/list"}
        actionRef={actionRef}
        keyword={`employee`}
      />
    </PageContainer>
  );
}
