import axios from "axios";
var BASE_URL = "https://www.bitkuber.io/api";

if (process.env.NODE_ENV !== "production") {
  BASE_URL = "/api";
}

const headers = {
  "Access-Control-Allow-Origin": "*",
  //   "Content-Type": "application/x-www-form-urlencoded",
  "Content-Type": "application/json",

  // application/json; charset=utf-8
};

export async function Get(route, params) {
  try {
    const response = await axios.get(BASE_URL + route);
    return response;
  } catch (error) {
    return error;
  }
}

export async function Post(route, data) {
  try {
    const response = await axios.post(BASE_URL + route, data, headers);
    return response;
  } catch (error) {
    return error;
  }
}

export async function Put(route, data) {
  try {
    const response = await axios.put(BASE_URL + route, data, headers);
    return response;
  } catch (error) {
    return error;
  }
}

export async function All(route) {
  return Promise.all(route.map(fetchData));
  function fetchData(URL) {
    return axios
      .get(BASE_URL + URL)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        return error;
      });
  }
}
