import "./index.css";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PageContainer } from "@ant-design/pro-components";
import {
  Typography,
  Card,
  Row,
  Col,
  Space,
  Tabs,
  Tooltip,
  Avatar,
  Button,
  Popconfirm,
  Divider,
  Modal,
} from "antd";
import moment from "moment-timezone";
import { useDocumentTitle } from "hooks";
import { Api } from "services";
import { Loading } from "components";
import { socket } from "socket";
import { Waiting } from "components";

const { success, warning } = Modal;
const { Title, Text, Paragraph } = Typography;
export default function Page({ employee }) {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [seconds, setSeconds] = useState(0);
  const [intervalId, setIntervalId] = useState(0);
  const [open, setOpen] = useState(true);
  const [waiting, setWaiting] = useState(false);


  useEffect(() => {
    fetchData(id);
  },[]);

  useEffect(() => {
    if(data?.book_type=="seat"){
      socket.emit("join", {id,...employee});
      socket.on("new_selected", (data_socket) => {
        if (data_socket.name === employee.name && data_socket.id === id) {
          setSeconds(data_socket.timer);
        }
        fetchData(id);
      });

      socket.on("booked_already", (data_socket) => {
        if (data_socket.name === employee.name && data_socket.id === id) {
          success({
            centered: true,
            title: "คุณได้ทำการจองคิวเรียบร้อยแล้ว",
            icon: (
              <span className="anticon">
                <i className="fas fa-check-circle"></i>
              </span>
            ),
            content: <Text>กรุณายกเลิกการจอง หากจะทำการจองใหม่</Text>,
            onOk() {
              fetchData(id);
            },
          });
        }
      });

      return () => {
        socket.off("new_selected");
        socket.off("booked_already");
      };
    }
  }, [socket]);

  useDocumentTitle(data ? data.name : "");

  useEffect(() => {
    if (seconds === 60) {
      handleStart();
    }
    if (seconds === 0) {
      handleStop();
    }
  }, [seconds]);

  const handleStart = () => {
    let newIntervalId = setInterval(() => {
      setSeconds((seconds) => seconds - 1);
    }, 1000);

    setIntervalId(newIntervalId);
  };

  const handleStop = () => {
    socket.emit("timeout", { id, employee, timer: 0 });
    clearInterval(intervalId);
  };

  const handleConfirm = () => {
    setWaiting(true);
    socket.emit("booked", { id, employee, timer: 0 });
    clearInterval(intervalId);
  };

  const fetchData = async (id) => {
    const response = await Api.Get(`/booking/info/${id}`);
    if (response.data.status === true) {
      setData(response.data.data);
      setLoading(false);
      setWaiting(false);
    }
  };

  const handleSelected = (parentId, childrenId, key) => {
    var expired = (moment(data.start_time).unix() > moment().unix() && moment(data.end_time).unix() < moment().unix() )
    if(expired===true){
      warning({
        title: "Booking time is over",
        content: "Booking until "+moment(data.end_time).format('YYYY-MM-DD HH:mm:ss'),
      });
    }else{
      if (seconds !== 0) {
        socket.emit("selected", {
          id,
          parentId,
          childrenId,
          key,
          employee,
          timer: seconds,
        });
      } else {
        socket.emit("selected", {
          id,
          parentId,
          childrenId,
          key,
          employee,
          timer: 60,
        });
      }
    }
  };

  const handleCancel = (data) => {
    socket.emit("cancel", {...data,book_id:id});
  };

  const RenderSlotBooked = (data) => (
    <div className="slot booked">
      {data.name === employee.name && (
        <>
          <Popconfirm
            title="ยกเลิกการจอง"
            description="คุณต้องการยกเลิกใช่หรือไม่?"
            onConfirm={() => handleCancel(data)}
            okText="Submit"
            cancelText="Cancel"
          >
            <Button
              size="small"
              shape="circle"
              icon={<i className="far fa-times"></i>}
              danger
            ></Button>
          </Popconfirm>
          <Tooltip title={data.name}>
            <Avatar src={data.avatar} size={"large"} shape="square" />
          </Tooltip>
        </>
      )}
    </div>
  );

  const RenderSlotSelected = (data) => (
    <div className="slot selected">
      {data.name === employee.name && (
        <Tooltip title={data.name}>
          <Avatar src={data.avatar} size={"large"} shape="square" />
        </Tooltip>
      )}
    </div>
  );

  const RenderSlotEmpty = (parentId, childrenId, index) => (
    <div
      className="slot"
      onClick={() => handleSelected(parentId, childrenId, index)}
    ></div>
  );

  const RenderQueue = (data) => (
    <div className="timeline">
      <Row gutter={[16, 16]}>
        {data.timeline.map((item) => (
          <Col key={item._id} span={24}>
            <Row align={"middle"} justify={"space-between"}>
              <Col>
                <Text strong>{item.time}</Text>
              </Col>
              <Col>
                <Space>
                  {item.queue.map((subItem, subIndex) => {
                    if (subItem.status === "booked")
                      return RenderSlotBooked(subItem);
                    else if (subItem.status === "selected")
                      return RenderSlotSelected(subItem);

                    return RenderSlotEmpty(data._id, item._id, subIndex);
                  })}
                </Space>
              </Col>
            </Row>
          </Col>
        ))}
      </Row>
    </div>
  );

  const RenderPopupSeat = () => {
    return (
      <Space
        direction="vertical"
        style={{ textAlign: "center", width: "100%" }}
      >
        <Title level={5}>
          โปรดอ่านก่อนทำการจองเข้าใช้บริการ
        </Title>
        <Paragraph>
          "เนื่องจากในบางบริการมีข้อจำกัดด้านสถานที่อาจทำให้รอบการรักษา<br />มีการคละระหว่าง
          เพศสภาพ กรุณาอ่านข้อมูลให้ครบถ้วนก่อนการตัดสินใจในการจอง”
        </Paragraph>
        <Button shape="round" type="primary" onClick={() => setOpen(false)}>
          Accept
        </Button>
      </Space>
    )
  }
  const RenderFormSeat = () => {
    return (
        <Card bordered={false} className="card">
          <Tabs
            defaultActiveKey="1"
            type="card"
            items={data.timeline.map((item) => ({
              label: moment(new Date(item.date)).format("DD MMM YYYY"),
              key: item.date,
              children: RenderQueue(item),
            }))}
          />
        </Card>
      )
  }

  const RenderPopupRandom = () => {
    return (
      <Space
        direction="vertical"
        style={{ textAlign: "center", width: "100%" }}
      >
        <Title level={5}>
          โปรดอ่านก่อนทำการจองตั๋ว
        </Title>
        <Paragraph style={{ textAlign: "left", width: "100%" }}>
          <ul>
            <li>จองตั๋วได้ฟรี 1 คน ต่อ 1 ที่นั่ง ไม่สามารถโอนสิทธิให้ผู้อื่นได้ </li>
            <li>สามารถยกเลิกการจองตั๋วได้ภายในช่วงเวลาที่ระบบเปิดทำการเท่านั้น</li>
            <li>หากทำการจองตั๋วแล้วจะต้องมาขึ้นรถโดยสารตามวันและเวลาที่กำหนด</li>
            <li>รถมินิบัสออกเดินทางเวลา 11:00 น. กรุณาขึ้นรถก่อน 15 นาที</li>
          </ul>
          <br />
          *หากมีความจำเป็นต้องยกเลิกตั๋วเดินทาง กรุณาแจ้งให้ People Team ทราบล่วงหน้าก่อนเดินทาง อย่างน้อย 3 วัน 
          เพื่อให้ชาว Bitkuber ได้รับประโยชน์อย่างสูงสุด
        </Paragraph>
        <Button shape="round" type="primary" onClick={() => setOpen(false)}>
          Accept
        </Button>
      </Space>
    )
  }

  const onBookCreate = async(item) => {
    console.log('====>',item)
    
    try {
      const result = await Api.Post("/booking/book_add/" + id+"/"+item._id?.toString(),{label:item.label});

      if (result.data.status === true) {
        fetchData(id);

      }else{
        warning({
          title: "You cannot booking.",
          icon: (
            <span className="anticon">
              <i className="fas fa-times-circle"></i>
            </span>
          ),
          content: <Text>{result.data.msg}</Text>
        });
      }
    } catch (err) {
        warning({
          title: "Error.",
          content: <Text>Please try again</Text>
        });
    }

  }
  const onBookRemove = async(item) => {
    console.log('====>',item)
    
    try {
      const result = await Api.Post("/booking/book_del/" + id,{label:item.label});

      if (result.data.status === true) {
        fetchData(id);
      }
    } catch (error) {
      alert("Please try again");
    }

  }

  const RenderFormRandom = () => {
    return (
      <>
          {data.book_data.map(item=>{
            return (

                    <Card
                      bordered={false} className="card"
                      style={{width:"100%",marginBottom:10}}
                    >
                      <Row justify={"space-between"} align={"middle"}>
                        <Col span={6}>
                          <Space size={0}>
                            <Title level={4} style={{margin:0}}>{item.label}</Title>
                          </Space>
                        </Col>
                        <Col span={18} style={{textAlign:"right"}} >
                          <Space
                            split={<Divider type="vertical" />}
                            size={"small"}
                          >
                            <Space
                              direction="vertical"
                              size={2}
                              style={{ textAlign: "center", width: "60px" }}
                            >
                              <Text>Booked</Text>
                              <Text strong={"true"}>{item.queue.length || 0}</Text>
                            </Space>
                            <Space
                              direction="vertical"
                              size={2}
                              style={{ textAlign: "center", width: "60px" }}
                            >
                              <Text>Total</Text>
                              <Text strong={"true"}>{item.limit || 0}</Text>
                            </Space>
                            <Space
                              direction="vertical"
                              size={2}
                              style={{ textAlign: "right" }}
                            >
                            {item.queue.includes(employee.name)? (
                              <Popconfirm
                                title="ยกเลิกการจอง"
                                description="คุณต้องการยกเลิกการจองใช่หรือไม่?"
                                onConfirm={() => onBookRemove(item)}
                                okText="Submit"
                                cancelText="Cancel"
                              >
                                <Button icon={<i className="far fa-times"></i>} size={'large'} danger>Cancel</Button>
                              </Popconfirm>
                            ) : (
                              <Popconfirm
                                title="ยืนยันการจอง"
                                description="คุณต้องการยืนยันการจองใช่หรือไม่?"
                                onConfirm={() => onBookCreate(item)}
                                okText="Submit"
                                cancelText="Cancel"
                              >
                                <Button type="primary" size={'large'} disabled={item.limit==item.queue.length? true : false}>Booking</Button>
                              </Popconfirm>
                            )}
                            </Space>
                          </Space>
                        </Col>
                      </Row>
                    </Card>

            )
          })}
          <br /><br />
          <Paragraph style={{ textAlign: "left", width: "100%" }}>
          <ul>
            <li>จองตั๋วได้ฟรี 1 คน ต่อ 1 ที่นั่ง ไม่สามารถโอนสิทธิให้ผู้อื่นได้ </li>
            <li>สามารถยกเลิกการจองตั๋วได้ภายในช่วงเวลาที่ระบบเปิดทำการเท่านั้น</li>
            <li>หากทำการจองตั๋วแล้วจะต้องมาขึ้นรถโดยสารตามวันและเวลาที่กำหนด</li>
            <li>รถมินิบัสออกเดินทางเวลา 11:00 น. กรุณาขึ้นรถก่อน 15 นาที</li>
          </ul>
          <br />
          *หากมีความจำเป็นต้องยกเลิกตั๋วเดินทาง กรุณาแจ้งให้ People Team ทราบล่วงหน้าก่อนเดินทาง อย่างน้อย 3 วัน 
          เพื่อให้ชาว Bitkuber ได้รับประโยชน์อย่างสูงสุด
        </Paragraph>
      </>
    )
  }

  if (loading) return <Loading />;

  return (
    <PageContainer
      className="kuber-booking-page"
      header={{
        title: data?.name || '',
        ghost: true,
        breadcrumb: {
          items: [
            {
              href: "/profile",
              title: "My Profile",
            },
            {
              href: "/booking",
              title: "Booking",
            },
            {
              title: data?.name || '',
            },
          ],
        },
      }}
    >
      <Card bordered={false} bodyStyle={{ padding: "32px" }}>
        <Row gutter={[32, 32]}>
          <Col xs={24} sm={24} md={12}>
            <Space direction="vertical">

              <Card
                className="card"
                bodyStyle={{ minHeight: "150px", overflow: "auto" }}
              >
                <Paragraph>
                  <div dangerouslySetInnerHTML={{ __html: data.description }} />
                </Paragraph>
              </Card>
            </Space>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Space direction="vertical" style={{width:'100%'}}>
              {data?.book_type && data?.book_type=="seat"? <RenderFormSeat/> : <RenderFormRandom/>}
            </Space>
          </Col>
        </Row>
      </Card>

      {seconds !== 0 && (
        <>
          <div className="countdown">
            <Space size={"small"}>
              <Text>กรุณากด</Text>
              <Button shape="round" type="primary" onClick={handleConfirm}>
                ยืนยัน
              </Button>
              <Text>
                ภายใน{" "}
                <Text type="success" strong>
                  {seconds}
                </Text>{" "}
                วินาที
              </Text>
            </Space>
          </div>
          <div className="countdown-mobile">
            <Space size={"small"}>
              <Text>กด</Text>
              <Button
                size="small"
                type="primary"
                shape="round"
                onClick={handleConfirm}
              >
                ยืนยัน
              </Button>
              <Text>
                ภายใน{" "}
                <Text type="success" strong>
                  {seconds}
                </Text>{" "}
                วินาที
              </Text>
            </Space>
          </div>
        </>
      )}

      {open === true && (
        <Modal
          open={open}
          footer={null}
          title={null}
          closable={false}
          centered
        >
          {data?.book_type && data?.book_type=="seat"? <RenderPopupSeat/> : <RenderPopupRandom/>}
        </Modal>
      )}

      {waiting === true && <Waiting open={waiting} />}
    </PageContainer>
  );
}
