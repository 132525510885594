import { useEffect, useState } from "react";
import { ProTable, LightFilter } from "@ant-design/pro-components";
import { Api } from "services";

export const waitTimePromise = async (time = 100) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, time);
  });
};

export const waitTime = async (time = 100) => {
  await waitTimePromise(time);
};

const Table = ({ rowKey = "_id", columns, route, actionRef, keyword, filter }) => {
  const [current, setCurrent] = useState(1);
  const [total, setTotal] = useState(1);
  const [type, setType] = useState("");

  useEffect(() => {
    if (type !== "") {
      actionRef.current.reload();
    }
  }, [type]);

  async function fetchData(params) {
    var str = Object.keys(params)
      .map((key) => {
        return "" + key + "=" + params[key];
      })
      .join("&");
    if (type !== "") {
      str = str + `&type=${type}`;
    }

    try {
      const result = await Api.Get(
        route + "?" + str,
        localStorage.getItem("token")
      );

      if (result.data.status === true) {
        setCurrent(result.data.info ? result.data.info.current : 1);
        setTotal(result.data.info ? result.data.info.rowCount : 1);

        return result.data;
      }
    } catch (error) {
      alert("Please try again");
    }
  }

  return (
    <ProTable
      size="small"
      actionRef={actionRef}
      columns={columns}
      rowKey={rowKey}
      request={async (params = {}) => {
        await waitTime(1000);
        return fetchData(params);
      }}
      options={{
        reload: () => {
          actionRef.current.reset();
        },
        search: {
          placeholder: `Please enter ${keyword}`,
        },
      }}
      search={false}
      pagination={{
        pageSize: 20,
        current: current,
        total: total,
        showSizeChanger: false,
      }}
      toolbar={{
        filter: (
          <LightFilter
            initialValues={{
              type: "token",
            }}
            onFinish={async (values) => {
              setType(values.type);
            }}
          >
            {filter}
          </LightFilter>
        ),
      }}
    />
  );
};

export default Table;
