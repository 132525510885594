import { useState, useEffect } from "react";

import { PageContainer, CheckCard, DrawerForm } from "@ant-design/pro-components";
import { UserOutlined } from '@ant-design/icons';
import { Loading } from "components";
import {
  Row,
  Col,
  Button,
  Space,
  Avatar,
  Typography,
  Image,
  Card,
  Tabs,
  Divider,
  List,
  Table,
} from "antd";
import { Api } from "services";
import { Link } from "react-router-dom";
import { useDocumentTitle } from "hooks";

const numberFormatter = Intl.NumberFormat("en-US");
const { Title, Text } = Typography;

export default function Page() {
  useDocumentTitle("DAO");
  const [loading, setLoading] = useState(true);
  const [myDao, setMyDao] = useState([]);
  const [otherDao, setOtherDao] = useState([]);
  const [place, setPlace] = useState([]);
  const [active, setActive] = useState(1);
  const [list, setList] = useState([]);
  const [listScore, setListScore] = useState([]);
  const [showChart, setShowChart] = useState(false);
  const [chartRunning, setChartRunning] = useState(false);
  const [chartActive, setChartActive] = useState(false);
  const [chartDataRunning, setChartDataRunning] = useState({top:0,list:[]});
  const [chartDataActive, setChartDataActive] = useState({top:0,list:[]});
  const [topRunningBearish, setTopRunningBearish] = useState();
  const [topRunningBullish, setTopRunningBullish] = useState();
  const [topRunningMoon, setTopRunningMoon] = useState();
  const [topRunningLambo, setTopRunningLambo] = useState();

  const columnsTop = [
    {
      title: "Place",
      dataIndex: "place",
      key: "place",
      align: "center",
      render: (item) => (
        <Space>
          {item < 4 ? (
            <Image
              src={`/top/${item}.png`}
              alt="gold"
              width={36}
              preview={false}
            />
          ) : (
            <Title level={5} style={{ margin: 0, lineHeight: "40px" }}>
              {item}
            </Title>
          )}
        </Space>
      ),
    },
    {
      title: "Username",
      dataIndex: "",
      key: "",
      render: (_, record) => (
        <Space>
          <Avatar src={record.avatar} style={{ backgroundColor: '#1677ff' }} icon={<UserOutlined />} />
          <Text>{record.company_email?.split("@")[0]}</Text>
        </Space>
      ),
    },
    {
      title: "Total (Km)",
      dataIndex: "total",
      key: "total",
      align: "right",
      render: (item) => (
        <Text type="secondary">{numberFormatter.format(item)}</Text>
      ),
    },
  ];

  useEffect(() => {
    fetchData();
    fetchDataLeaderboard();
    fetchDataGame();
    fetchDataTopRunning("667f6b93ffc480455d8090ec");
    fetchDataTopRunning("667f6b93ffc480455d8090ef");
    fetchDataTopRunning("667f6b94ffc480455d8090f7");
    fetchDataTopRunning("667f6b93ffc480455d8090f2");

  }, []);

  useEffect(() => {
    if(topRunningBearish?.total && topRunningBullish?.total && topRunningMoon?.total && topRunningLambo?.total){
      console.log('hit===>')
      var chart_running = [
        {
          logo:'Bullish',
          score:topRunningBullish?.total,
          all:topRunningBullish?.top,
          total_member: topRunningBullish?.total_member,
          total_active: topRunningBullish?.total_active,
          active_percent:parseFloat(((parseFloat(topRunningBullish?.total_active)/parseFloat(topRunningBullish?.total_member))*100).toFixed(0))
        },
        {
          logo:'Bearish',
          score:topRunningBearish?.total,
          all:topRunningBearish?.top,
          total_member: topRunningBearish?.total_member,
          total_active: topRunningBearish?.total_active,
          active_percent:parseFloat(((parseFloat(topRunningBearish?.total_active)/parseFloat(topRunningBearish?.total_member))*100).toFixed(0))
        },
        {
          logo:'Moon',
          score:topRunningMoon?.total,
          all:topRunningMoon?.top,
          total_member: topRunningMoon?.total_member,
          total_active: topRunningMoon?.total_active,
          active_percent:parseFloat(((parseFloat(topRunningMoon?.total_active)/parseFloat(topRunningMoon?.total_member))*100).toFixed(0))
        },
        {
          logo:'Lambo',
          score:topRunningLambo?.total,
          all:topRunningLambo?.top,
          total_member: topRunningLambo?.total_member,
          total_active: topRunningLambo?.total_active,
          active_percent:parseFloat(((parseFloat(topRunningLambo?.total_active)/parseFloat(topRunningLambo?.total_member))*100).toFixed(0))
        },
      ].sort((a, b) => b.score - a.score)


      var chart_active = chart_running.sort((a, b) => b.active_percent - a.active_percent)


      const chart_max = Math.max(...chart_running.map(o => o.score))
      const raw_max = (chart_max).toString().split('.')[0]
      const raw_first = parseFloat(raw_max[1]+3)>=10? parseFloat(raw_max[0])+1 : parseFloat(raw_max[0])
      const raw_sec = parseFloat(raw_max[1]+3)>10? 5 : 0
      const max_use = [raw_first,raw_sec,'0'.repeat(raw_max.length-2)].join('')
      const max_section = (parseFloat(max_use)/4).toFixed(0)

      chart_running = chart_running.map(item=>{
        return {...item,percent: ((parseFloat(item.score)/parseFloat(max_use))*100).toFixed(0)+'%' }
      })
      console.log(chart_running)





      const max_section_active = (100/4).toFixed(0)
      chart_active = chart_active.map(item=>{
        return {...item,percent: item.active_percent+'%' }
      })
      console.log(chart_active)

      setChartDataRunning({top:parseFloat(max_use),list:[0,max_section,max_section*2,max_section*3,max_section*4]})
      setChartDataActive({top:parseFloat(100),list:[0,max_section_active,max_section_active*2,max_section_active*3,max_section_active*4]})
      console.log('====>',chart_running,max_use)
      setChartRunning(chart_running)
      setChartActive(chart_active)
      setShowChart(true)
    }
  }, [topRunningBearish,topRunningBullish,topRunningMoon,topRunningLambo]);


  const fetchData = async () => {
    const response = await Api.Get(`/dao/list`);
    if (response.data.status === true) {
      setMyDao(response.data.myDao);
      setOtherDao(response.data.otherDao);
      setLoading(false);
    }
  };

  const fetchDataLeaderboard = async () => {
    const response = await Api.Get(`/dao/leaderboard`);
    if (response.data.status === true) {
      setPlace(response.data.data);
    }
  };

  const fetchDataGame = async () => {
    const response = await Api.Get(`/dao/game`);
    if (response.data.status === true) {
      setList(response.data.data);
      console.log('score===>',Object.keys(response.data.total).map(item=>{
        return {dao:item,point:response.data.total[item]}
      }))
      setListScore(Object.keys(response.data.total).map(item=>{
        return {dao:item,point:response.data.total[item]}
      }).sort((a, b) => b.point - a.point))
    }
  };

  const fetchDataTopRunning = async (id) => {
    const response = await Api.Get(`/dao/top_running/${id}`);
    if (response.data.status === true) {
      if (id === "667f6b93ffc480455d8090ec")
        setTopRunningBearish(response.data.data);
      if (id === "667f6b93ffc480455d8090ef")
        setTopRunningBullish(response.data.data);
      if (id === "667f6b94ffc480455d8090f7")
        setTopRunningMoon(response.data.data);
      if (id === "667f6b93ffc480455d8090f2")
        setTopRunningLambo(response.data.data);
    }
  };

  const getRank = (item,key) => {
    const array = Object.keys(item).map(node=>item[node]).sort((a, b) => b - a)
    let index = array.indexOf(item[key]);
    return index+1 
  }

  const RenderDrawer = ({ children, data, name }) => {
    return (
        <DrawerForm
          title={name}
          trigger={children}
          resize={{
            maxWidth: window.innerWidth * 0.8,
            minWidth: 300,
          }}
          width={600}
          drawerProps={{
            destroyOnClose: true,
          }}
          submitter={false}
        >
          <Table
            rowKey={"_id"}
            columns={columnsTop}
            dataSource={data}
            size="small"
            pagination={false}
          />
        </DrawerForm>
    );
  };

  const RenderStats=(listData)=>{
    return (<>
      <Col span={24}>
        <Row justify={"center"}>
        <Space size={30} align="end">
          {Object.keys(listData.data).map((node,index)=>{
            return (
          
           
                  <Image
                    src={`/activity/${node}_${getRank(listData.data,node)}.png`}
                    width={listData.data[node]+"%"}
                    preview={false}
                    style={{margin:'0 auto',width:70}}
                  />
            
            
            )
          })}
        </Space>
        </Row>
      </Col>
      <Col span={24}>
        <Card bordered={false} bodyStyle={{ padding: 0 }}>
          <List
            itemLayout="horizontal"
            dataSource={
              Object.keys(listData.data).map(node=>{
                return {
                  dao:node,score:listData.data[node],rank:getRank(listData.data,node)
                }
              }).sort((a, b) => a.rank - b.rank)
            }
            size="small"
            renderItem={(item, index) => (
              <List.Item key={index} >

                <List.Item.Meta
                  avatar={
                    <Space>
                      <Title
                        level={5}
                        style={{ margin: 0, width: "16px" }}
                      >
                        {getRank(listData.data,item.dao)}
                      </Title>

                      <Space>
                        <Image
                          src={`/activity/${item.dao}.png`}
                          width={32}
                          preview={false}
                        />
                      </Space>

                      <Text>{item.dao}</Text>
                    </Space>
                  }
                />
                <Space><Text strong={"true"}>{numberFormatter.format(item.score)}</Text></Space>
              </List.Item>
            )}
          />
        </Card>
      </Col>
    </>)
  }

  if (loading) return <Loading />;

  return (
    <PageContainer className="kuber-dao-page">
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12} lg={6}>
          <CheckCard
            style={{ width: "100%" }}
            description={
              <Space
                direction="vertical"
                style={{ display: "flex", textAlign: "center" }}
                size={"large"}
              >
                <Image
                  src={`/dao/${myDao[0]?.name}.png`}
                  preview={false}
                  width={"50%"}
                />
                <Title level={5} style={{ margin: 0 }}>
                  {myDao[0]?.name}
                </Title>

                <Space size={"large"}>
                  <Space direction="vertical" size={0}>
                    <Text strong={"true"}>{myDao[0]?.activies}</Text>
                    <Text type="secondary">
                      <small>{"activies".toUpperCase()}</small>
                    </Text>
                  </Space>
                  <Space direction="vertical" size={0}>
                    <Text strong={"true"}>{myDao[0]?.transactions}</Text>
                    <Text type="secondary">
                      <small>{"transactions".toUpperCase()}</small>
                    </Text>
                  </Space>
                </Space>

                <Space align="center">
                  <Avatar.Group>
                    {myDao[0]?.sample.map((item) => (
                      <Avatar key={item._id} src={item.avatar} style={{ backgroundColor: '#1677ff' }} icon={<UserOutlined />} />
                    ))}
                  </Avatar.Group>
                  <Text strong={"true"}>{`+ ${myDao[0]?.members - 5}`}</Text>
                </Space>

                <Link to={`/dao/${myDao[0]?._id}`}>
                  <Button type="primary" shape="round" block>
                    View My DAO
                  </Button>
                </Link>
              </Space>
            }
            checked
          />
        </Col>
        {otherDao.map((item) => (
          <Col key={item._id} xs={24} sm={12} lg={6}>
            <Card bordered={false}>
              <Space
                direction="vertical"
                style={{ display: "flex", textAlign: "center" }}
                size={"large"}
              >
                <Image
                  src={`/dao/${item.name}.png`}
                  preview={false}
                  width={"50%"}
                />
                <Title level={5} style={{ margin: 0 }}>
                  {item.name}
                </Title>

                <Space size={"large"}>
                  <Space direction="vertical" size={0}>
                    <Text strong={"true"}>{item.activies}</Text>
                    <Text type="secondary">
                      <small>{"activies".toUpperCase()}</small>
                    </Text>
                  </Space>
                  <Space direction="vertical" size={0}>
                    <Text strong={"true"}>{item.transactions}</Text>
                    <Text type="secondary">
                      <small>{"transactions".toUpperCase()}</small>
                    </Text>
                  </Space>
                </Space>

                <Space align="center">
                  <Avatar.Group>
                    {item.sample.map((item) => (
                      <Avatar key={item._id} src={item.avatar}style={{ backgroundColor: '#1677ff' }} icon={<UserOutlined />} />
                    ))}
                  </Avatar.Group>
                  <Text strong={"true"}>{`+ ${item.members - 5}`}</Text>
                </Space>

                <Button type="link" href={`/dao/${item._id}`} target="_blank">
                  View
                </Button>
              </Space>
            </Card>
          </Col>
        ))}












        <Col xs={24} md={12}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Title level={4} style={{ margin: 0 }}>
                Leader Board
              </Title>
            </Col>
            <Col span={24}>
              <Row gutter={[8, 8]}>
                {listScore.map((item, index) => (
                  <Col span={24} key={index}>
                    <Card
                      className={"card-leader other"}
                      bodyStyle={{ padding: "4px 16px 4px 4px" }}
                      bordered={false}
                    >
                      <Row justify={"space-between"} align={"middle"}>
                        <Col>
                          <Space size={0}>
                            <Title level={4}>{index + 1}</Title>
                            <Image
                              src={`/activity/${item.dao}-neon.png`}
                              width={80}
                              preview={false}
                            />
                          </Space>
                        </Col>
                        <Col>
                          <Space
                            split={<Divider type="vertical" />}
                            size={"small"}
                          >
                            <Space
                              direction="vertical"
                              size={2}
                              style={{ textAlign: "center", width: "60px" }}
                            >
                              <small>Badges</small>
                              <Text strong={"true"}>{numberFormatter.format(item.badge || 0)}</Text>
                            </Space>
                            <Space
                              direction="vertical"
                              size={2}
                              style={{ textAlign: "center", width: "60px" }}
                            >
                              <small>Points</small>
                              <Text strong={"true"}>{numberFormatter.format(item.point || 0)}</Text>
                            </Space>
                            <Space
                              direction="vertical"
                              size={2}
                              style={{ textAlign: "center", width: "60px" }}
                            >
                              <small>Total</small>
                              <Text strong={"true"}>{numberFormatter.format((item.point || 0) + (item.badge || 0))}</Text>
                            </Space>
                          </Space>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs={24} md={12}>
          <Row gutter={[16, 8]}>
              <Tabs
              style={{width:'100%'}}
                items={list.map(item=>{
                  return {
                    label: item.title,
                    key: item._id.toString(),
                    children: RenderStats(item)
                  }
                })}
              />
          </Row>
        </Col>





























        <Col span={24}>
          <Row gutter={[16, 16]}>
          <Title level={3} style={{ margin: '0 0 0 20px' }}>
            Running
          </Title>
          <div className="chart_team">
              {chartRunning && chartRunning.map(item=>{
                return (
                  <div className={'team '+item.logo.toLowerCase()}>
                      <img src={`/dao/${item.logo}.png`} alt={item.logo+' Team'}/>
                      <div className="team-bar">
                          <div className="progress" style={{width: `${item.percent}`}}></div>
                          <div className="label"><Text strong>{item.score} Km.</Text></div>
                      </div>
                  </div>
                )
              })}

                <div className="scale">
                  {chartDataRunning.list.map(item=>{
                    return (<span><Text>{item}Km.</Text></span>)
                  })}
                </div>
              </div>
          </Row>
        </Col>
        



        <Col span={24}>
          <Row gutter={[16, 16]}>
          <Title level={3} style={{ margin: '0 0 0 20px' }}>
            Paticipants Rate
          </Title>
          <div className="chart_team">
              {chartActive && chartActive.map(item=>{
                return (
                  <RenderDrawer data={item.all} name={item.logo}>
                  <div className={'team '+item.logo.toLowerCase()} style={{cursor:'pointer'}}>
                      <img src={`/dao/${item.logo}.png`} alt={item.logo+' Team'}/>
                      <div className="team-bar">
                          <div className="progress" style={{width: `${item.percent}`}}></div>
                          <div className="label"><Text strong>{item.percent}</Text></div>
                      </div>
                  </div>
                  </RenderDrawer>
                )
              })}

                <div className="scale">
                  {chartDataActive.list.map(item=>{
                    return (<span><Text>{item} %.</Text></span>)
                  })}
                </div>
              </div>
          </Row>
        </Col>





        <Col span={24}>
          <Row gutter={[16, 16]}>
            <Col xs={24} md={12}>
              <Card
                title={
                  <Row justify={"space-between"} align={"middle"}>
                    <Col>
                      <Space>
                        <Image
                          src="/dao/Bearish.png"
                          alt="Bearish"
                          preview={false}
                          width={40}
                        />
                        <Title level={5} style={{ margin: 0 }}>
                          Top 5 Running
                        </Title>
                      </Space>
                    </Col>

                    <Col>
                      <Text>
                        <small>{`All ${
                          topRunningBearish
                            ? numberFormatter.format(topRunningBearish.total)
                            : ""
                        } Km`}</small>
                      </Text>
                    </Col>
                  </Row>
                }
                bodyStyle={{ padding: 0, overflowX: "auto" }}
                bordered={false}
              >
                <Table
                  rowKey={"_id"}
                  columns={columnsTop}
                  dataSource={topRunningBearish?.top?.slice(0, 5)}
                  size="small"
                  pagination={false}
                />
              </Card>
            </Col>
            <Col xs={24} md={12}>
              <Card
                title={
                  <Row justify={"space-between"} align={"middle"}>
                    <Col>
                      <Space>
                        <Image
                          src="/dao/Bullish.png"
                          alt="Bullish"
                          preview={false}
                          width={40}
                        />
                        <Title level={5} style={{ margin: 0 }}>
                          Top 5 Running
                        </Title>
                      </Space>
                    </Col>
                    <Col>
                      <Text>
                        <small>{`All ${
                          topRunningBullish
                            ? numberFormatter.format(topRunningBullish.total)
                            : ""
                        } Km`}</small>
                      </Text>
                    </Col>
                  </Row>
                }
                bodyStyle={{ padding: 0, overflowX: "auto" }}
                bordered={false}
              >
                <Table
                  rowKey={"_id"}
                  columns={columnsTop}
                  dataSource={topRunningBullish?.top?.slice(0, 5)}
                  size="small"
                  pagination={false}
                />
              </Card>
            </Col>
            <Col xs={24} md={12}>
              <Card
                title={
                  <Row justify={"space-between"} align={"middle"}>
                    <Col>
                      <Space>
                        <Image
                          src="/dao/Moon.png"
                          alt="Moon"
                          preview={false}
                          width={40}
                        />
                        <Title level={5} style={{ margin: 0 }}>
                          Top 5 Running
                        </Title>
                      </Space>
                    </Col>
                    <Col>
                      <Text>
                        <small>{`All ${
                          topRunningMoon
                            ? numberFormatter.format(topRunningMoon.total)
                            : ""
                        } Km`}</small>
                      </Text>
                    </Col>
                  </Row>
                }
                bodyStyle={{ padding: 0, overflowX: "auto" }}
                bordered={false}
              >
                <Table
                  rowKey={"_id"}
                  columns={columnsTop}
                  dataSource={topRunningMoon?.top?.slice(0, 5)}
                  size="small"
                  pagination={false}
                />
              </Card>
            </Col>
            <Col xs={24} md={12}>
              <Card
                title={
                  <Row justify={"space-between"} align={"middle"}>
                    <Col>
                      <Space>
                        <Image
                          src="/dao/Lambo.png"
                          alt="Lambo"
                          preview={false}
                          width={40}
                        />
                        <Title level={5} style={{ margin: 0 }}>
                          Top 5 Running
                        </Title>
                      </Space>
                    </Col>
                    <Col>
                      <Text>
                        <small>{`All ${
                          topRunningLambo
                            ? numberFormatter.format(topRunningLambo.total)
                            : ""
                        } Km`}</small>
                      </Text>
                    </Col>
                  </Row>
                }
                bodyStyle={{ padding: 0, overflowX: "auto" }}
                bordered={false}
              >
                <Table
                  rowKey={"_id"}
                  columns={columnsTop}
                  dataSource={topRunningLambo?.top?.slice(0, 5)}
                  size="small"
                  pagination={false}
                />
              </Card>
            </Col>
          </Row>
        </Col>




        
      </Row>



    </PageContainer>
  );
}
