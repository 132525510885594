import { Row, Col, Card, Space, Typography, Avatar } from "antd";
import { generateUsername, getAvengerRole } from "utils";

const { Text, Link } = Typography;
const Avenger = ({ data }) => {
  return (
    <Card title="Avenger" bordered={false}>
      <Row gutter={[8, 8]}>
        {data.map((item, index) => (
          <Col key={item._id} span={24}>
            <Row justify={"space-between"} align={"middle"}>
              <Col>
                <Avatar src={item.avatar} size={"large"} />
              </Col>
              <Col align="right">
                <Space direction="vertical" size={2}>
                  <Link href={`/profile/${item._id}`} target="_blank" strong>
                    {generateUsername(item.company_email)}
                  </Link>

                  <Text type="secondary">
                    <small>{getAvengerRole(index)}</small>
                  </Text>
                </Space>
              </Col>
            </Row>
          </Col>
        ))}
      </Row>
    </Card>
  );
};

export default Avenger;
