import { Modal, Space, Typography } from "antd";

const { Title } = Typography;
const Waiting = ({ open }) => {
  return (
    <Modal open={open} centered closable={false} footer={null}>
      <Space direction="vertical" style={{ display: "flex" }}>
        <Title align="center" level={3}>
          Please wait a moment ...
        </Title>
        <div className="container-waiting">
          <div className="📦"></div>
          <div className="📦"></div>
          <div className="📦"></div>
          <div className="📦"></div>
          <div className="📦"></div>
        </div>
      </Space>
    </Modal>
  );
};

export default Waiting;
