import { PageContainer } from "@ant-design/pro-components";
import { Table as DataTable, Download } from "components";
import { Space, Drawer, Tag, Table, Avatar, Typography, Tooltip, Image } from "antd";
import { useRef, useState } from "react";
import moment from "moment-timezone";
import { useDocumentTitle } from "hooks";
import { generateUsername } from "utils";
import { Link } from "react-router-dom";

const { Text } = Typography;

export default function Page() {
  useDocumentTitle("Management Transaction");
  const actionRef = useRef();
  const [active, setActive] = useState("send_kbs");
  const [dataSource, setDataSource] = useState([]);
  const [dateSelect, setDateSelect] = useState(false);
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onChange = (key) => {
    setActive(key);
  };
  const columnsShop = [
    {
      title: "Report Date",
      dataIndex: "label",
      key: "label",
      render: (_, record) => (
        <Text onClick={()=>{
          setDateSelect(record.label)
          setDataSource(record.list) 
          showDrawer()
        }} strong>{record.label}</Text>
      ),
    },
    {
      title: "Total Order",
      dataIndex: "total_order",
      key: "total_order",
      align: "center"
    },
    {
      title: "Total Amount",
      dataIndex: "total_amount",
      key: "total_amount",
      align: "right",
      render: (_, record) => (
        <Space size={2}>
          <Text>{record.total_amount}</Text>
          <Image
            src={`/token/KBC.png`}
            alt={'KBC'}
            width={18}
            preview={false}
          />
        </Space>
      ),
    }
  ];
  const columnsSend = [
    {
      title: "Timestamp",
      dataIndex: "timestamp",
      key: "timestamp",
      render: (item) => (
        <Text>{moment(item).format("YYYY-MM-DD, HH:mm:ss")}</Text>
      ),
    },
    {
      title: "Sender",
      dataIndex: "",
      key: "",
      align: "center",
      render: (_, record) => (
        <Tooltip title={generateUsername(record.from_name)}>
          <Link to={`/profile/${record.from_id}`} target="_blank">
            <Avatar src={record.from_avatar} alt="sender" />
          </Link>
        </Tooltip>
      ),
    },
    {
      title: "Receiver",
      dataIndex: "",
      key: "",
      align: "center",
      render: (_, record) => (
        <Tooltip title={generateUsername(record.to_name)}>
          <Link to={`/profile/${record.to_id}`} target="_blank">
            <Avatar src={record.to_avatar} alt="receiver" />
          </Link>
        </Tooltip>
      ),
    },

    {
      title: "Comment",
      dataIndex: "comment",
      key: "comment",
      render: (item) => (
        <Text>
          <small>{item}</small>
        </Text>
      ),
    },
    {
      title: "Core Value",
      dataIndex: "core_value",
      key: "core_value",
      align: "center",
    },
    { title: "Point", dataIndex: "point", key: "point", align: "center" },

    {
      title: "Status",
      dataIndex: "hash",
      key: "hash",
      align: "center",
      render: (item) => (
        <Text>
          {item !== "" ? (
            <Tag color="green">success</Tag>
          ) : (
            <Tag color="blue">pending</Tag>
          )}
        </Text>
      ),
    },

    {
      title: "Amount",
      dataIndex: "",
      key: "",
      align: "right",
      render: (_, record) => (
        <Space size={2}>
          <Text>{record.amount}</Text>
          <Image
            src={`/token/${record.symbol}.png`}
            alt={record.symbol}
            width={18}
            preview={false}
          />
        </Space>
      ),
    },
  ];

  const columnsPay = [
    {
      title: "Timestamp",
      dataIndex: "timestamp",
      key: "timestamp",
      render: (item) => (
        <Text>{moment(item).format("YYYY-MM-DD, HH:mm:ss")}</Text>
      ),
    },
    {
      title: "Sender",
      dataIndex: "",
      key: "",
      align: "center",
      render: (_, record) => (
        <Tooltip title={generateUsername(record.from_name)}>
          <Link to={`/profile/${record.from_id}`} target="_blank">
            <Avatar src={record.from_avatar} alt="sender" />
          </Link>
        </Tooltip>
      ),
    },
    {
      title: "Receiver",
      dataIndex: "",
      key: "",
      align: "center",
      render: (_, record) => (
        <Tooltip title={generateUsername(record.to_name)}>
          <Link to={`/profile/${record.to_id}`} target="_blank">
            <Avatar src={record.to_avatar} alt="receiver" />
          </Link>
        </Tooltip>
      ),
    },

    {
      title: "Comment",
      dataIndex: "comment",
      key: "comment",
      render: (item) => (
        <Text>
          <small>{item}</small>
        </Text>
      ),
    },
    {
      title: "Status",
      dataIndex: "hash",
      key: "hash",
      align: "center",
      render: (item) => (
        <Text>
          {item !== "" ? (
            <Tag color="green">success</Tag>
          ) : (
            <Tag color="blue">pending</Tag>
          )}
        </Text>
      ),
    },

    {
      title: "Amount",
      dataIndex: "",
      key: "",
      align: "right",
      render: (_, record) => (
        <Space size={2}>
          <Text>{record.amount}</Text>
          <Image
            src={`/token/${record.symbol}.png`}
            alt={record.symbol}
            width={18}
            preview={false}
          />
        </Space>
      ),
    },
  ];

  return (
    <PageContainer
      header={{
        title: "Transaction",
        ghost: true,
        breadcrumb: {
          items: [
            {
              href: "/management",
              title: "Management",
            },
            {
              title: "Transaction",
            },
          ],
        },
        extra: active === "send_kbs"? [
          <Download
            key={"download"}
            route="/transaction/download"
            columns={[
              "timestamp",
              "sender",
              "receiver",
              "comment",
              "core_value",
              "point",
              "status",
              "amount",
            ]}
          />,
        ] : [],
      }}
      tabProps={{
        onChange,
      }}
      tabBarExtraContent={<Text>โชว์รายละเอียดเกี่ยวกับ Transaction</Text>}
      tabList={[
        {
          tab: "Send KBS",
          key: "send_kbs",
        },
        {
          tab: "Shop",
          key: "shop",
        },
      ]}
    >
      {active === "send_kbs" && (
        <DataTable
          columns={columnsSend}
          route={`/transaction/list_send_kbs`}
          actionRef={actionRef}
          keyword={`sender`}
        />
      )}
      {active === "shop" && (
        <DataTable
          columns={columnsShop}
          route={`/transaction/transaction_group/PAY/KBC`}
          actionRef={actionRef}
          keyword={`label`}
        />
      )}


      <Drawer title="Transaction list" onClose={onClose} open={open} destroyOnClose={true} width={'80%'}>
        <Download
            key={"download"}
            route={`/transaction/transaction_group/PAY/KBC/${dateSelect}`}
            time={false}
            columns={false}
          />
          <br /><br />


        <Table dataSource={dataSource} columns={columnsPay}/>
      </Drawer>

    </PageContainer>
  );
}
