import { useEffect, useState } from "react";
import { PageContainer, StatisticCard } from "@ant-design/pro-components";
import {
  Row,
  Col,
  Space,
  Avatar,
  Typography,
  Image,
  Card,
  Table,
  Segmented,
} from "antd";
import { Api } from "services";
import { Area, Column } from "@ant-design/plots";
import { useDocumentTitle } from "hooks";

const { Title, Text, Link } = Typography;

export default function Page() {
  useDocumentTitle("Dashboard");
  const [KBS, setKBS] = useState();
  const [KBG, setKBG] = useState();
  const [KBC, setKBC] = useState();
  const [badge, setBadge] = useState([]);
  const [statKBS, setStatKBS] = useState([]);
  const [statKBG, setStatKBG] = useState([]);
  const [topSend, setTopSend] = useState([]);
  const [topReceive, setTopReceive] = useState([]);
  const [topBackToOffice, setTopBackToOffice] = useState([]);

  const configKBS = {
    data: KBS ? KBS.statistic : [],
    height: 130,
    xField: "_id",
    yField: "totalAmount",
    smooth: true,
    xAxis: {
      range: [0, 1],
      tickCount: 3,
    },
    yAxis: {
      tickCount: 0,
    },
    color: "#24d37d",
    areaStyle: () => {
      return {
        fill: "l(270) 0:#ffffff 0.5:#24d37d 1:#24d37d",
      };
    },
  };

  const configKBG = {
    data: KBG ? KBG.statistic : [],
    height: 130,
    xField: "_id",
    yField: "totalAmount",
    smooth: true,
    xAxis: {
      range: [0, 1],
      tickCount: 3,
    },
    yAxis: {
      tickCount: 0,
    },
    color: "#24d37d",
    areaStyle: () => {
      return {
        fill: "l(270) 0:#ffffff 0.5:#24d37d 1:#24d37d",
      };
    },
  };

  const configKBC = {
    data: [
      {
        dao: "Bearish",
        totalAmount: 0,
      },
      {
        dao: "Bullish",
        totalAmount: 0,
      },
      {
        dao: "Moon",
        totalAmount: 30000,
      },
      {
        dao: "Lambo",
        totalAmount: 0,
      },
    ],
    height: 130,
    xField: "dao",
    yField: "totalAmount",
    xAxis: {
      range: [0, 1],
      tickCount: 4,
    },
    color: "#24d37d",
  };

  const configStatKBS = {
    data: statKBS,
    height: 250,
    xField: "_id",
    yField: "totalAmount",
    color: "#24d37d",
  };

  const configStatKBG = {
    data: statKBG,
    height: 250,
    xField: "_id",
    yField: "totalAmount",
    color: "#24d37d",
  };

  useEffect(() => {
    fetchDataAnalytic("KBS");
    fetchDataAnalytic("KBG");
    fetchDataAnalytic("KBC");
    fetchDataBadge();
    fetchDataStatistic("KBS");
    fetchDataStatistic("KBG");
    fetchDataTopSend();
    fetchDataTopReceive();
    fetchDataTopBackToOffice();
  }, []);

  const fetchDataAnalytic = async (token) => {
    const response = await Api.Get(`/dashboard/analytic/${token}`);
    if (response.data.status === true) {
      if (token === "KBS") {
        setKBS(response.data.data);
      } else if (token === "KBG") {
        setKBG(response.data.data);
      } else {
        setKBC(response.data.data);
      }
    }
  };

  const fetchDataBadge = async () => {
    const response = await Api.Get(`/dashboard/badge`);
    if (response.data.status === true) {
      setBadge(response.data.data);
    }
  };

  const fetchDataStatistic = async (token) => {
    const response = await Api.Get(`/dashboard/statistic/${token}`);
    if (response.data.status === true) {
      if (token === "KBS") {
        setStatKBS(response.data.data);
      } else {
        setStatKBG(response.data.data);
      }
    }
  };

  const fetchDataTopSend = async () => {
    const response = await Api.Get(`/dashboard/top_send_kbs`);
    if (response.data.status === true) {
      setTopSend(response.data.data);
    }
  };

  const fetchDataTopReceive = async () => {
    const response = await Api.Get(`/dashboard/top_receive_kbs`);
    if (response.data.status === true) {
      setTopReceive(response.data.data);
    }
  };

  const fetchDataTopBackToOffice = async () => {
    const response = await Api.Get(`/dashboard/top_back_to_office`);
    if (response.data.status === true) {
      setTopBackToOffice(response.data.data);
    }
  };

  const columnsTop = [
    {
      title: "Place",
      dataIndex: "place",
      key: "place",
      align: "center",
      render: (item) => (
        <Space>
          {item < 4 ? (
            <Image
              src={`/top/${item}.png`}
              alt="gold"
              width={36}
              preview={false}
            />
          ) : (
            <Title level={5} style={{ margin: 0, lineHeight: "40px" }}>
              {item}
            </Title>
          )}
        </Space>
      ),
    },
    {
      title: "Username",
      dataIndex: "",
      key: "",
      render: (_, record) => (
        <Space>
          <Avatar src={record.avatar} />
          <Link strong href={`/profile/${record._id}`}>
            {record.company_email?.split("@")[0]}
          </Link>
        </Space>
      ),
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      align: "right",
    },
  ];

  return (
    <PageContainer>
      <Row gutter={[16, 16]}>
        <Col xs={24} md={12} lg={6}>
          <StatisticCard
            title={<Text strong>KBS</Text>}
            extra={<Avatar src="/token/KBS.png" size={"large"} />}
            statistic={{
              value: KBS ? KBS.balance : 0,
              description: (
                <Space>
                  {KBS?.percent >= 0 && (
                    <Text strong type="success">
                      <small>
                        <i className="far fa-arrow-up"></i> {`${KBS.percent} %`}
                      </small>
                    </Text>
                  )}
                  {KBS?.percent < 0 && (
                    <Text strong type="danger">
                      <small>
                        <i className="far fa-arrow-down"></i>{" "}
                        {`${KBS.percent} %`}
                      </small>
                    </Text>
                  )}
                  <Text type="secondary">
                    <small>vs. previous month</small>
                  </Text>
                </Space>
              ),
            }}
            chart={<Area {...configKBS} />}
          />
        </Col>
        <Col xs={24} md={12} lg={6}>
          <StatisticCard
            title={<Text strong>KBG</Text>}
            extra={<Avatar src="/token/KBG.png" size={"large"} />}
            statistic={{
              value: KBG ? KBG.balance : 0,
              description: (
                <Space>
                  {KBG?.percent >= 0 && (
                    <Text strong type="success">
                      <small>
                        <i className="far fa-arrow-up"></i> {`${KBG.percent} %`}
                      </small>
                    </Text>
                  )}
                  {KBG?.percent < 0 && (
                    <Text strong type="danger">
                      <small>
                        <i className="far fa-arrow-down"></i>{" "}
                        {`${KBG.percent} %`}
                      </small>
                    </Text>
                  )}
                  <Text type="secondary">
                    <small>vs. previous month</small>
                  </Text>
                </Space>
              ),
            }}
            chart={<Area {...configKBG} />}
          />
        </Col>
        <Col xs={24} md={12} lg={6}>
          <StatisticCard
            title={<Text strong>KBC</Text>}
            extra={<Avatar src="/token/KBC.png" size={"large"} />}
            statistic={{
              value: KBC ? KBC.balance : 0,
              description: (
                <Space>
                  {KBC?.percent >= 0 && (
                    <Text strong type="success">
                      <small>
                        <i className="far fa-arrow-up"></i> {`${KBC.percent} %`}
                      </small>
                    </Text>
                  )}
                  {KBC?.percent < 0 && (
                    <Text strong type="danger">
                      <small>
                        <i className="far fa-arrow-down"></i>{" "}
                        {`${KBC.percent} %`}
                      </small>
                    </Text>
                  )}
                  <Text type="secondary">
                    <small>vs. previous month</small>
                  </Text>
                </Space>
              ),
            }}
            chart={<Column {...configKBC} />}
          />
        </Col>
        <Col xs={24} md={12} lg={6}>
          <Card bordered={false} style={{ height: "100%" }}>
            <Row gutter={[16, 16]}>
              {badge.map((item) => (
                <Col key={item._id} span={24}>
                  <Row justify={"space-between"} align={"middle"}>
                    <Col>
                      <Avatar src={`/dao/${item.dao}.png`} size={52} />
                    </Col>
                    <Col align="right">
                      <Space direction="vertical" size={0}>
                        <Space size={2}>
                          <Title level={4} style={{ margin: 0 }}>
                            {item.totalAmount}
                          </Title>
                          <Avatar src={`/token/${item.token}.png`} size={20} />
                        </Space>
                        <Space size={2}>
                          <Text type="secondary">
                            <i className="fas fa-users-crown"></i>
                          </Text>
                          <Text type="secondary">
                            <small>{item.totalMember}</small>
                          </Text>
                        </Space>
                      </Space>
                    </Col>
                  </Row>
                </Col>
              ))}
            </Row>
          </Card>
        </Col>
        <Col xs={24} md={12}>
          <Card
            title={
              <Row justify={"space-between"} align={"middle"}>
                <Col>
                  <Title level={5} style={{ margin: 0 }}>
                    KBS Statistic
                  </Title>
                </Col>
                <Col>
                  <Segmented options={["1Y"]} />
                </Col>
              </Row>
            }
            bordered={false}
          >
            <Column {...configStatKBS} />
          </Card>
        </Col>
        <Col xs={24} md={12}>
          <Card
            title={
              <Row justify={"space-between"} align={"middle"}>
                <Col>
                  <Title level={5} style={{ margin: 0 }}>
                    KBG Statistic
                  </Title>
                </Col>
                <Col>
                  <Segmented options={["1Y"]} />
                </Col>
              </Row>
            }
            bordered={false}
          >
            <Column {...configStatKBG} />
          </Card>
        </Col>
        <Col xs={24} md={8}>
          <Card
            title="Top 5 Send KBS"
            bodyStyle={{ padding: 0, overflowX: "auto" }}
            bordered={false}
          >
            <Table
              rowKey={"_id"}
              columns={columnsTop}
              dataSource={topSend}
              size="small"
              pagination={false}
            />
          </Card>
        </Col>
        <Col xs={24} md={8}>
          <Card
            title="Top 5 Receive KBS"
            bodyStyle={{ padding: 0, overflowX: "auto" }}
            bordered={false}
          >
            <Table
              rowKey={"_id"}
              columns={columnsTop}
              dataSource={topReceive}
              size="small"
              pagination={false}
            />
          </Card>
        </Col>
        <Col xs={24} md={8}>
          <Card
            title="Top 5 Back to Office"
            bodyStyle={{ padding: 0, overflowX: "auto" }}
            bordered={false}
          >
            <Table
              rowKey={"_id"}
              columns={columnsTop}
              dataSource={topBackToOffice}
              size="small"
              pagination={false}
            />
          </Card>
        </Col>
      </Row>
    </PageContainer>
  );
}
