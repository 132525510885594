import { Button, Tooltip } from "antd";
import { DrawerForm } from "@ant-design/pro-components";

const Drawer = ({ title, form, render, onFinish }) => {
  return (
    <DrawerForm
      title={`Add ${title}`}
      resize={{
        maxWidth: window.innerWidth * 0.8,
        minWidth: 500,
      }}
      form={form}
      trigger={
        <Tooltip title={`Add ${title}`}>
          <Button
            type="primary"
            icon={<i className="far fa-plus"></i>}
          ></Button>
        </Tooltip>
      }
      drawerProps={{
        destroyOnClose: true,
      }}
      onFinish={(values) => {
        onFinish(values);
        return true;
      }}
    >
      {render}
    </DrawerForm>
  );
};

export default Drawer;
