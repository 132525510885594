import "./index.css";
import { useEffect, useState } from "react";
import { Row, Col, Typography, Image, Card, Space, Avatar } from "antd";
import { Api } from "services";
import { Loading } from "components";
import { useDocumentTitle } from "hooks";

const { Title } = Typography;

function Page() {
  useDocumentTitle("KUBER Lucky Draw");

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [prize, setPrize] = useState([]);
  const [winner, setWinner] = useState([]);
  const [random, setRandom] = useState(null);
  const [current, setCurrent] = useState(null);

  const fetchData = async () => {
    const result = await Api.Get(`/lucky_draw/live`);
    if (result.data.status === true) {
      setData(result.data.data);
      setPrize(result.data.prize);
      setWinner(result.data.winner);
      setCurrent(result.data.current);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleRandom = async () => {
    setLoading(true);
    const result = await Api.Get("/lucky_draw/random");
    if (result.data.status === true) {
      setRandom(result.data.random);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const handleNext = async (id) => {
    setRandom(null);
    const result = await Api.Get(`/lucky_draw/submit/${id}`);
    if (result.data.status === true) {
      fetchData();
    }
  };

  const handleCancel = async (id) => {
    setRandom(null);
    const result = await Api.Get(`/lucky_draw/cancel/${id}`);
    if (result.data.status === true) {
      fetchData();
    }
  };

  return (
    <div
      className="kuber-random-page"
      style={{
        backgroundImage: `url(/lucky_draw/bg.png)`,
      }}
    >
      <div className="event-body">
        <Row gutter={[24, 24]} align={"middle"}>
          <Col span={7}>
            <div className="event-card-prize">
              <Card size="small" bordered={false}>
                <Row gutter={[12, 12]}>
                  {prize.map((item, index) => (
                    <Col span={8} key={index}>
                      <Image
                        src={`/api/static/lotteries/prize-${item._id}.png`}
                        alt={item.name}
                        preview={false}
                        style={{
                          filter:
                            item.status === false ? "blur(3px)" : "blur(0)",
                        }}
                      />
                    </Col>
                  ))}
                </Row>
              </Card>
            </div>
          </Col>

          <Col span={10} align="center">
            <div className="topic">
              <Space direction="vertical">
                <Image
                  src="/lucky_draw/logo.png"
                  alt="logo"
                  preview={false}
                  width={200}
                />
                <Title level={2}>{data?.title}</Title>
              </Space>
            </div>
            {loading === true ? (
              <Loading color={"white"} />
            ) : random ? (
              <div className="event-winner">
                <Space direction="vertical" size={"large"}>
                  <Space size={"large"}>
                    <div className="event-card-nft-blur">
                      <Image
                        preview={false}
                        src="/event/nft-blur.png"
                        alt="nft-blur"
                        width={160}
                      />
                      <div className="event-card-nft">
                        <Image
                          src={`https://ipfs.bitkuber.io/ipfs/${random.nft}`}
                          alt="nft"
                          width={160}
                          preview={false}
                        />
                      </div>
                    </div>

                    <Space direction="vertical">
                      <Avatar src={random.avatar} size={100} />
                      <Title level={3}>{random.name}</Title>

                      <Image
                        src={`/api/static/lotteries/prize-${random.prize}.png`}
                        alt={random.prize}
                        preview={false}
                        width={80}
                      />
                    </Space>
                  </Space>

                  <Space size={"large"}>
                    <a
                      onClick={() => handleNext(random._id)}
                      className="event-button"
                    >
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      Next
                    </a>
                    <a
                      onClick={() => handleCancel(random._id)}
                      className="event-button-cancel"
                    >
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      Cancel
                    </a>
                  </Space>
                </Space>
              </div>
            ) : winner.filter((item) => item.name !== "").length !== prize.length ? (
              <>
                <div>
                  <Image
                    src={`/api/static/lotteries/prize-${current?._id}.png`}
                    alt={`prize-${current?._id}`}
                    preview={false}
                    width={"40%"}
                  />
                  <Title level={4}>{current?.name}</Title>
                </div>

                <a onClick={() => handleRandom()} className="event-button">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  Random
                </a>
              </>
            ) : (
              <div class="thanks">Thank You</div>
            )}
          </Col>

          <Col span={7}>
            <div className="event-card-winner">
              <Card bordered={false} size="small">
                <Row gutter={[12, 12]}>
                  {winner.map((item, index) => (
                    <Col span={8} key={index}>
                      <div className="event-card-winner-empty">
                        <Image
                          src={`/event/empty-winner.png`}
                          alt={item}
                          preview={false}
                        />

                        {item.avatar !== "" && (
                          <div className="event-card-winner-avatar">
                            <Image
                              src={item.avatar}
                              alt={item.avatar}
                              preview={false}
                            />
                          </div>
                        )}

                        {item.prize !== "" && (
                          <div className="event-card-winner-prize">
                            <Image
                              src={`/api/static/lotteries/prize-${item.prize}.png`}
                              alt={item.prize}
                              preview={false}
                            />
                          </div>
                        )}
                      </div>
                    </Col>
                  ))}
                </Row>
              </Card>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Page;
