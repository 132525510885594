import "./index.css";
import { useState, useEffect } from "react";
import { PageContainer } from "@ant-design/pro-components";
import { Row, Col, Button, Space, Typography, Image, Card, Badge } from "antd";
import { Api } from "services";
import { History, Waiting, Loading } from "components";
import moment from "moment-timezone";
import { useDocumentTitle } from "hooks";

const { Title, Text, Link, Paragraph } = Typography;

export default function Page() {
  useDocumentTitle("Daily Check-In");
  const [loading, setLoading] = useState(true);
  const [checked, setChecked] = useState(true);
  const [open, setOpen] = useState(false);
  const [day, setDay] = useState([
    {
      amount: 1,
      status: "kbgs",
      focus: false,
    },
    {
      amount: 2,
      status: "kbgs",
      focus: false,
    },
    {
      amount: 2,
      status: "kbgs",
      focus: false,
    },
    {
      amount: 2,
      status: "kbgs",
      focus: false,
    },
    {
      amount: 2,
      status: "kbgs",
      focus: false,
    },
    {
      amount: 2,
      status: "kbgs",
      focus: false,
    },
    {
      amount: 5,
      status: "kbgs",
      focus: false,
    },
  ]);

  const columns = [
    {
      title: "Date Time",
      dataIndex: "created_at",
      key: "created_at",
      render: (item) => (
        <Text>{moment(item).format("DD/MM/YYYY, HH:mm:ss")}</Text>
      ),
    },
    {
      title: "Description",
      dataIndex: "comment",
      key: "comment",
    },
    {
      title: "Amount",
      dataIndex: "",
      key: "",
      align: "right",
      render: (_, record) => (
        <Space size={2}>
          <Text>{record.amount}</Text>
          <Image
            src={`/token/${record.symbol}.png`}
            alt={record.symbol}
            width={18}
            preview={false}
          />
        </Space>
      ),
    },
    {
      title: "Testnet",
      dataIndex: "hash",
      key: "hash",
      align: "center",
      render: (item) => (
        <Space>
          {item !== "" ? (
            <Link
              href={`https://testnet.bkcscan.com/tx/${item}`}
              target="_blank"
            >
              view
            </Link>
          ) : (
            <Badge status="processing" text="processing" />
          )}
        </Space>
      ),
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const response = await Api.Get(`/daily_check_in/list`);
    if (response.data.status === true) {
      let newArr = [...day];
      if (response.data.data.length !== 0) {
        for (var i = 0; i < response.data.data.length; i++) {
          newArr[i].status = "checked";
        }
        for (var i = 0; i < 7; i++) {
          newArr[i].focus = false;
        }
        if (response.data.checkToday.length !== 0) {
          newArr[response.data.data.length - 1].focus = true;
        } else {
          newArr[response.data.data.length].focus = true;
        }

        setDay(newArr);
      } else {
        newArr[0].focus = true;
        setDay(newArr);
      }
      setChecked(response.data.checkToday.length !== 0 ? true : false);
      setOpen(false);
      setLoading(false);
    }
  };

  const handleCheckIn = async () => {
    setOpen(true);
    const response = await Api.Get(`/daily_check_in/claim`);
    if (response.data.status === true) {
      fetchData();
    }
  };

  if (loading) return <Loading />;

  return (
    <PageContainer
      className="kuber-daily-check-in-page"
      header={{
        title: "Daily Check-In",
        ghost: true,
        breadcrumb: {
          items: [
            {
              href: "/profile",
              title: "My Profile",
            },
            {
              title: "Daily Check-In",
            },
          ],
        },
        extra: [
          <History
            key="history"
            columns={columns}
            route={`/history/daily_check_in`}
          />,
        ],
      }}
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card bordered={false}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Title level={4} align="center" style={{ margin: 0 }}>
                  7 Days Daily Check-In Rewards
                </Title>
              </Col>
              <Col span={24}>
                <Card
                  bodyStyle={{
                    textAlign: "center",
                    overflowX: "auto",
                    paddingInline: "8px",
                  }}
                >
                  <div className="list">
                    {day.map((item, index) => (
                      <div
                        key={index}
                        className={item.focus === true ? "item focus" : "item"}
                      >
                        <Space direction="vertical" size={0}>
                          <Image
                            src={
                              item.status === "checked"
                                ? `/icon/checked.png`
                                : `/token/KBG.png`
                            }
                            alt="KBG"
                            preview={false}
                          />
                          <Text strong>{`+${item.amount}`}</Text>
                          <Text type="secondary">
                            <small>{`Day ${index + 1}`}</small>
                          </Text>
                        </Space>
                      </div>
                    ))}
                  </div>
                </Card>
              </Col>
              <Col span={24} align="center">
                <Paragraph>
                  Come back every day to earn extra rewards!
                  <br />
                  The daily check-in will reset every Monday.
                </Paragraph>
              </Col>
              <Col span={24} align="center">
                <Button
                  type="primary"
                  shape="round"
                  disabled={checked}
                  onClick={handleCheckIn}
                >
                  Check-In
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      {open === true && <Waiting open={open} />}
    </PageContainer>
  );
}
