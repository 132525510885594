import { useState, useEffect } from "react";
import {
  DrawerForm,
  ProFormTextArea,
  PageContainer,
} from "@ant-design/pro-components";
import { Row, Col, Button, Typography, Image, Card, Space } from "antd";
import { Api } from "services";
import { useDocumentTitle } from "hooks";

const { Text } = Typography;

export default function Page() {
  useDocumentTitle("Setting Templace");
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    const result = await Api.Get(`/templace/list`);
    if (result.data.status === true) {
      setData(result.data.data);
    }
  }

  // async function onFinish(values) {
  //   const result = await Api.Post(`/templace/add`, values);
  //   if (result.data.status === true) {
  //     message.success("DONE");
  //     fetchData();
  //     return true;
  //   }
  // }

  return (
    <PageContainer
      header={{
        title: "Templace",
        ghost: true,
        breadcrumb: {
          items: [
            {
              href: "/setting",
              title: "Setting",
            },
            {
              title: "Templace",
            },
          ],
        },
        extra: [
          <DrawerForm
            key="add"
            title={`Add Templace`}
            width={600}
            trigger={
              <Button
                icon={<i className="far fa-plus"></i>}
                type="primary"
                shape="circle"
              />
            }
            //onFinish={onFinish}
            drawerProps={{
              destroyOnClose: true,
            }}
          >
            <ProFormTextArea name="remark" label="Remark" />
          </DrawerForm>,
        ],
      }}
    >
      <Row gutter={[8, 8]}>
        {data.map((item) => (
          <Col key={item._id} xs={12} sm={8} md={6}>
            <Card size="small" bordered={false}>
              <Row gutter={[8, 8]}>
                <Col span={24}>
                  <Row gutter={[12, 12]}>
                    <Col span={12}>
                      <Image
                        src={`/api/static/templaces/${item._id}/common.png`}
                        alt={item.remark}
                      />
                    </Col>
                    <Col span={12}>
                      <Image
                        src={`/api/static/templaces/${item._id}/rare.png`}
                        alt={item.remark}
                      />
                    </Col>
                    <Col span={12}>
                      <Image
                        src={`/api/static/templaces/${item._id}/superrare.png`}
                        alt={item.remark}
                      />
                    </Col>
                    <Col span={12}>
                      <Image
                        src={`/api/static/templaces/${item._id}/ultrarare.png`}
                        alt={item.remark}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Space direction="vertical" size={0}>
                    <Text type="secondary">
                      <small>Model</small>
                    </Text>
                    <Text strong>
                      <small>{item._id}</small>
                    </Text>
                  </Space>
                </Col>
              </Row>
            </Card>
          </Col>
        ))}
      </Row>
    </PageContainer>
  );
}
