import "./index.css";
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { PageContainer } from "@ant-design/pro-components";
import {
  Row,
  Col,
  Space,
  Avatar,
  Typography,
  Image,
  Card,
  Descriptions,
  Timeline,
} from "antd";
import { Api } from "services";
import { generateUsername } from "utils";
import moment from "moment-timezone";
import { useDocumentTitle } from "hooks";

const { Title, Text, Paragraph } = Typography;

export default function Page() {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [employee, setEmployee] = useState([]);
  useDocumentTitle(`NFT ID: ${id}`);

  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [id]);

  const fetchData = async (id) => {
    const result = await Api.Get(`/nft/full_info/${id}`);
    if (result.data.status === true) {
      setData(result.data.data);
      setTotal(result.data.total);
      setEmployee(result.data.employee);
    }
  };

  return (
    <PageContainer
      className="kuber-nft-page"
      header={{
        ghost: true,
        breadcrumb: {
          items: [
            {
              href: "/profile",
              title: "My Profile",
            },
            {
              title: `NFT ID : ${data._id}`,
            },
          ],
        },
      }}
    >
      <Row gutter={[16, 16]}>
        <Col md={8}>
          <Row gutter={[16, 16]}>
            {data.mission_id && (
              <Col span={24}>
                <Card bordered={false}>
                  <div
                    className="cover"
                    style={{
                      backgroundImage: `url(/api/static/nfts/${data.mission_id._id}.png)`,
                    }}
                  >
                    <Image
                      src={`/api/media/nft/${data.mission_id._id}/${data.number}.png`}
                      preview={false}
                    />
                  </div>
                </Card>
              </Col>
            )}
            <Col span={24}>
              <Space direction="vertical">
                <Text strong>People who have this NFT</Text>
                <Space>
                  <Avatar.Group size={"large"}>
                    {employee.map((item) => (
                      <Avatar key={item._id} src={item.avatar} />
                    ))}
                  </Avatar.Group>
                  {total > 5 && <Text strong>{`+ ${total}`}</Text>}
                </Space>
              </Space>
            </Col>
          </Row>
        </Col>
        <Col md={16}>
          <Card bordered={false}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Title level={5} style={{ margin: 0 }}>
                  NFT Information
                </Title>
              </Col>
              <Col span={24}>
                <Space size={"large"}>
                  <Space>
                    <Avatar size={"large"} />
                    <Space direction="vertical" size={2}>
                      <Text type="secondary">
                        <small>Creator</small>
                      </Text>
                      <Text strong>Admin</Text>
                    </Space>
                  </Space>
                  <Space>
                    <Avatar src={data?.employee_id?.avatar} size={"large"} />
                    <Space direction="vertical" size={2}>
                      <Text type="secondary">
                        <small>Owner</small>
                      </Text>
                      <Text strong>
                        {data.employee_id
                          ? generateUsername(data.employee_id.company_email)
                          : ""}
                      </Text>
                    </Space>
                  </Space>
                </Space>
              </Col>
              <Col span={24}>
                <Descriptions
                  size="small"
                  bordered
                  items={[
                    {
                      label: "Name",
                      children: (
                        <Text>
                          {data.mission_id && (
                            <Link
                              to={`/mission/${data.mission_id._id}`}
                              target="_blank"
                            >
                              {data.mission_id.name}
                            </Link>
                          )}
                        </Text>
                      ),
                      span: 3,
                    },
                    {
                      label: "Hash",
                      children: (
                        <Paragraph copyable style={{ margin: 0 }}>
                          {data.hash}
                        </Paragraph>
                      ),
                      span: 3,
                    },

                    {
                      label: "Number",
                      children: (
                        <Text>{data.number === 0 ? "-" : data.number}</Text>
                      ),
                      span: 3,
                    },
                    {
                      label: "NFT Type",
                      children: "Mission",
                      span: 3,
                    },
                    {
                      label: "Rarity",
                      children: <Text>{data.rarity}</Text>,
                      span: 3,
                    },
                  ]}
                />
              </Col>
              <Col span={24}>
                <Title level={5} style={{ margin: "0 0 16px" }}>
                  Timeline
                </Title>

                {data.employee_id && (
                  <Timeline
                    items={[
                      {
                        children: (
                          <Space direction="vertical" size={0}>
                            <Text strong>
                              <small>Created by Admin</small>
                            </Text>
                            <Text type="secondary">
                              <small>
                                {moment(new Date(data.timestamp * 1000)).format(
                                  "DD/MM/YYYY, HH:mm:ss"
                                )}
                              </small>
                            </Text>
                          </Space>
                        ),
                      },
                      {
                        children: (
                          <Space direction="vertical" size={0}>
                            <Text strong>
                              <small>{`Owned by ${generateUsername(
                                data.employee_id.company_email
                              )}`}</small>
                            </Text>
                            <Text type="secondary">
                              <small>
                                {moment(data.updated_at).format(
                                  "DD/MM/YYYY, HH:mm:ss"
                                )}
                              </small>
                            </Text>
                          </Space>
                        ),
                      },
                    ]}
                  />
                )}
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </PageContainer>
  );
}
