import "./index.css";
import { useEffect, useState } from "react";
import {
  Row,
  Col,
  Typography,
  Image,
  Card,
  Space,
  Carousel,
  Avatar,
  Form,
  Input,
  Button,
} from "antd";
import { Api } from "services";
import { Loading } from "components";

const { Title } = Typography;

function Page() {
  const [login, setLogin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [list, setList] = useState([]);
  const [winner, setWinner] = useState();
  const [count, setCount] = useState();

  const fetchData = async () => {
    const result = await Api.Get(`/event/detail`);
    if (result.data.status === true) {
      setData(result.data.prize);
      setList(result.data.list);
      setCount(result.data.count);
      setWinner();
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleRandom = async () => {
    setLoading(true);

    const result = await Api.Get("/event/random");
    if (result.data.status === true) {
      setWinner(result.data.winner);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const handleNext = async () => {
    const result = await Api.Post("/event/submit", winner);
    if (result.data.status === true) {
      fetchData();
    }
  };

  const handleCancel = async () => {
    const result = await Api.Post("/event/cancel", winner);
    if (result.data.status === true) {
      fetchData();
    }
  };

  const onFinish = (values) => {
    if (
      values.username === "bitkuballhands2023" &&
      values.password === "8;k,]y[w,j,u.oF]d"
    ) {
      setLogin(true);
    } else {
      alert(
        "ขอโทษ ที่ทำให้บอบช้ำ ขอโทษ ที่ยังให้เจ็บซ้ำ ปล่อยให้ใส่รหัสผิดซ้ำๆ มานานแค่ไหน"
      );
    }
  };

  return (
    <div
      className="event-container"
      style={{
        backgroundImage: `url(/bg_event.jpg)`,
      }}
    >
      <div className="event-body">
        <Row gutter={[24, 24]} align={"middle"}>
          <Col span={24} align="center">
            <Space direction="vertical">
              <Image
                src="/event/logobitkub.png"
                alt="logobitkub"
                preview={false}
                width={200}
              />
              <Image
                src="/event/event-name.png"
                alt="event-name"
                preview={false}
                width={300}
              />
            </Space>
          </Col>

          {login === false && (
            <Col span={24}>
              <Row justify={"center"}>
                <Col span={6}>
                  <Card bordered={false}>
                    <Form
                      layout="vertical"
                      onFinish={onFinish}
                      autoComplete="off"
                    >
                      <Form.Item
                        name="username"
                        label="Username"
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name="password"
                        label="Password"
                        rules={[{ required: true }]}
                      >
                        <Input type="password" />
                      </Form.Item>

                      <Form.Item rules={[{ required: true }]}>
                        <Button
                          type="primary"
                          htmlType="submit"
                          block
                          shape="round"
                          size="large"
                        >
                          Login
                        </Button>
                      </Form.Item>
                    </Form>
                  </Card>
                </Col>
              </Row>
            </Col>
          )}

          {login === true && (
            <>
              <Col span={7}>
                <div className="event-card-prize">
                  <Card size="small" bordered={false}>
                    <Row gutter={[12, 12]}>
                      {data.map((item, index) => (
                        <Col span={8} key={index}>
                          <Image
                            src={`/event/${item.img}`}
                            alt={item.name}
                            preview={false}
                            style={{
                              filter:
                                item.status === true ? "blur(3px)" : "blur(0)",
                            }}
                          />
                        </Col>
                      ))}
                    </Row>
                  </Card>
                </div>
              </Col>
              <Col span={10} align="center">
                {loading === true ? (
                  <Loading color={"white"} />
                ) : winner ? (
                  <div className="event-winner">
                    <Space direction="vertical" size={"large"}>
                      <Space size={"large"}>
                        <div className="event-card-nft-blur">
                          <Image
                            preview={false}
                            src="/event/nft-blur.png"
                            alt="nft-blur"
                            width={160}
                          />
                          <div className="event-card-nft">
                            <Image
                              src={`https://ipfs.bitkuber.io/ipfs/${winner.nft}`}
                              alt="nft"
                              width={160}
                              preview={false}
                            />
                          </div>
                        </div>

                        <Space direction="vertical">
                          <Avatar src={winner.avatar} size={100} />
                          <Space size={4}>
                            <Title level={3}>{winner.name}</Title>
                            {winner.onduty === true && (
                              <i
                                className="fas fa-user-headset"
                                style={{ color: "#fff" }}
                              ></i>
                            )}
                          </Space>

                          <Image
                            src={`/event/${winner.prize}`}
                            alt={winner.prize}
                            preview={false}
                            width={80}
                          />
                        </Space>
                      </Space>

                      <Space size={"large"}>
                        <a
                          onClick={() => handleNext()}
                          className="event-button"
                        >
                          <span></span>
                          <span></span>
                          <span></span>
                          <span></span>
                          Next
                        </a>
                        <a
                          onClick={() => handleCancel()}
                          className="event-button-cancel"
                        >
                          <span></span>
                          <span></span>
                          <span></span>
                          <span></span>
                          Cancel
                        </a>
                      </Space>
                    </Space>
                  </div>
                ) : (
                  <>
                    <div className="event-carousel">
                      <Carousel dots={false}>
                        {data.map((item, index) => {
                          if (item.status === true)
                            return (
                              <div key={index}>
                                <Image
                                  src={`/event/${item.img}`}
                                  alt={`prize-${item}`}
                                  preview={false}
                                  width={"60%"}
                                />
                                <Title level={3}>{item.name}</Title>
                              </div>
                            );
                        })}
                      </Carousel>
                    </div>

                    {count !== 9 ? (
                      <a
                        onClick={() => handleRandom()}
                        className="event-button"
                      >
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        Random
                      </a>
                    ) : (
                      <div class="thanks">Thank You</div>
                    )}
                  </>
                )}
              </Col>
              <Col span={7}>
                <div className="event-card-winner">
                  <Card bordered={false} size="small">
                    <Row gutter={[12, 12]}>
                      {list.map((item, index) => (
                        <Col span={8} key={index}>
                          <div className="event-card-winner-empty">
                            <Image
                              src={`/event/empty-winner.png`}
                              alt={item}
                              preview={false}
                            />

                            {item.avatar !== "" && (
                              <div className="event-card-winner-avatar">
                                <Image
                                  src={item.avatar}
                                  alt={item.avatar}
                                  preview={false}
                                />
                              </div>
                            )}

                            {item.prize !== "" && (
                              <div className="event-card-winner-prize">
                                <Image
                                  src={`/event/${item.prize}`}
                                  alt={item.prize}
                                  preview={false}
                                />
                              </div>
                            )}
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </Card>
                </div>
              </Col>
            </>
          )}
        </Row>
      </div>
    </div>
  );
}

export default Page;
