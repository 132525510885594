import {
  ModalForm,
  ProFormDatePicker,
  PageContainer,
  ProFormText,
  ProFormSelect,
  ProFormGroup,
} from "@ant-design/pro-components";
import { Table, Drawer, Waiting, Download } from "components";
import {
  Button,
  Space,
  Tag,
  Avatar,
  Typography,
  Tooltip,
  Form,
  message,
} from "antd";
import { useRef, useState } from "react";
import moment from "moment-timezone";
import { useDocumentTitle } from "hooks";
import { Api } from "services";

const { Text } = Typography;

export default function Page() {
  useDocumentTitle("Management Employee");
  const actionRef = useRef();
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [teams, setTeams] = useState([]);
  const [active, setActive] = useState("active");

  const onChange = (key) => {
    setActive(key);
  };

  const fetchTeam = async (value) => {
    const response = await Api.Get(`/team/list/${value}?pageSize=100`);
    if (response.data.status === true) {
      setTeams(
        response.data.data.map((item) => ({
          label: item.name,
          value: item._id,
        }))
      );
    }
  };

  const RenderForm = () => {
    return (
      <>
        <ProFormGroup>
          <ProFormText
            name="employee_id"
            label="Employee ID"
            placeholder="Enter"
            width={"xs"}
            rules={[{ required: true, message: "" }]}
          />
          <ProFormSelect
            name="employee_type"
            label="Employee Type"
            request={async () => [
              { label: "Full-Time", value: "Full-Time" },
              { label: "Intern", value: "Intern" },
              { label: "Part-Time", value: "Part-Time" },
              { label: "Contract", value: "Contract" },
              { label: "Advisory", value: "Advisory" },
            ]}
            placeholder="Select"
            rules={[{ required: true, message: "" }]}
          />

          <ProFormSelect
            name="prefix"
            label="Prefix"
            width={"xs"}
            request={async () => [
              { label: "Miss", value: "Miss" },
              { label: "Mr.", value: "Mr." },
              { label: "Mrs.", value: "Mrs." },
              { label: "Ms.", value: "Ms." },
              { label: "Mx.", value: "Mx." },
            ]}
            placeholder="Select"
            rules={[{ required: true, message: "" }]}
          />
        </ProFormGroup>

        <ProFormGroup>
          <ProFormText
            name="first_name_en"
            label="First Name (Eng)"
            placeholder="Enter"
            width={"sm"}
            rules={[{ required: true, message: "" }]}
          />
          <ProFormText
            name="last_name_en"
            label="Last Name (Eng)"
            placeholder="Enter"
            width={"sm"}
            rules={[{ required: true, message: "" }]}
          />
        </ProFormGroup>

        <ProFormGroup>
          <ProFormText
            name="first_name_th"
            label="ชื่อภาษาไทย"
            placeholder="Enter"
            width={"sm"}
            rules={[{ required: true, message: "" }]}
          />
          <ProFormText
            name="last_name_th"
            label="นามสกุลภาษาไทย"
            placeholder="Enter"
            width={"sm"}
            rules={[{ required: true, message: "" }]}
          />
        </ProFormGroup>

        <ProFormGroup>
          <ProFormText
            name="nickname"
            label="Nickname"
            placeholder="Enter"
            width={"xs"}
            rules={[{ required: true, message: "" }]}
          />
          <ProFormText
            name="company_email"
            label="Company Email"
            placeholder="Enter"
            width={"md"}
            rules={[{ required: true, type: "email", message: "" }]}
          />
        </ProFormGroup>

        <ProFormGroup>
          <ProFormSelect
            name="entity_id"
            label="Entity"
            width={"xs"}
            request={async () => {
              const result = await Api.Get(`/entity/list`);
              return result.data.data.map((item) => ({
                label: item.abbreviation,
                value: item._id,
              }));
            }}
            onChange={(value) => {
              form.setFieldsValue({ team_id: null });
              fetchTeam(value);
            }}
            placeholder="Select"
            rules={[{ required: true, message: "" }]}
          />

          <ProFormSelect
            name="team_id"
            label="Team"
            width={"md"}
            options={teams}
            placeholder="Select"
            rules={[{ required: true, message: "" }]}
          />
        </ProFormGroup>

        <ProFormGroup>
          <ProFormText
            name="slack_id"
            label="Slack ID"
            placeholder="Enter"
            width={"sm"}
            rules={[{ required: true, message: "" }]}
          />
          <ProFormSelect
            name="dao"
            label="DAO"
            width={"sm"}
            options={[
              { label: "Bearish", value: "Bearish" },
              { label: "Bullish", value: "Bullish" },
              { label: "Moon", value: "Moon" },
              { label: "Lambo", value: "Lambo" },
            ]}
            fieldProps={{
              optionItemRender(item) {
                return (
                  <Space size={4}>
                    <Avatar src={`/dao/${item.label}.png`} size={20} />
                    <Text>
                      <small>{`${item.label}`}</small>
                    </Text>
                  </Space>
                );
              },
            }}
            placeholder="Select"
            rules={[{ required: true, message: "" }]}
          />

          <ProFormDatePicker
            name="join_date"
            label="Join Date"
            width={"sm"}
            placeholder="Select"
            rules={[{ required: true, message: "" }]}
          />
        </ProFormGroup>
      </>
    );
  };

  const handleFinishAdd = async (values) => {
    setOpen(true);
    const response = await Api.Post(`/employee/add`, values);
    if (response.data.status === true) {
      actionRef.current.reset();
      setOpen(false);
    } else {
      setOpen(false);
      message.error(response.data.msg);
    }
  };

  const handleFinishResign = async (values, id) => {
    const response = await Api.Post(`/employee/resign/${id}`, values);
    if (response.data.status === true) {
      actionRef.current.reload();
    } else {
      message.error(response.data.msg);
    }
  };

  const columns = [
    {
      title: "Employee",
      dataIndex: "",
      key: "",
      render: (_, record) => (
        <Space>
          <Avatar src={record.avatar} alt="" />
          <Text>{record.company_email}</Text>
        </Space>
      ),
    },

    {
      title: "Entity",
      dataIndex: "",
      key: "",
      render: (_, record) => <Text>{record.entity_id?.abbreviation}</Text>,
    },

    { title: "Emp ID", dataIndex: "employee_id", key: "emp_id" },

    {
      title: "Employee Type",
      dataIndex: "employee_type",
      key: "employee_type",
      align: "center",
      render: (item) => (
        <Text>
          {item === "Full-Time" && <Tag color="green">{item}</Tag>}
          {item === "Intern" && <Tag color="blue">{item}</Tag>}
        </Text>
      ),
    },

    {
      title: "Join Date",
      dataIndex: "join_date",
      key: "join_date",
      render: (item) => <Text>{moment(item).format("YYYY-MM-DD")}</Text>,
    },

    {
      title: "Probation",
      dataIndex: "confirmation_date",
      key: "confirmation_date",
      align: "center",
      render: (item) => (
        <Text>
          <i
            className={
              new Date(item).getTime() <= new Date(item).getTime()
                ? "fas fa-check-circle primary-color"
                : "far fa-history"
            }
          ></i>
        </Text>
      ),
    },
    {
      title: "Options",
      key: "",
      align: "center",
      render: (_, record) => (
        <Space>
          <Button
            type="link"
            href={`/profile/${record._id}`}
            target="_blank"
            icon={<i className="far fa-file-search"></i>}
          />

          <ModalForm
            width={400}
            title="Set to resign"
            trigger={
              <Tooltip title="Set to resign">
                <Button
                  type="text"
                  size="small"
                  icon={<i className="far fa-user-times"></i>}
                  danger
                />
              </Tooltip>
            }
            modalProps={{
              destroyOnClose: true,
            }}
            onFinish={(values) => {
              handleFinishResign(values, record._id);
              return true;
            }}
          >
            <ProFormDatePicker
              name="resignation_date"
              label="Resignation Date"
              width={"100%"}
              rules={[
                {
                  required: true,
                },
              ]}
            />
          </ModalForm>
        </Space>
      ),
    },
  ];

  const columnsInactive = [
    {
      title: "Company Email",
      dataIndex: "company_email",
      key: "company_email",
    },

    { title: "Emp ID", dataIndex: "employee_id", key: "employee_id" },

    {
      title: "Resignation Date",
      dataIndex: "",
      key: "",
      render: (_, record) => (
        <Text>
          {record.resignation_date
            ? moment(record.resignation_date).format("YYYY-MM-DD")
            : "N/A"}
        </Text>
      ),
    },
  ];

  return (
    <PageContainer
      header={{
        title: "Employee",
        ghost: true,
        breadcrumb: {
          items: [
            {
              href: "/management",
              title: "Management",
            },
            {
              title: "Employee",
            },
          ],
        },
        extra: [
          <Drawer
            key={"Employee"}
            title={"Employee"}
            form={form}
            render={<RenderForm />}
            onFinish={handleFinishAdd}
          />,
          <Download
            key={"download"}
            route="/fee_credit/download"
            columns={[
              "employee_id",
              "employee_type",
              "prefix",
              "first_name_en",
              "first_name_th",
              "last_name_en",
              "last_name_th",
              "nickname",
              "company_email",
              "slack_id",
              "join_date",
              "dao",
              "entity",
              "team",
            ]}
          />,
        ],
      }}
      tabProps={{
        onChange,
      }}
      tabBarExtraContent={<Text>โชว์รายละเอียดเกี่ยวกับ Employee</Text>}
      tabList={[
        {
          tab: "Active",
          key: "active",
        },
        {
          tab: "Inactive",
          key: "inactive",
        },
      ]}
    >
      {active === "active" && (
        <Table
          columns={columns}
          route={"/employee/list_active"}
          actionRef={actionRef}
          keyword={`employee`}
        />
      )}

      {active === "inactive" && (
        <Table
          columns={columnsInactive}
          route={"/employee/list_inactive"}
          actionRef={actionRef}
          keyword={`email`}
        />
      )}

      {open === true && <Waiting open={open} />}
    </PageContainer>
  );
}
