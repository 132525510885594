import { useRef, useState } from "react";
import { PageContainer } from "@ant-design/pro-components";
import { Table } from "components";
import { Space, Avatar, Typography } from "antd";
import moment from "moment-timezone";
import { useDocumentTitle } from "hooks";
import { generateUsername } from "utils";

const { Text } = Typography;

export default function Page() {
  useDocumentTitle("Management Log");
  const actionRef = useRef();
  const [active, setActive] = useState("user");

  const onChange = (key) => {
    setActive(key);
  };

  const columns = [
    {
      title: "Date Time",
      dataIndex: "created_at",
      key: "created_at",
      render: (item) => (
        <Text>{moment(item).format("DD/MM/YYYY, HH:mm:ss")}</Text>
      ),
    },
    {
      title: "Action By",
      dataIndex: "",
      key: "",
      render: (_, record) => (
        <Space>
          <Avatar
            src={
              record.employee_id
                ? record.employee_id.avatar
                : "/icon-512x512.png"
            }
            alt=""
          />
          <Text strong>
            {record.employee_id
              ? generateUsername(record.employee_id.company_email)
              : "Admin"}
          </Text>
        </Space>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
  ];

  return (
    <PageContainer
      header={{
        title: "Log",
        ghost: true,
        breadcrumb: {
          items: [
            {
              href: "/management",
              title: "Management",
            },
            {
              title: "Log",
            },
          ],
        },
      }}
      tabProps={{
        onChange,
      }}
      tabBarExtraContent={<Text>โชว์รายละเอียดเกี่ยวกับ Action</Text>}
      tabList={[
        {
          tab: "User's action",
          key: "user",
        },
        {
          tab: "System's action",
          key: "system",
        },
      ]}
    >
      {active === "user" && (
        <Table
          columns={columns}
          route={"/log/user"}
          actionRef={actionRef}
          keyword={`action`}
        />
      )}
      {active === "system" && (
        <Table
          columns={columns}
          route={"/log/system"}
          actionRef={actionRef}
          keyword={`action`}
        />
      )}
    </PageContainer>
  );
}
