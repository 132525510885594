import { Empty as AntEmpty, Typography } from "antd";

const { Text } = Typography;

const Empty = () => {
  return (
    <AntEmpty
      image="/empty/data.png"
      description={<Text type="secondary">No Data</Text>}
    />
  );
};

export default Empty;
