import "./index.css";
import { useState, useEffect, useRef } from "react";
import { PageContainer, ProTable } from "@ant-design/pro-components";
import {
  Row,
  Col,
  Button,
  Space,
  Spin,
  Avatar,
  Typography,
  Image,
  Card,
  Badge,
  Modal,
  Table,
  Carousel,
  Divider,
  Tag,
} from "antd";
import { Api } from "services";
import { Countdown, History } from "components";
import moment from "moment-timezone";
import { useDocumentTitle } from "hooks";
import { generateUsername } from "utils";

const { Title, Text, Link } = Typography;
const { confirm, error, success } = Modal;

export default function Page() {
  useDocumentTitle("Staking");
  const actionRef = useRef();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [enabled, setEnabled] = useState(false);
  const [list, setList] = useState([]);
  const [top, setTop] = useState([]);

  const settings = {
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 7,
    slidesToScroll: 1,
    initialSlide: 0,
    draggable: false,
    pauseOnHover: false,
    dots: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const columns = [
    {
      title: "Date Time",
      dataIndex: "created_at",
      key: "created_at",
      render: (item) => (
        <Text>{moment(item).format("DD/MM/YYYY, HH:mm:ss")}</Text>
      ),
    },

    {
      title: "NFT",
      dataIndex: "",
      key: "",
      align: "center",
      render: (_, record) => (
        <Image
          src={`/api/media/nft/${record.nft_id?.mission_id}/${record.nft_id?.number}.png?a=1`}
          width={24}
        />
      ),
    },

    {
      title: "Number",
      dataIndex: "",
      key: "",
      align: "center",
      render: (_, record) => <Text>{record.nft_id?.number}</Text>,
    },

    {
      title: "Owner",
      dataIndex: "",
      key: "",
      render: (_, record) => (
        <Space>
          <Avatar src={record.employee_id?.avatar} />
          <Text>
            {record.employee_id
              ? generateUsername(record.employee_id.company_email)
              : ""}
          </Text>
        </Space>
      ),
    },

    {
      title: "Testnet",
      dataIndex: "hash",
      key: "hash",
      align: "center",
      render: (item) =>
        item === "-" ? (
          <Badge status="processing" text="waiting for submit" />
        ) : (
          <Link
            href={`https://testnet.bkcscan.com/tx/${item}/logs`}
            target="_blank"
            underline
          >
            view
          </Link>
        ),
    },
  ];

  const columnsList = [
    {
      title: "NFT",
      dataIndex: "",
      key: "",
      align: "center",
      render: (_, record) => (
        <Image
          src={`/api/media/nft/${record.mission_id}/${record.number}.png?a=1`}
          width={24}
        />
      ),
    },

    {
      title: "Number",
      dataIndex: "number",
      key: "number",
      align: "center",
    },

    {
      title: "Mission",
      dataIndex: "mission_name",
      key: "mission_name",
      ellipsis: true,
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (item) => (
        <Tag bordered={false} color={item === "Stake" ? "gold" : "green"}>
          {item}
        </Tag>
      ),
    },
  ];

  const columnsTop = [
    {
      title: "Place",
      dataIndex: "place",
      key: "place",
      align: "center",
      render: (item) => (
        <Space>
          {item < 4 ? (
            <Image
              src={`/top/${item}.png?a=1`}
              alt="gold"
              width={36}
              preview={false}
            />
          ) : (
            <Title level={5} style={{ margin: 0, lineHeight: "40px" }}>
              {item}
            </Title>
          )}
        </Space>
      ),
    },
    {
      title: "Username",
      dataIndex: "",
      key: "",
      render: (_, record) => (
        <Space>
          <Avatar src={record.avatar} />
          <Link strong href={`/profile/${record._id}`}>
            {record.company_email?.split("@")[0]}
          </Link>
        </Space>
      ),
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      align: "right",
    },
  ];

  useEffect(() => {
    const fetchDataInfo = async () => {
      const response = await Api.Get(`/staking/info`);
      if (response.data.status === true) {
        setData(response.data.data);
        fetchDataTop();
      }
    };
    fetchDataInfo();
  }, []);

  const fetchDataList = async () => {
    const response = await Api.Get(`/staking/list`);
    if (response.data.status === true) {
      var result = {
        data: [],
      };
      result.data = response.data.data.map((item) => ({
        key: item._id,
        ipfs_uri: item.ipfs_uri,
        ipfs_image: item.ipfs_image,
        number: item.number,
        mission_id: item.mission_id._id,
        mission_name: item.mission_id.name,
        status: item.status,
      }));
      setList(result.data);
      return result;
    }
  };

  const fetchDataTop = async () => {
    const response = await Api.Get(`/staking/top`);
    if (response.data.status === true) {
      setTop(response.data.data);
    }
  };

  const handleStake = (key, data) => {
    confirm({
      centered: true,
      title: "Do you want to stake this NFTs?",
      icon: (
        <span className="anticon">
          <i className="fas fa-exclamation-triangle"></i>
        </span>
      ),
      content: (
        <Row gutter={[16, 16]}>
          {data.map((item) => (
            <Col key={item.key} span={6}>
              <Image
                src={`${item.ipfs_uri}/${item.ipfs_image}`}
                preview={false}
                alt=""
              />
            </Col>
          ))}
        </Row>
      ),
      onOk() {
        handleConfirm(key);
      },
    });
  };

  const handleConfirm = async (data) => {
    setLoading(true)
    const response = await Api.Post(`/staking/submit`, {
      nfts: data,
    });
    if (response.data.status === true) {
      setLoading(false)
      success({
        centered: true,
        title: "Thank you for your stake.",
        icon: (
          <span className="anticon">
            <i className="fas fa-check-circle"></i>
          </span>
        ),
        content: <Text>คุณสามารถตรวจสอบรายการได้ที่ History</Text>,
        onOk() {
          actionRef.current.reset();
          fetchDataTop();
        },
      });
    } else {
       setLoading(false)
      error({
        centered: true,
        title: "You cannot stake.",
        icon: (
          <span className="anticon">
            <i className="fas fa-times-circle"></i>
          </span>
        ),
        content: <Text>{response.data.msg}</Text>,
        onOk() {
          actionRef.current.reset();
          fetchDataTop();
        },
      });
    }
  };

  return (
    <PageContainer
      className="kuber-staking-page"
      header={{
        title: "Staking",
        ghost: true,
        breadcrumb: {
          items: [
            {
              href: "/profile",
              title: "My Profile",
            },
            {
              title: "Staking",
            },
          ],
        },
        extra: [
          <History
            key="history"
            columns={columns}
            route={`/history/staking`}
          />,
        ],
      }}
    >
    <Spin spinning={loading}>
      <Row gutter={[16, 32]}>
        <Col span={24}>
          <Countdown
            time={data?.end_date}
            endTime={(value) => setEnabled(value)}
          />
        </Col>
        <Col span={24}>
          <Title level={5} style={{ marginTop: 0 }} align="center">
            {`All eligible NFTs for Lucky Draw is ${data?.mission_list.length} NFTs`}
          </Title>

          {data ? (
            data.mission_list.length < 7 ? (
              <Row justify="center" gutter={[16, 16]}>
                {data.mission_list.map((item) => (
                  <Col md={4} key={item}>
                    <Card size="small" bordered={false}>
                      <Image src={`/api/static/nfts/${item}.png?a=1`} />
                    </Card>
                  </Col>
                ))}
              </Row>
            ) : (
              <div className="carousel">
                <Carousel {...settings}>
                  {data.mission_list.map((item) => (
                    <div key={item}>
                      <Card size="small" bordered={false}>
                        <Image src={`/api/static/nfts/${item}.png?a=1`} />
                      </Card>
                    </div>
                  ))}
                </Carousel>
              </div>
            )
          ) : null}
        </Col>

        <Col xs={24} md={16}>
          <Card bordered={false} bodyStyle={{ padding: 0 }}>
            <ProTable
              size="small"
              request={() => {
                return fetchDataList();
              }}
              actionRef={actionRef}
              columns={columnsList}
              rowSelection={{
                selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT],
                getCheckboxProps: (record) => ({
                  disabled: record.status === "Stake" || enabled === true,
                }),
              }}
              tableAlertRender={({ selectedRowKeys }) => {
                return (
                  <Text>
                    Select <Text strong>{selectedRowKeys.length}</Text> NFT
                  </Text>
                );
              }}
              tableAlertOptionRender={({
                selectedRowKeys,
                selectedRows,
                onCleanSelected,
              }) => {
                if (enabled === true) return <Text>Time Out!!!</Text>;
                return (
                  <Space>
                    <Button
                      type="primary"
                      shape="round"
                      onClick={() => handleStake(selectedRowKeys, selectedRows)}
                    >
                      Stake
                    </Button>

                    <Button shape="round" onClick={onCleanSelected}>
                      Reset
                    </Button>
                  </Space>
                );
              }}
              scroll={{ x: 400 }}
              options={false}
              search={false}
              pagination={false}
              rowKey="key"
              headerTitle={
                <Title level={5} style={{ margin: 0 }}>
                  รายการ NFT ที่เข้าร่วม
                </Title>
              }
              toolBarRender={() => [
                <Space split={<Divider type="vertical" />}>
                  <Text type="secondary">
                    Stake ไปแล้ว{" "}
                    <Text strong>
                      {
                        list.filter((element) => element.status === "Stake")
                          .length
                      }
                    </Text>{" "}
                    NFT
                  </Text>
                  <Text type="secondary">
                    คงเหลือ{" "}
                    <Text strong>
                      {list.length -
                        list.filter((element) => element.status === "Stake")
                          .length}
                    </Text>{" "}
                    NFT
                  </Text>
                </Space>,
              ]}
            />
          </Card>
        </Col>

        <Col xs={24} md={8}>
          <Card
            title="Top 5 Stake NFTs"
            bodyStyle={{ padding: 0, overflowX: "auto" }}
            bordered={false}
          >
            <Table
              rowKey={"_id"}
              columns={columnsTop}
              dataSource={top}
              size="small"
              pagination={false}
            />
          </Card>
        </Col>
      </Row>
      </Spin>
    </PageContainer>
  );
}
