import { Button, Result, Image, Typography } from "antd";

const { Title, Text } = Typography;

export const Maintenance = () => {
  return (
    <Result
      icon={
        <Image
          src="/status/maintenance.png"
          preview={false}
          alt="404"
          width={"50%"}
        />
      }
      title={
        <Title level={4} style={{ margin: 0 }}>
          We are currently in the process of developing the system.
        </Title>
      }
      subTitle={<Text>We will come back soon.</Text>}
    />
  );
};
