import { Link } from "react-router-dom";

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

export function generateMenu(data) {
  const menuMember = [
    getItem(
      <Link to={`/profile/${data._id}`}>My Profile</Link>,
      `/profile/${data._id}`,
      <i className="fad fa-user"></i>
    ),
    getItem(
      <Link to="/dashboard">Dashboard</Link>,
      "/dashboard",
      <i className="fad fa-th-large"></i>
    ),
    getItem(
      <Link to="/daily_check_in">Daily Check-In</Link>,
      "/daily_check_in",
      <i className="fad fa-badge-check"></i>
    ),
    // getItem(
    //   <Link to="/mission">Mission</Link>,
    //   "/mission",
    //   <i className="fad fa-bullseye-arrow"></i>
    // ),

    getItem(
      <Link to="/item">Item</Link>,
      "/item",
      <i className="fad fa-gift"></i>
    ),

    getItem(
      <Link to="/fee_credit">Fee Credit</Link>,
      "/fee_credit",
      <i className="fad fa-coins"></i>
    ),

    getItem(
      <Link to="/leaderboard">Leaderboard</Link>,
      "/leaderboard",
      <i className="fad fa-crown"></i>
    ),
    getItem(
      <Link to="/dao">DAO</Link>,
      "/dao",
      <i className="fad fa-users-crown"></i>
    ),
    // getItem(
    //   <Link to="/lucky_draw">Lucky Draw</Link>,
    //   "/lucky_draw",
    //   <i className="fad fa-star"></i>
    // ),
    getItem(
      <Link to="/staking">Staking</Link>,
      "/staking",
      <i className="fad fa-layer-group"></i>
    ),

    getItem(
      <Link to="/vote">Vote</Link>,
      "/vote",
      <i className="fad fa-poll-people"></i>
    ),
    getItem(
      <Link to="/booking">Booking</Link>,
      "/booking",
      <i className="fad fa-calendar-check"></i>
    ),
    getItem(
      <Link to="/survey">Survey</Link>,
      "/survey",
      <i className="fad fa-poll-h"></i>
    ),
  ];

  const menuAdmin = [
    getItem("Management", "/management", <i className="fad fa-list-ul"></i>, [
      getItem(
        <Link to="/management/activity">Activity</Link>,
        "/management/activity"
      ),
      getItem(
        <Link to="/management/employee">Employee</Link>,
        "/management/employee"
      ),
      getItem(<Link to="/management/item">Item</Link>, "/management/item"),
      // getItem(<Link to="/management/mission">Mission</Link>, "/management/mission"),
      // getItem(<Link to="/vote/item">Vote</Link>, "/vote/item"),
      getItem(
        <Link to="/management/fee_credit">Fee Credit</Link>,
        "/management/fee_credit"
      ),
      // getItem(
      //   <Link to="/management/reservation">Reservation</Link>,
      //   "/management/reservation"
      // ),
      getItem(<Link to="/management/log">Log</Link>, "/management/log"),

      getItem(
        <Link to="/management/transaction">Transaction</Link>,
        "/management/transaction"
      ),

      
    ]),
  ];

  const menuDev = [
    getItem("Setting", "/setting", <i className="fad fa-cog"></i>, [
      getItem(
        <Link to="/setting/administrator">Administrator</Link>,
        "/setting/administrator"
      ),
      getItem(
        <Link to="/setting/templace">Templace</Link>,
        "/setting/templace"
      ),
      getItem(<Link to="/setting/token">Token</Link>, "/setting/token"),
    ]),
  ];

  if (data.role === "member-kuber") return menuMember;
  if (data.role === "admin-kuber") return menuMember.concat(menuAdmin);
  if (data.role === "dev-kuber")
    return menuMember.concat(menuAdmin).concat(menuDev);
}

export function generateUsername(data) {
  return data.split("@")[0];
}

export function addressFormat(value, start, end) {
  return `${value.slice(0, start)}...${value.slice(value.length - end)}`;
}

export function getCoreValue(value) {
  const data = [
    "Earn trust",
    "Passionate and dive deep",
    "Challenge and Commit",
    "Strive for results and actions",
    "Ownership and Beyond",
    "Adapt and Innovate",
    "Empathy & Collaboration",
  ];
  return data[value];
}

export function getAvengerRole(value) {
  const data = [
    "Captain Team",
    "Vice Captain",
    "Supporter 1",
    "Supporter 2",
    "Supporter 3",
  ];
  return data[value];
}
