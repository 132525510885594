import "./index.css";
import { useState, useEffect } from "react";
import { PageContainer } from "@ant-design/pro-components";
import {
  Row,
  Col,
  Button,
  Space,
  Avatar,
  Typography,
  Image,
  Card,
  Badge,
  Modal,
} from "antd";
import { Api } from "services";
import { History, Waiting } from "components";
import moment from "moment-timezone";
import { useDocumentTitle } from "hooks";

const { Title, Text, Link } = Typography;
const { confirm, error, success } = Modal;

export default function Page() {
  useDocumentTitle("Item");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      title: "Date Time",
      dataIndex: "created_at",
      key: "created_at",
      render: (item) => (
        <Text>{moment(item).format("DD/MM/YYYY, HH:mm:ss")}</Text>
      ),
    },
    {
      title: "Item",
      dataIndex: "",
      key: "",
      render: (_, record) => (
        <Space>
          <Image
            src={`/api/static/items/${record.item_id._id}.png`}
            alt={record.item_id._id}
            width={28}
          />
          <Text>{record.item_id.name}</Text>
        </Space>
      ),
    },
    {
      title: "Amount",
      dataIndex: "",
      key: "",
      align: "right",
      render: (_, record) => (
        <Space size={2}>
          <Text>{record.amount}</Text>
          <Image
            src={`/token/${record.symbol}.png`}
            alt={record.symbol}
            width={18}
            preview={false}
          />
        </Space>
      ),
    },
    {
      title: "Testnet",
      dataIndex: "hash",
      key: "hash",
      align: "center",
      render: (item) => (
        <Space>
          {item !== "" ? (
            <Link
              href={`https://testnet.bkcscan.com/tx/${item}`}
              target="_blank"
            >
              view
            </Link>
          ) : (
            <Badge status="processing" text="processing" />
          )}
        </Space>
      ),
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const response = await Api.Get(`/item/list_active`);
    if (response.data.status === true) {
      setData(response.data.data);
    }
  };

  const handleRedeem = (data) => {
    confirm({
      centered: true,
      title: "Do you want to redeem this item?",
      icon: (
        <span className="anticon">
          <i className="fas fa-exclamation-triangle"></i>
        </span>
      ),
      content: (
        <Row gutter={[20, 20]}>
          <Col span={10}>
            <Image src={`/api/static/items/${data._id}.png`} />
          </Col>
          <Col span={14}>
            <Space direction="vertical">
              <Title level={5} style={{ marginBlockStart: 0 }}>
                {data.name}
              </Title>
              <Space size={4} align="center">
                <Avatar src="/token/KBG.png" alt="" size={20} />
                <Text strong>{data.kbg}</Text>
              </Space>
              <Text type="secondary">{`Remaining ${
                data.total_amount - data.current_amount
              } pcs.`}</Text>
            </Space>
          </Col>
        </Row>
      ),
      onOk() {
        handleConfirm(data._id);
      },
    });
  };

  const handleConfirm = async (id) => {
    setLoading(true);
    const response = await Api.Get(`/item/redeem/${id}`);
    if (response.data.status === true) {
      setLoading(false);
      success({
        centered: true,
        title: "Thank you for your redemption.",
        icon: (
          <span className="anticon">
            <i className="fas fa-check-circle"></i>
          </span>
        ),
        content: (
          <Text>Please wait for an admin to proceed the next step.</Text>
        ),
        onOk() {
          fetchData();
        },
      });
    } else {
      setLoading(false);
      error({
        centered: true,
        title: "You cannot redeem.",
        icon: (
          <span className="anticon">
            <i className="fas fa-times-circle"></i>
          </span>
        ),
        content: <Text>{response.data.msg}</Text>,
        onOk() {
          fetchData();
        },
      });
    }
  };

  return (
    <PageContainer
      header={{
        title: "Item",
        ghost: true,
        breadcrumb: {
          items: [
            {
              href: "/profile",
              title: "My Profile",
            },
            {
              title: "Item",
            },
          ],
        },
        extra: [
          <History key="history" columns={columns} route={`/history/item`} />,
        ],
      }}
    >
      <Row gutter={[16, 16]}>
        {data.map((item) => (
          <Col key={item._id} xs={12} sm={8} md={6}>
            <Card bordered={false} size="small">
              <Row gutter={[8, 8]}>
                <Col span={24}>
                  <div
                    className="kuber-item-page-image"
                    style={{
                      backgroundImage: `url(/api/static/items/${item._id}.png)`,
                    }}
                  >
                    <Image
                      src={`/api/static/items/${item._id}.png`}
                      alt={item.name}
                      preview={false}
                    />
                  </div>
                </Col>

                <Col span={24}>
                  <Row justify={"space-between"}>
                    <Col>
                      <Space direction="vertical" size={0}>
                        <Text type="secondary">
                          <small>Remaining</small>
                        </Text>
                        <Text strong>{item.amount}</Text>
                      </Space>
                    </Col>
                    <Col align="right">
                      <Space direction="vertical" size={0}>
                        <Text type="secondary">
                          <small>Value</small>
                        </Text>
                        <Space size={2}>
                          <Avatar src="/token/KBG.png" size={18} />
                          <Text strong>{item.kbg}</Text>
                        </Space>
                      </Space>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  {item.amount <= 0 ? (
                    <Button
                      block
                      type="primary"
                      shape="round"
                      disabled
                      icon={<i className="far fa-siren-on"></i>}
                    >
                      Out of stock
                    </Button>
                  ) : (
                    <Button
                      block
                      type="primary"
                      shape="round"
                      onClick={() => handleRedeem(item)}
                    >
                      Redeem
                    </Button>
                  )}
                </Col>
              </Row>
            </Card>
          </Col>
        ))}
      </Row>

      {loading === true && <Waiting open={loading} />}
    </PageContainer>
  );
}
