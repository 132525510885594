import { useEffect, useState } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { Maintenance } from "./Maintenance";
import { NotFound } from "./NotFound";
import { ProtectedRoute } from "./ProtectedRoute";
import { PrivateRoute } from "./PrivateRoute";
import { LayoutOther, LayoutMain } from "layouts";
import { Api } from "services";

import Login from "pages/Login";
import Profile from "pages/Profile";
import Dashboard from "pages/Dashboard";
import DailyCheckIn from "pages/DailyCheckIn";
// import Mission from "pages/Mission";
// import MissionDetail from "pages/Mission/Detail";
// import MissionCreate from "pages/Mission/Create";
import Item from "pages/Item";
import FeeCredit from "pages/FeeCredit";
import Leaderboard from "pages/Leaderboard";
import DAO from "pages/DAO";
import DAODetail from "pages/DAO/Detail";
// import LuckyDraw from "pages/LuckyDraw";
import Staking from "pages/Staking";
import Vote from "pages/Vote";
import VoteDetail from "pages/Vote/Detail";
import NFT from "pages/NFT";
import Qr from "pages/Qr";
import Event from "pages/Event";
import Random from "pages/Random";
import Booking from "pages/Booking";
import BookingDetail from "pages/Booking/Detail";
import Survey from "pages/Survey";

//management
import ManagementActivity from "pages/Management/Activity";
import ManagementEmployee from "pages/Management/Employee";
import ManagementItem from "pages/Management/Item";
import ManagementFeeCredit from "pages/Management/FeeCredit";
import ManagementLog from "pages/Management/Log";
import ManagementSurvey from "pages/Management/Survey";
import ManagementSurveyDetail from "pages/Management/SurveyDetail";
// import ManagementMission from "pages/Management/Mission";
// import ManagementMissionDetail from "pages/Management/MissionDetail";
import ManagementTransaction from "pages/Management/Transaction";
// import ManagementVote from "pages/Management/Vote";
//setting
import SettingAdministrator from "pages/Setting/Administrator";
import SettingTemplace from "pages/Setting/Templace";
import SettingToken from "pages/Setting/Token";

export const RouterConfig = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [employee, setEmployee] = useState();

  useEffect(() => {
    let current = location.pathname.split("/")[1];
    async function getUserInfo() {
      const response = await Api.Get(`/employee/short_info`);
      if (response.data.status === true) {
        if (localStorage.getItem("isRedirect")) {
          var path = localStorage.getItem("isRedirect");
          localStorage.removeItem("isRedirect");
          navigate(path);
        } else {
          if (
            location.pathname === "/" ||
            location.pathname === "/profile" ||
            location.pathname === "/login"
          ) {
            navigate(`/profile/${response.data.data._id}`);
          }
        }

        setEmployee(response.data.data);
        setLoading(false);
      } else {
        if (location.pathname !== "/login") {
          localStorage.setItem("isRedirect", location.pathname);
          navigate(`/login`);
        }

        setEmployee({ role: "" });
        setLoading(false);
      }
    }

    if (current !== "qr" && current !== "event") {
      getUserInfo();
    } else {
      setEmployee({ role: "" });
      setLoading(false);
    }
  }, [location, navigate]);

  if (loading) return null;

  return (
    <Routes>
      <Route
        path="/login"
        element={
          <LayoutOther>
            <Login />
          </LayoutOther>
        }
      />
      <Route
        path="/qr/:id/:event"
        element={
          <LayoutOther>
            <Qr />
          </LayoutOther>
        }
      />
      <Route path="/event" element={<Event />} />

      <Route path="/random" element={<ProtectedRoute role={employee.role} />}>
        <Route
          path="lucky_draw"
          element={
            <LayoutOther>
              <Random />
            </LayoutOther>
          }
        />
      </Route>

      <Route path="/" element={<LayoutMain data={employee} />}>
        <Route index element={<Profile />} />
        <Route path="profile/:id" element={<Profile />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="vote" element={<Vote />} />
        <Route path="vote/detail/:id" element={<VoteDetail />} />
        <Route path="daily_check_in" element={<DailyCheckIn />} />
        <Route path="mission" element={<Maintenance />} />
        <Route path="mission/detail/:id" element={<Maintenance />} />
        <Route path="item" element={<Item />} />
        <Route path="fee_credit" element={<FeeCredit />} />
        <Route path="leaderboard" element={<Leaderboard />} />
        <Route path="dao" element={<DAO />} />
        <Route path="dao/:id" element={<DAODetail />} />
        <Route path="lucky_draw" element={<Maintenance />} />
        <Route path="staking" element={<Staking />} />
        <Route path="nft/:id" element={<NFT />} />
        <Route path="survey" element={<Survey />} />
        <Route
          path="booking"
          element={
            <Booking
              employee={{
                name: employee.name,
                avatar: employee.avatar,
              }}
            />
          }
        />
        <Route
          path="booking/:id"
          element={
            <BookingDetail
              employee={{
                name: employee.name,
                avatar: employee.avatar,
              }}
            />
          }
        />
        <Route
          path="management"
          element={<ProtectedRoute role={employee.role} />}
        >
          <Route path="activity" element={<ManagementActivity />} />
          <Route path="employee" element={<ManagementEmployee />} />
          <Route path="item" element={<ManagementItem />} />
          <Route path="fee_credit" element={<ManagementFeeCredit />} />
          <Route path="log" element={<ManagementLog />} />

          <Route path="survey" element={<ManagementSurvey />} />
          <Route path="survey/:id" element={<ManagementSurveyDetail />} />
          
          <Route path="transaction" element={<ManagementTransaction />} />
          {/*<Route path="vote" element={<ManagementVote />} />
          <Route path="mission" element={<ManagementMission />} />
          <Route path="mission/:id" element={<ManagementMissionDetail />} />*/}
        </Route>
        <Route path="setting" element={<PrivateRoute role={employee.role} />}>
          <Route path="administrator" element={<SettingAdministrator />} />
          <Route path="templace" element={<SettingTemplace />} />
          <Route path="token" element={<SettingToken />} />
        </Route>

        <Route path="test" element={<ProtectedRoute role={employee.role} />}>
          <Route path="fee_credit" element={<FeeCredit />} />
        </Route>

        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};
