import { useEffect, useState } from "react";
import {
  Row,
  Col,
  Avatar,
  Carousel,
  Space,
  Typography,
  AutoComplete,
  Form,
  Result,
  Image,
  Alert,
} from "antd";
import {
  DrawerForm,
  ProFormSelect,
  ProFormTextArea,
  ProForm,
} from "@ant-design/pro-components";
import { addressFormat, generateUsername } from "utils";
import debounce from "lodash/debounce";
import { Api } from "services";
import moment from "moment-timezone";

const { Title, Text, Paragraph } = Typography;
const example = [
  {
    example_core: [
      "รักษาความลับขององค์กรอย่างสุดความสามารถ",
      "สื่อสารอย่างเปิดเผยและตรงไปตรงมาให้ทุกคนรับรู้กันอย่างทั่วถึง",
    ],
    example_msg: [
      "ขอบคุณน้อง Bullkub ที่เก็บรักษาความลับของทีมเป็นอย่างดีค่ะ!",
      "Thank you to P’Rabitian ให้ feedback น้องๆอย่างตรงไปตรงมา เอามงไปเลย!",
    ],
  },
  {
    example_core: [
      "ยึดภารกิจของบิทคับเป็นที่ตั้ง และคำนึงถึงในทุก ๆ การตัดสินใจ",
      "สรรหา และนำเสนอวิธีการใหม่ ๆ ที่ช่วยส่งเสริมภารกิจของบิทคับ",
    ],
    example_msg: [
      "ขอบคุณพี่แรบบิทสำหรับกิจกรรมเสริมทักษะ ใน Team Meeting ตลอดเล้ยยยย",
      "ขอบคุณพี่แรบบิทที่เสนอการแก้ไขปัญหาใหม่ๆ ได้อย่าง เยี่ยมจริงๆๆ!",
    ],
  },
  {
    example_core: [
      "ออกความเห็นอย่างตรงไปตรงมาเพื่อผลลัพธ์ที่ดีที่สุดของทีม",
      "ตัดสินใจร่วมกันในฐานะทีม และลุยไปด้วยกันจนสุดทาง",
    ],
    example_msg: [
      "ขอบคุณน้องบุญคับที่ออกความเห็นอย่างตรงไปตรงมาให้กับทีมของเราค่าาา",
      "พี่แรบบิท Challenger ของเรา ลุยกับทีมไปด้วยกันในงาน Bitkub Career!",
    ],
  },
  {
    example_core: [
      "ลำดับความสำคัญของงานอย่างชัดเจน และส่งมอบงานอย่างรวดเร็ว บนมาตรฐานที่สูงที่สุด",
      "มองหาวิธีการ และเครื่องมือใหม่ ๆ มาช่วยพัฒนาประสิทธิภาพงานเสมอ",
    ],
    example_msg: [
      "ขอบคุณน้องบุญคับสำหรับ Employee Report ที่รวดเร็วทันใจ :)",
      "ขอบคุณพี่แรบบิทสำหรับ Tools ใหม่ๆที่ช่วยพัฒนา Kuber อย่างต่อเนื่องค่าาา",
    ],
  },
  {
    example_core: [
      "รักษาคำพูดและทำสุดความสามารถเพื่อส่งมอบงานตามที่ตกลง",
      "ลงมือแก้ไขปัญหาทันทีที่พบเจอแม้จะนอกเหนือความรับผิดชอบของตัวเอง",
    ],
    example_msg: [
      "พี่แรบบิทคือ Project Owner ที่สุดปัง ทำให้ Bitkub Career Fanpage โด่งดังสุดๆ",
      "ขอบคุณน้องบุญคับที่แก้ไขปัญหาให้ลูกค้าได้อย่างรวดเร็ววว",
    ],
  },
  {
    example_core: [
      "มองหาเทรนด์ และเทคโนโลยีใหม่ ๆ เพื่อสร้างโอกาสให้กับองค์กรตลอดเวลา",
      "ใช้ทุก ๆ สถานการณ์เป็นโอกาสในการเรียนรู้ และพัฒนาตัวเอง",
    ],
    example_msg: [
      "พี่แรบบิท คือผู้นำเทรนด์ใหม่ๆมาปรับใช้กับทีมของเราตลอดเลยยย",
      "พี่แรบบิท ผู้เป็น Top Learner ประจำทีม ไอดอลเลยค่าาาา",
    ],
  },
  {
    example_core: [
      "รับฟังอย่างตั้งใจ และเข้าใจถึงความคิดและความรู้สึกของผู้อื่น",
      "แสดงความชื่นชมยินดีต่อความพยายามของผู้อื่น รวมถึงรู้ว่าเมื่อใดควรยืนหยัดในความคิด หรือเมื่อใดควรประนีประนอมเมื่อต้องทำงานร่วมกันเพื่อเป้าหมายของกันและกัน",
    ],
    example_msg: [
      "พี่แรบบิทคือ The Best Supporter รับฟังน้องๆตลอดเลย",
      "ได้กำลังใจจากพี่แรบบิทใน Mental Clinic มาเยอะมากๆเลยค่าาา",
    ],
  },
];

const Wallet = ({ open, balance, onClose, onFinish }) => {
  const [action, setAction] = useState(0);
  const [employee, setEmployee] = useState([]);
  const [mode, setMode] = useState("send");
  const [form] = Form.useForm();
  const [selected, setSelected] = useState();

  const afterChange = (index) => {
    setAction(index);
  };

  useEffect(() => {
    function checkAirdrop(day, hours) {
      var currentDate = moment.tz(new Date(), "Asia/Bangkok");
      return currentDate.date() === day && currentDate.hours() < hours;
    }
    setMode(checkAirdrop(1, 16) ? "airdrop" : "send");
  }, []);

  async function handleSearch(keyword) {
    if (keyword == "") {
      setEmployee([]);
    } else {
      const response = await Api.Get(`/employee/mention/${keyword}`);
      if (response.data.status === true) {
        if (response.data.data.length > 0) {
          let data = [];
          for (var i = 0; i < response.data.data.length; i++) {
            data.push({
              value: generateUsername(response.data.data[i].company_email),
              label: (
                <Space>
                  <Avatar src={response.data.data[i].avatar} size="small" />
                  <Text>
                    {generateUsername(response.data.data[i].company_email)}
                  </Text>
                </Space>
              ),
            });
          }
          setEmployee(data);
        } else {
          form.setFieldsValue({
            to: "",
          });
          setEmployee([]);
        }
      }
    }
  }

  const RenderAirdrop = (
    <Result
      icon={<Image src="/landing/airdrop.png" preview={false} alt="airdrop" />}
      subTitle={<Text type="secondary">On airdrop until 16:00</Text>}
    />
  );

  const RenderFormKBS = (
    <>
      <ProForm.Item
        name="to"
        label="Send to"
        rules={[{ required: true, message: "" }]}
      >
        <AutoComplete
          onSearch={debounce(handleSearch, 500)}
          placeholder="Search user"
          options={employee}
        />
      </ProForm.Item>

      <ProFormSelect
        name="amount"
        label="Amount (KBS)"
        options={[
          { label: "1", value: 1 },
          { label: "2", value: 2 },
          { label: "3", value: 3 },
          { label: "4", value: 4 },
          { label: "5", value: 5 },
        ]}
        fieldProps={{
          optionItemRender(item) {
            return (
              <Space size={4}>
                <Avatar src="/token/KBS.png" size={20} />
                <Text>
                  <small>{`${item.label} KBS`}</small>
                </Text>
              </Space>
            );
          },
        }}
        placeholder="Select amount"
        rules={[{ required: true, message: "" }]}
      />

      <ProFormSelect
        name="core_value"
        label="Choose core value"
        options={[
          { label: "1.Earn Trust", value: 1 },
          { label: "2.Passionate and Dive Deep", value: 2 },
          { label: "3.Challenge and Commit", value: 3 },
          { label: "4.Strive for results and Actions", value: 4 },
          { label: "5.Ownership and Beyond", value: 5 },
          { label: "6.Adapt and Innovate", value: 6 },
          { label: "7.Empathy and Collaboration", value: 7 },
        ]}
        fieldProps={{
          optionItemRender(item) {
            return (
              <Space size={4}>
                <Avatar src={`/core_value/${item.value}.svg`} size={20} />
                <Text>
                  <small>{`${item.label}`}</small>
                </Text>
              </Space>
            );
          },
        }}
        placeholder="Select core value"
        rules={[{ required: true, message: "" }]}
        onChange={(value) => setSelected(value)}
      />

      {selected && (
        <ProForm.Item>
          <Alert
            style={{ padding: "2px 10px" }}
            description={
              <Space direction="vertical" size={2}>
                <Text strong>
                  <small>ตัวอย่างพฤติกรรมพึงประสงค์</small>
                </Text>
                <Space direction="vertical" size={0}>
                  {example[selected - 1].example_core.map((item, index) => (
                    <Text key={index} type="secondary">
                      <small>{`${index + 1}. ${item}`}</small>
                    </Text>
                  ))}
                </Space>
                <Text strong>
                  <small>ตัวอย่างข้อความ</small>
                </Text>
                <Space direction="vertical" size={0}>
                  {example[selected - 1].example_msg.map((item, index) => (
                    <Text key={index} type="secondary">
                      <small>{`${index + 1}. ${item}`}</small>
                    </Text>
                  ))}
                </Space>
              </Space>
            }
            type="success"
          />
        </ProForm.Item>
      )}

      <ProFormTextArea
        name="comment"
        label="Details"
        rules={[{ required: true, message: "" }]}
        fieldProps={{
          showCount: {
            formatter: (info) => (
              <Text type="secondary">{`max length : ${
                info.maxLength - info.count
              }`}</Text>
            ),
          },
          maxLength: 150,
        }}
      />
    </>
  );

  return (
    <DrawerForm
      title="My Wallet"
      resize={{
        maxWidth: window.innerWidth * 0.8,
        minWidth: 300,
      }}
      open={open}
      drawerProps={{
        destroyOnClose: true,
        closable: false,
        onClose: onClose,
      }}
      submitTimeout={2000}
      onFinish={(values) => onFinish(values, mode)}
      form={form}
    >
      <Carousel afterChange={afterChange}>
        <div>
          <div
            className="wallet-cover"
            style={{
              backgroundImage: `url(/wallet/KBS.png)`,
            }}
          >
            <Space direction="vertical" style={{ display: "flex" }}>
              <Row justify={"space-between"} align={"middle"}>
                <Col>
                  <Avatar src="/token/KBS.png" size={"large"} />
                </Col>
                <Col>
                  <Title style={{ margin: 0 }} level={3}>
                    KBS
                  </Title>
                </Col>
              </Row>
              <Title style={{ margin: 0 }} level={3}>
                {balance.toFixed(2)}
              </Title>

              <Row justify={"space-between"} align={"bottom"}>
                <Col>
                  <Space direction="vertical" size={0}>
                    <Text>
                      <small>{"Token Address".toUpperCase()}</small>
                    </Text>
                    <Paragraph
                      copyable={{
                        text: "0x4dDBa8d9be405c867E3d4823AdcaB05A120E7d4C",
                      }}
                      style={{ margin: 0 }}
                      strong
                    >
                      {addressFormat(
                        "0x4dDBa8d9be405c867E3d4823AdcaB05A120E7d4C",
                        4,
                        4
                      )}
                    </Paragraph>
                  </Space>
                </Col>
                <Col>
                  <Space direction="vertical" size={0}>
                    <Text>
                      <small>{"Token Name".toUpperCase()}</small>
                    </Text>
                    <Text strong>{"Kuber Silver".toUpperCase()}</Text>
                  </Space>
                </Col>
              </Row>
            </Space>
          </div>
        </div>
        {/* <div>
          <div
            className="wallet-cover"
            style={{
              backgroundImage: `url(/wallet/KBG.png)`,
            }}
          >
            2
          </div>
        </div>
        <div>
          <div
            className="wallet-cover"
            style={{
              backgroundImage: `url(/wallet/KBC.png)`,
            }}
          >
            3
          </div>
        </div> */}
      </Carousel>
      {action === 0
        ? mode === "airdrop"
          ? RenderAirdrop
          : RenderFormKBS
        : null}
    </DrawerForm>
  );
};

export default Wallet;
