import { useRef, useState } from "react";
import {
  PageContainer,
  ProFormText,
  ProFormTextArea,
  ProFormGroup,
  ProFormDigit,
  ProForm,
} from "@ant-design/pro-components";
import { Download,Table, Drawer, Upload } from "components";
import {
  Button,
  Space,
  Avatar,
  Typography,
  Image,
  Switch,
  Popconfirm,
  InputNumber,
  Form,
  Modal,
} from "antd";
import moment from "moment-timezone";
import { Api } from "services";
import { useDocumentTitle } from "hooks";

const { Text } = Typography;
const { confirm } = Modal;

export default function Page() {
  useDocumentTitle("Management Item");
  const [form] = Form.useForm();
  const actionRef = useRef();
  const [active, setActive] = useState("redemption");

  const getFile = (image) => {
    form.setFieldsValue({
      image,
    });
  };

  const RenderForm = () => {
    return (
      <>
        <ProForm.Item label="Image" name="image" rules={[{ required: true }]}>
          <Upload aspect={1 / 1} getFile={getFile} />
        </ProForm.Item>
        <ProFormText
          name="name"
          label="Name"
          type="text"
          rules={[{ required: true }]}
        />
        <ProFormTextArea
          name="description"
          label="Description"
          rules={[{ required: true }]}
        />

        <ProFormGroup>
          <ProFormDigit
            name="kbg"
            label="Value (KBG)"
            rules={[{ required: true }]}
          />
          <ProFormDigit
            name="amount"
            label="Amount"
            rules={[{ required: true }]}
          />
        </ProFormGroup>
      </>
    );
  };

  const onChange = (key) => {
    setActive(key);
  };

  const handleStatus = async (id) => {
    const response = await Api.Get(`/item/edit_status/${id}`);
    if (response.data.status === true) {
      actionRef.current.reload();
    }
  };

  const handleAmount = (value, id, old_amount) => {
    var amount = parseInt(value) || 0;
    if (amount !== 0 && amount !== old_amount) {
      confirm({
        title: "Are you sure to change amount?",
        content: (
          <Space direction="vertical">
            <Text>{`Current Amount: ${old_amount}`}</Text>
            <Text>{`New Amount: ${amount}`}</Text>
          </Space>
        ),
        async onOk() {
          const response = await Api.Post(`/item/edit_amount/${id}`, {
            amount,
          });
          if (response.data.status === true) {
            actionRef.current.reload();
          }
        },
      });
    }
  };

  const handleFinishAdd = async (values) => {
    const response = await Api.Post(`/item/add`, values);
    if (response.data.status === true) {
      if (active === "list") actionRef.current.reload();
    }
  };

  const columnsRedemption = [
    {
      title: "Date Time",
      dataIndex: "created_at",
      key: "created_at",
      render: (item) => (
        <Text>{moment(item).format("DD/MM/YYYY, HH:mm:ss")}</Text>
      ),
    },
    {
      title: "Employee",
      dataIndex: "",
      key: "",
      render: (_, record) => (
        <Space>
          <Avatar src={record.employee_id.avatar} alt="" />
          <Text>{record.employee_id.company_email}</Text>
        </Space>
      ),
    },
    {
      title: "Item",
      dataIndex: "",
      key: "",
      render: (_, record) => (
        <Space>
          <Image
            src={`/api/static/items/${record.item_id._id}.png`}
            alt={record.item_id._id}
            width={28}
          />
          <Text>{record.item_id.name}</Text>
        </Space>
      ),
    },
    {
      title: "Value",
      dataIndex: "",
      key: "",
      align: "right",
      render: (_, record) => (
        <Space size={2}>
          <Text>{record.amount}</Text>
          <Image
            src={`/token/${record.symbol}.png`}
            alt={record.symbol}
            width={18}
            preview={false}
          />
        </Space>
      ),
    },
  ];

  const columnsList = [
    {
      title: "Date Created",
      dataIndex: "created_at",
      key: "created_at",
      render: (item) => (
        <Text>{moment(item).format("DD/MM/YYYY, HH:mm:ss")}</Text>
      ),
    },

    {
      title: "Item",
      dataIndex: "",
      key: "",
      width: 200,
      render: (_, record) => (
        <Space>
          <Image
            src={`/api/static/items/${record._id}.png`}
            alt={record.name}
            width={28}
          />
          <Text>{record.name}</Text>
        </Space>
      ),
    },
    {
      title: "Value",
      dataIndex: "",
      key: "",
      align: "right",
      render: (_, record) => (
        <Space size={2}>
          <Text>{record.kbg}</Text>
          <Image
            src={`/token/KBG.png`}
            alt={record.kbg}
            width={18}
            preview={false}
          />
        </Space>
      ),
    },
    {
      title: "Amount",
      dataIndex: "",
      key: "",
      align: "center",
      render: (_, record) => (
        <InputNumber
          controls={false}
          size="small"
          min={0}
          value={record.amount}
          onBlur={(e) =>
            handleAmount(e.target.value, record._id, record.amount)
          }
        />
      ),
    },

    {
      title: "Status",
      dataIndex: "",
      key: "",
      align: "center",
      render: (_, record) => (
        <Popconfirm
          title="Change the item"
          description="Are you sure to change status this item?"
          okText="Yes"
          cancelText="No"
          onConfirm={() => handleStatus(record._id)}
        >
          <Switch
            checked={record.status}
            checkedChildren={"active"}
            unCheckedChildren={"inactive"}
          />
        </Popconfirm>
      ),
    },
    {
      title: "Options",
      key: "",
      align: "center",
      render: (_) => (
        <Space>
          <Button
            type="link"
            // href={`#`}
            target="_blank"
            icon={<i className="far fa-file-search"></i>}
          />
        </Space>
      ),
    },
  ];

  return (
    <PageContainer
      header={{
        title: "Item",
        ghost: true,
        breadcrumb: {
          items: [
            {
              href: "/management",
              title: "Management",
            },
            {
              title: "Item",
            },
          ],
        },
        extra: [
          <Drawer
            key={"Item"}
            title={"Item"}
            form={form}
            render={<RenderForm />}
            onFinish={handleFinishAdd}
          />,
          <Download
            key={"download"}
            route="/item/download"
            columns={[
              "employee_id",
              "name",
              "amount",
              "symbol",
              "hash",
              "item",
              "created_at"


            ]}
          />,
        ]
      }}
      tabProps={{
        onChange,
      }}
      tabBarExtraContent={<Text>โชว์รายละเอียดเกี่ยวกับ Item</Text>}
      tabList={[
        {
          tab: "Redemption",
          key: "redemption",
        },
        {
          tab: "List",
          key: "list",
        },
      ]}
    >
      {active === "redemption" && (
        <Table
          columns={columnsRedemption}
          route={"/item/redemption"}
          actionRef={actionRef}
          keyword={`email`}
        />
      )}
      {active === "list" && (
        <Table
          columns={columnsList}
          route={"/item/list_all"}
          actionRef={actionRef}
          keyword={`name`}
          // add={{
          //   route: "/item/add",
          //   form: <RenderForm />,
          // }}
        />
      )}
    </PageContainer>
  );
}
