import { useState } from "react";
import {
  ModalForm,
  ProFormText,
  ProFormDateRangePicker,
  ProFormRadio,
  ProFormCheckbox,
} from "@ant-design/pro-components";
import { Button, Tooltip, message } from "antd";
import { Api } from "services";
import moment from "moment-timezone";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import Waiting from "./Waiting";

const Download = ({ route, columns, time=true }) => {
  const [open, setOpen] = useState(false);

  async function fetchData(values) {
    setOpen(true);
    const response = await Api.Post(route, values);
    if (response.data.status === true) {
      const data = response.data.data;
      const wb = new ExcelJS.Workbook();
      const worksheet = wb.addWorksheet();
      worksheet.columns = response.data.columns;
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        worksheet.addRow(element);
      }

      // worksheet.getCell("A1").font = {
      //   size: 14,
      //   bold: true,
      // };
      

      

      // for (var i = 0; i < data.length; i++) {
      //   worksheet.addRow({
      //     email: data[i].email,
      //     emp_id: data[i].emp_id,
      //     trading: data[i].trading,
      //     entity: data[i].entity,
      //     team: data[i].team,
      //     total: data[i].total,
      //   });
      //   for (var j = 0; j < data[i].history.length; j++) {
      //     worksheet.addRow({
      //       code: data[i].history[j].code,
      //       timestamp: moment(data[i].history[j].timestamp).format(
      //         "YYYY-MM-DD, HH:mm:ss"
      //       ),
      //     });
      //   }
      // }

      if (values.type === "xlsx") {
        var buf = await wb.xlsx.writeBuffer();
        saveAs(new Blob([buf]), `${values.name}.xlsx`);
      }
      if (values.type === "csv") {
        var buf = await wb.csv.writeBuffer();
        saveAs(new Blob(["\uFEFF"+buf]), `${values.name}.csv`);
      }
      message.success("Download successfully");
      setOpen(false);
    } else {
      message.error("No Data");
      setOpen(false);
    }
  }

  return (
    <>
      <ModalForm
        title="Download"
        trigger={
          <Tooltip title="Download">
            <Button
              key="download"
              icon={<i className="far fa-arrow-to-bottom"></i>}
            ></Button>
          </Tooltip>
        }
        width={400}
        modalProps={{
          destroyOnClose: true,
        }}
        onFinish={async (values) => {
          fetchData(values);
          return true;
        }}
      >
        <ProFormText
          name="name"
          label="ชื่อ"
          tooltip="ตั้งชื่อไฟล์ของคุณ"
          rules={[{ required: true }]}
        />
        
        {time && (<ProFormDateRangePicker
          name="period"
          label="เลือกช่วงเวลา"
          rules={[{ required: true }]}
        />)}

        {columns && (
          <ProFormCheckbox.Group
            name="columns"
            label="Columns"
            options={columns}
            rules={[{ required: true }]}
          />
        )}

        <ProFormRadio.Group
          name="type"
          label="เลือกชนิดไฟล์"
          options={[
            {
              label: ".csv",
              value: "csv",
            },
            {
              label: ".xlsx",
              value: "xlsx",
            },
          ]}
          rules={[{ required: true }]}
        />
      </ModalForm>
      {open === true && <Waiting open={open} />}
    </>
  );
};

export default Download;
