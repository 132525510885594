import { useState, useEffect } from "react";
import { Space, Typography, Card } from "antd";
import moment from "moment-timezone";

const { Title, Text } = Typography;
const Countdown = ({ time, endTime }) => {
  const [day, setDay] = useState(0);
  const [hour, setHour] = useState(0);
  const [min, setMin] = useState(0);
  const [sec, setSec] = useState(0);

  useEffect(() => {
    if (time) {
      var countDownDate = new Date(time).getTime();
      function addZero(i) {
        if (i < 10) {
          i = "0" + i;
        }
        return i;
      }
      const intervalId = setInterval(() => {
        var now = new Date().getTime();
        var timeleft = countDownDate - now;

        if (timeleft > 0) {
          var days = addZero(Math.floor(timeleft / (1000 * 60 * 60 * 24)));
          var hours = addZero(
            Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
          );
          var minutes = addZero(
            Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60))
          );
          var seconds = addZero(Math.floor((timeleft % (1000 * 60)) / 1000));
          setDay(days);
          setHour(hours);
          setMin(minutes);
          setSec(seconds);
          endTime(false);
        } else {
          setDay("00");
          setHour("00");
          setMin("00");
          setSec("00");
          endTime(true);
        }
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [time]);

  return (
    <Card bordered={false} bodyStyle={{ textAlign: "center" }}>
      <Space direction="vertical" size={"large"}>
        <Title level={3} style={{ marginTop: 0 }}>
          Staking Ended
        </Title>
        <ul className="countdown">
          <li>
            <Title level={2}>{day}</Title>
            <Text strong>Days</Text>
          </li>
          <li>
            <Title level={2}>{hour}</Title>
            <Text strong>Hours</Text>
          </li>
          <li>
            <Title level={2}>{min}</Title>
            <Text strong>Mins</Text>
          </li>
          <li>
            <Title level={2}>{sec}</Title>
            <Text strong>Secs</Text>
          </li>
        </ul>
        <Text type="secondary">{`(Until ${moment(time).format(
          "DD MMM YYYY, HH:mm:ss"
        )})`}</Text>
      </Space>
    </Card>
  );
};

export default Countdown;
