import "./index.css";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  PageContainer,
  ProDescriptions,
  ModalForm,
} from "@ant-design/pro-components";
import { Rose } from "@ant-design/plots";
import {
  Row,
  Col,
  Space,
  Tag,
  Avatar,
  Typography,
  Image,
  Tooltip,
  Card,
  Table,
  Tabs,
  Divider,
  Pagination,
  Select,
  Button,
  Upload,
  message,
  Dropdown,
} from "antd";
import moment from "moment-timezone";
import { Api } from "services";
import { CoreValue, Empty, NFTMission, Loading } from "components";
import CountUp from "react-countup";
import { generateUsername, addressFormat, getCoreValue } from "utils";
import { useDocumentTitle } from "hooks";
import ImgCrop from "antd-img-crop";

const { Title, Text, Paragraph, Link } = Typography;

export default function Page() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [active, setActive] = useState();
  const [data, setData] = useState(null);
  const [list, setList] = useState([]);
  const [current, setCurrent] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [total, setTotal] = useState(1);
  const [token, setToken] = useState("KBS");
  const [stat, setStat] = useState([]);
  const [owner, setOwner] = useState(false);
  const [cover, setCover] = useState(false);
  const [changeCover, setChangeCover] = useState(new Date().getTime());
  const items = [
    {
      label: "Information",
      key: "information",
    },
    {
      label: "Transaction",
      key: "transaction",
    },
    {
      label: "NFT Mission",
      key: "nft_mission",
    },
    {
      label: "NFT Achievement",
      key: "nft_achievement",
    },
  ];

  const config = {
    data: stat,
    xField: "name",
    yField: "point",
    seriesField: "name",
    legend: {
      position: "bottom",
    },
    label: false,
    radius: 0.8,
    innerRadius: 0.2,
    tooltip: {
      formatter: (data) => {
        return {
          name: "point",
          value: data.point,
        };
      },
    },
    colorField: "name",
    color: ({ name }) => {
      if (name === "Earn trust") return "#71c597";
      else if (name === "Passionate & dive deep") return "#4eb96a";
      else if (name === "Challenge & Commit") return "#1bb35a";
      else if (name === "Strive for results & actions") return "#347865";
      else if (name === "Ownership & Beyond") return "#00563e";
      else if (name === "Adapt & Innovate") return "#676767";
      return "#414242";
    },
    annotations: [
      {
        type: "html",
        position: ["50%", "50%"],
        html: (_, view) => {
          const coord = view.getCoordinate();
          const w = coord.polarRadius * coord.innerRadius * 1.15;
          return `<div style="transform:translate(-50%,-46%);">
          <img width="${
            (w / 203) * 231
          }"   alt="" src="/icon-512x512.png" style="border-radius: 50%">
        </div>`;
        },
      },
    ],
  };

  const uploadImage = async (options) => {
    const { onSuccess } = options;
    const fmData = new FormData();
    fmData.append("file", options.file);
    const res = await Api.Post("/upload/employee", fmData);
    if (res.data.status === true) {
      onSuccess("Ok");
      setChangeCover(new Date().getTime());
    } else {
      message.error("Please try again");
    }
  };

  useDocumentTitle(data ? generateUsername(data.company_email) : "");
  useEffect(() => {
    const fetchData = async (id) => {
      const result = await Api.Get(`/employee/profile/${id}`);
      if (result.data.status === true) {
        setData(result.data.data);
        setOwner(result.data.owner);
        setCover(result.data.cover);
        fetchInformation(id);
      } else {
        navigate(`/404`, {
          replace: true,
        });
      }
    };
    if (id) {
      fetchData(id);
    }
  }, [id, navigate]);

  const columns = [
    {
      title: "Timestamp",
      dataIndex: "timestamp",
      key: "timestamp",
      render: (item) => (
        <Text>{moment(item).format("DD/MM/YYYY, HH:mm:ss")}</Text>
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      align: "center",
      render: (item) => (
        <Space>
          {item === "AIR_DROP" && (
            <Tag bordered={false} color="processing" style={{ margin: 0 }}>
              <small>AIR DROP</small>
            </Tag>
          )}
          {item === "SEND" && (
            <Tag bordered={false} color="success" style={{ margin: 0 }}>
              <small>{item}</small>
            </Tag>
          )}
          {item === "CLAIM" && (
            <Tag bordered={false} color="gold" style={{ margin: 0 }}>
              <small>{item}</small>
            </Tag>
          )}
          {item === "UNSTAKE" && (
            <Tag bordered={false} color="cyan" style={{ margin: 0 }}>
              <small>{item}</small>
            </Tag>
          )}
          {item === "DAILY_CHECK_IN" && (
            <Tag bordered={false} color="purple" style={{ margin: 0 }}>
              <small>DAILY CHECK IN</small>
            </Tag>
          )}
        </Space>
      ),
    },
    {
      title: "From",
      dataIndex: "",
      key: "",
      align: "center",
      render: (_, record) => (
        <Tooltip title={record.from_name.split("@")[0]}>
          {record.from_id === "" ? (
            <Avatar src={record.from_avatar} />
          ) : (
            <Link href={`/profile/${record.from_id}`} target="_blank">
              <Avatar src={record.from_avatar} />
            </Link>
          )}
        </Tooltip>
      ),
    },
    {
      title: "To",
      dataIndex: "",
      key: "",
      align: "center",
      render: (_, record) => (
        <Tooltip title={record.to_name.split("@")[0]}>
          {record.to_id === "" ? (
            <Avatar src={record.to_avatar} />
          ) : (
            <Link href={`/profile/${record.to_id}`} target="_blank">
              <Avatar src={record.to_avatar} />
            </Link>
          )}
        </Tooltip>
      ),
    },
    {
      title: "Amount",
      dataIndex: "",
      key: "",
      align: "right",

      render: (_, record) => (
        <Space size={2}>
          <Text>{record.amount}</Text>
          <Image
            src={`/token/${token === "KBG" ? "KBG" : record.symbol}.png`}
            alt={token === "KBG" ? "KBG" : record.symbol}
            width={18}
            preview={false}
          />
        </Space>
      ),
    },
    {
      title: "Option",
      dataIndex: "",
      key: "",
      align: "center",
      render: (_, record) => (
        <ModalForm
          trigger={
            <Tooltip title="view">
              <Button
                type="link"
                icon={<i className="far fa-file-search"></i>}
              />
            </Tooltip>
          }
          submitter={false}
          modalProps={{
            destroyOnClose: true,
          }}
        >
          <ProDescriptions
            column={3}
            title="Information"
            size="small"
            layout="vertical"
            columns={[
              {
                title: () => (
                  <Text type="secondary">
                    <small>Type</small>
                  </Text>
                ),
                key: "",
                dataIndex: "",
                render: () => (
                  <Space>
                    {record.type === "AIR_DROP" && (
                      <Tag
                        bordered={false}
                        color="processing"
                        style={{ margin: 0 }}
                      >
                        <small>AIR DROP</small>
                      </Tag>
                    )}
                    {record.type === "SEND" && (
                      <Tag
                        bordered={false}
                        color="success"
                        style={{ margin: 0 }}
                      >
                        <small>{record.type}</small>
                      </Tag>
                    )}

                    {record.type === "CLAIM" && (
                      <Tag bordered={false} color="gold" style={{ margin: 0 }}>
                        <small>{record.type}</small>
                      </Tag>
                    )}
                    {record.type === "UNSTAKE" && (
                      <Tag bordered={false} color="cyan" style={{ margin: 0 }}>
                        <small>{record.type}</small>
                      </Tag>
                    )}

                    {record.type === "DAILY_CHECK_IN" && (
                      <Tag
                        bordered={false}
                        color="purple"
                        style={{ margin: 0 }}
                      >
                        <small>DAILY CHECK IN</small>
                      </Tag>
                    )}
                  </Space>
                ),
              },
              {
                title: () => (
                  <Text type="secondary">
                    <small>From</small>
                  </Text>
                ),
                key: "",
                dataIndex: "",
                render: () => (
                  <Space>
                    <Avatar src={record.from_avatar} />
                    {record.from_id === "" ? (
                      <Text strong>{generateUsername(record.from_name)}</Text>
                    ) : (
                      <Link
                        strong
                        href={`/profile/${record.from_id}`}
                        target="_blank"
                      >
                        {generateUsername(record.from_name)}
                      </Link>
                    )}
                  </Space>
                ),
              },
              {
                title: () => (
                  <Text type="secondary">
                    <small>To</small>
                  </Text>
                ),
                key: "",
                dataIndex: "",
                render: () => (
                  <Space>
                    <Avatar src={record.to_avatar} />
                    {record.to_id === "" ? (
                      <Text strong>{generateUsername(record.to_name)}</Text>
                    ) : (
                      <Link
                        strong
                        href={`/profile/${record.to_id}`}
                        target="_blank"
                      >
                        {generateUsername(record.to_name)}
                      </Link>
                    )}
                  </Space>
                ),
              },
              {
                title: () => (
                  <Text type="secondary">
                    <small>Comment</small>
                  </Text>
                ),
                key: "",
                dataIndex: "",
                span: 3,
                render: () => (
                  <Card className="card-comment">
                    <Paragraph style={{ margin: 0 }}>
                      {record.comment}
                    </Paragraph>
                  </Card>
                ),
              },
              {
                title: () => (
                  <Text type="secondary">
                    <small>Core Value</small>
                  </Text>
                ),
                key: "",
                dataIndex: "",
                render: () => (
                  <Space>
                    {record.core_value === 0 ? (
                      <Text>-</Text>
                    ) : (
                      <>
                        <Avatar src={`/core_value/${record.core_value}.svg`} />
                        <Text>{getCoreValue(record.core_value - 1)}</Text>
                      </>
                    )}
                  </Space>
                ),
              },
              {
                title: () => (
                  <Text type="secondary">
                    <small>Point</small>
                  </Text>
                ),
                key: "",
                dataIndex: "",
                render: () => (
                  <Space>
                    {record.point === 0 ? (
                      <Text>-</Text>
                    ) : (
                      <>
                        <Avatar src={record.to_avatar} />
                        <Text strong>{`+${record.point} points`}</Text>
                      </>
                    )}
                  </Space>
                ),
              },
              {
                title: () => (
                  <Text type="secondary">
                    <small>Amount</small>
                  </Text>
                ),
                key: "",
                dataIndex: "",
                render: () => (
                  <Space size={2}>
                    <Text>{record.amount}</Text>
                    <Image
                      src={`/token/${
                        token === "KBG" ? "KBG" : record.symbol
                      }.png`}
                      alt={token === "KBG" ? "KBG" : record.symbol}
                      width={18}
                      preview={false}
                    />
                  </Space>
                ),
              },
              {
                title: () => (
                  <Text type="secondary">
                    <small>Hash</small>
                  </Text>
                ),
                key: "",
                dataIndex: "",
                span: 3,
                render: () => (
                  <Link
                    underline
                    href={`https://testnet.bkcscan.com/tx/${record.hash}`}
                    target="_blank"
                  >
                    {addressFormat(record.hash, 20, 10)}
                  </Link>
                ),
              },
            ]}
          />
        </ModalForm>
      ),
    },
  ];

  const fetchInformation = async (id) => {
    const result = await Api.Get(`/employee/full_info/${id}`);
    if (result.data.status === true) {
      setActive("information");
      setStat(result.data.stat);
      setLoading(false);
    }
  };

  const fetchTransaction = async (token, current) => {
    const result = await Api.Get(
      `/transaction/list_wallet/${id}?token=${token}&current=${current}`
    );
    if (result.data.status === true) {
      setList(result.data.data);
      setCurrent(result.data.info.current);
      setPageCount(result.data.info.pageCount);
      setTotal(result.data.info.rowCount);
      setActive("transaction");
      setToken(token);
      setLoading(false);
    }
  };

  const fetchNFTMission = async (current) => {
    const result = await Api.Get(`/nft/mission_list/${id}?current=${current}`);
    if (result.data.status === true) {
      setList(result.data.data);
      setCurrent(result.data.info.current);
      setPageCount(result.data.info.pageCount);
      setTotal(result.data.info.rowCount);
      setActive("nft_mission");
      setLoading(false);
    }
  };

  const fetchNFTAchievement = async (current) => {
    const result = await Api.Get(
      `/nft/achievement_list/${id}?current=${current}`
    );
    if (result.data.status === true) {
      setList(result.data.data);
      setCurrent(result.data.info.current);
      setPageCount(result.data.info.pageCount);
      setTotal(result.data.info.rowCount);
      setActive("nft_achievement");
      setLoading(false);
    }
  };

  const handleRemoveCover = async () => {
    const response = await Api.Get("/employee/remove_cover");
    if (response.data.status === true) {
      setCover(false);
      setChangeCover(new Date().getTime());
    } else {
      message.error("Please try again");
    }
  };

  if (data)
    return (
      <PageContainer className="kuber-profile-page">
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Card
              bordered={false}
              cover={
                <div
                  className="cover"
                  style={{
                    backgroundImage: `url(/api/media/employee/cover-${data._id}.png?time=${changeCover})`,
                  }}
                >
                  <Image
                    alt="example"
                    src={`/api/media/employee/cover-${data._id}.png?time=${changeCover}`}
                    preview={false}
                  />

                  {owner === true && (
                    <div className="button">
                      <Tooltip title="Cover photo">
                        <Dropdown
                          menu={{
                            items: [
                              {
                                label: (
                                  <ImgCrop rotationSlider aspect={3 / 1}>
                                    <Upload
                                      accept="image/png, image/jpg"
                                      customRequest={uploadImage}
                                      showUploadList={false}
                                    >
                                      Upload
                                    </Upload>
                                  </ImgCrop>
                                ),
                                key: "upload",
                                icon: <i className="fas fa-upload"></i>,
                              },
                              {
                                label: "Remove",
                                key: "remove",
                                icon: <i className="fas fa-trash-alt"></i>,
                                disabled: !cover,
                              },
                            ],
                            onClick: ({ key }) => {
                              if (key === "remove") handleRemoveCover();
                            },
                          }}
                          trigger={["click"]}
                        >
                          <Button
                            icon={<i className="fad fa-image-polaroid"></i>}
                            shape="circle"
                          ></Button>
                        </Dropdown>
                      </Tooltip>
                    </div>
                  )}
                </div>
              }
              bodyStyle={{ padding: "16px 16px 0 " }}
            >
              <Row align={"bottom"}>
                <Col xs={0} md={9}>
                  <Space split={<Divider type="vertical" />}>
                    <Space
                      direction="vertical"
                      size={4}
                      style={{ textAlign: "initial" }}
                    >
                      <Text type="secondary">
                        <small>KBS</small>
                      </Text>
                      <Space size={4}>
                        <Image
                          src={`/token/KBS.png`}
                          width={20}
                          preview={false}
                        />
                        <Text strong>
                          <CountUp end={data.balanceKBS} separator="," />
                        </Text>
                      </Space>
                    </Space>
                    <Space
                      direction="vertical"
                      size={4}
                      style={{ textAlign: "initial" }}
                    >
                      <Text type="secondary">
                        <small>KBG</small>
                      </Text>
                      <Space size={4}>
                        <Image
                          src={`/token/KBG.png`}
                          width={20}
                          preview={false}
                        />
                        <Text strong>
                          <CountUp end={data.balanceKBG} separator="," />
                        </Text>
                      </Space>
                    </Space>
                    <Space
                      direction="vertical"
                      size={4}
                      style={{ textAlign: "initial" }}
                    >
                      <Text type="secondary">
                        <small>KBC</small>
                      </Text>
                      <Space size={4}>
                        <Image
                          src={`/token/KBC.png`}
                          width={20}
                          preview={false}
                        />
                        <Text strong>
                          <CountUp end={data.balanceKBC} separator="," />
                        </Text>
                      </Space>
                    </Space>
                  </Space>
                </Col>

                <Col xs={0} md={6} align="center">
                  <div className="avatar">
                    <Avatar src={data.avatar} size={100} />
                    <div className="rank">
                      <Tooltip title={data.rank_name.toUpperCase()}>
                        <Avatar src={`/rank/${data.rank_name}.png`} size={32} />
                      </Tooltip>
                    </div>
                  </div>
                  <Space direction="vertical">
                    <Title level={5} style={{ margin: 0 }}>
                      {generateUsername(data.company_email)}
                    </Title>
                  </Space>
                </Col>

                <Col xs={24} md={0} align="center">
                  <div className="avatar">
                    <Avatar src={data.avatar} size={84} />
                    <div className="rank">
                      <Tooltip title={data.rank_name.toUpperCase()}>
                        <Avatar src={`/rank/${data.rank_name}.png`} size={28} />
                      </Tooltip>
                    </div>
                  </div>
                  <Space>
                    <Title level={5} style={{ margin: 0 }}>
                      {generateUsername(data.company_email)}
                    </Title>
                    <Image
                      src={`/dao/${data.dao_id.name}.png`}
                      width={40}
                      preview={false}
                    />
                  </Space>
                </Col>

                <Col xs={0} md={9} align="right">
                  <Space split={<Divider type="vertical" />}>
                    <Space
                      direction="vertical"
                      size={4}
                      style={{ textAlign: "initial" }}
                    >
                      <Text type="secondary">
                        <small>Wallet</small>
                      </Text>
                      <Paragraph
                        copyable={{
                          text: data.wallet[0].address,
                        }}
                        style={{ margin: 0 }}
                      >
                        <small>
                          {addressFormat(data.wallet[0].address, 2, 4)}
                        </small>
                      </Paragraph>
                    </Space>
                    <Space
                      direction="vertical"
                      size={4}
                      style={{ textAlign: "initial" }}
                    >
                      <Text type="secondary">
                        <small>Badge</small>
                      </Text>
                      <Space size={4}>
                        <Image
                          src={`/token/${data.dao_id.token}.png`}
                          width={20}
                          preview={false}
                        />
                        <Text strong>
                          <CountUp
                            end={data.wallet[0].balanceBadge}
                            separator=","
                          />
                        </Text>
                      </Space>
                    </Space>
                    <Image
                      src={`/dao/${data.dao_id.name}.png`}
                      width={50}
                      preview={false}
                    />
                  </Space>
                </Col>

                <Col xs={24} md={0} align="center">
                  <Space split={<Divider type="vertical" />}>
                    <Space
                      direction="vertical"
                      size={4}
                      style={{ textAlign: "initial" }}
                    >
                      <Text type="secondary">
                        <small>KBS</small>
                      </Text>
                      <Space size={4}>
                        <Image
                          src={`/token/KBS.png`}
                          width={20}
                          preview={false}
                        />
                        <Text strong>
                          <CountUp end={data.balanceKBS} separator="," />
                        </Text>
                      </Space>
                    </Space>
                    <Space
                      direction="vertical"
                      size={4}
                      style={{ textAlign: "initial" }}
                    >
                      <Text type="secondary">
                        <small>KBG</small>
                      </Text>
                      <Space size={4}>
                        <Image
                          src={`/token/KBG.png`}
                          width={20}
                          preview={false}
                        />
                        <Text strong>
                          <CountUp end={data.balanceKBG} separator="," />
                        </Text>
                      </Space>
                    </Space>
                    <Space
                      direction="vertical"
                      size={4}
                      style={{ textAlign: "initial" }}
                    >
                      <Text type="secondary">
                        <small>KBC</small>
                      </Text>
                      <Space size={4}>
                        <Image
                          src={`/token/KBC.png`}
                          width={20}
                          preview={false}
                        />
                        <Text strong>
                          <CountUp end={data.balanceKBC} separator="," />
                        </Text>
                      </Space>
                    </Space>
                    <Space
                      direction="vertical"
                      size={4}
                      style={{ textAlign: "initial" }}
                    >
                      <Text type="secondary">
                        <small>Badge</small>
                      </Text>
                      <Space size={4}>
                        <Image
                          src={`/token/${data.dao_id.token}.png`}
                          width={20}
                          preview={false}
                        />
                        <Text strong>
                          <CountUp
                            end={data.wallet[0].balanceBadge}
                            separator=","
                          />
                        </Text>
                      </Space>
                    </Space>
                  </Space>
                </Col>

                <Col xs={24} md={0} align="center">
                  <Paragraph
                    copyable={{
                      text: data.wallet[0].address,
                    }}
                    style={{ margin: "8px 0 0" }}
                  >
                    <small>{addressFormat(data.wallet[0].address, 8, 8)}</small>
                  </Paragraph>
                </Col>

                <Col xs={0} md={24}>
                  <Tabs
                    activeKey={active}
                    centered
                    onChange={(key) => {
                      setLoading(true);
                      if (key === "information") fetchInformation(id);
                      if (key === "transaction") fetchTransaction("KBS", 1);
                      if (key === "nft_mission") fetchNFTMission(1);
                      if (key === "nft_achievement") fetchNFTAchievement(1);
                    }}
                    items={items}
                  />
                </Col>

                <Col xs={24} md={0}>
                  <Tabs
                    activeKey={active}
                    onChange={(key) => {
                      setLoading(true);
                      if (key === "information") fetchInformation(id);
                      if (key === "transaction") fetchTransaction("KBS", 1);
                      if (key === "nft_mission") fetchNFTMission(1);
                      if (key === "nft_achievement") fetchNFTAchievement(1);
                    }}
                    items={items}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={0} md={6}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <CoreValue data={data.coreValue} />
              </Col>
              <Col span={24}>
                <NFTMission data={data.nftMission} />
              </Col>
            </Row>
          </Col>

          {loading === true ? (
            <Col xs={24} md={18}>
              <Loading />
            </Col>
          ) : (
            <Col xs={24} md={18}>
              {active === "information" && (
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Card bordered={false}>
                      <Row justify={"center"}>
                        <Col span={24}>
                          <ProDescriptions
                            column={3}
                            title="Information"
                            size="small"
                            layout="vertical"
                            request={async () => {
                              const result = await Api.Get(
                                `/employee/full_info/${id}`
                              );
                              return result.data;
                            }}
                            emptytext={"-"}
                            columns={[
                              {
                                title: () => (
                                  <Text type="secondary">
                                    <small>Name</small>
                                  </Text>
                                ),
                                key: "",
                                dataIndex: "",
                                render: (_, record) => (
                                  <Space>{`${record.first_name_en} ${record.last_name_en}`}</Space>
                                ),
                              },
                              {
                                title: () => (
                                  <Text type="secondary">
                                    <small>ชื่อ</small>
                                  </Text>
                                ),
                                key: "",
                                dataIndex: "",
                                render: (_, record) => (
                                  <Space>{`${record.first_name_th} ${record.last_name_th}`}</Space>
                                ),
                              },

                              {
                                title: () => (
                                  <Text type="secondary">
                                    <small>Nickname</small>
                                  </Text>
                                ),
                                key: "nickname",
                                dataIndex: "nickname",
                              },

                              {
                                title: () => (
                                  <Text type="secondary">
                                    <small>Employee ID</small>
                                  </Text>
                                ),
                                key: "employee_id",
                                dataIndex: "employee_id",
                              },
                              {
                                title: () => (
                                  <Text type="secondary">
                                    <small>Entity</small>
                                  </Text>
                                ),
                                key: "",
                                dataIndex: "",
                                render: (_, record) => (
                                  <Text>{record.entity_id.name}</Text>
                                ),
                              },

                              {
                                title: () => (
                                  <Text type="secondary">
                                    <small>Company Email</small>
                                  </Text>
                                ),
                                key: "company_email",
                                dataIndex: "company_email",
                              },

                              {
                                key: "updated_at",
                                dataIndex: "updated_at",
                                valueType: "option",
                                render: (item) => [
                                  <Text type="secondary" key="date">
                                    <small>{`Last updated on ${moment(
                                      item
                                    ).format("DD/MM/YYYY, HH:mm")}`}</small>
                                  </Text>,
                                ],
                              },
                            ]}
                          />
                        </Col>
                        <Col span={24} align="center">
                          <Rose {...config} />
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              )}

              {active === "transaction" && (
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Card
                      bordered={false}
                      title={
                        <Row justify={"space-between"}>
                          <Col>
                            <Title level={5} style={{ margin: 0 }}>
                              Transaction
                            </Title>
                          </Col>
                          <Col>
                            <Space align="middle">
                              <Text type="secondary">
                                <small>Filter by : </small>
                              </Text>
                              <Select
                                size="small"
                                value={token}
                                style={{ width: 100 }}
                                options={[
                                  { value: "KBS", label: "KBS" },
                                  { value: "KBG", label: "KBG" },
                                  { value: "KBC", label: "KBC" },
                                  { value: "BADGE", label: "Badge" },
                                ]}
                                onChange={(value) => fetchTransaction(value, 1)}
                              />
                            </Space>
                          </Col>
                        </Row>
                      }
                      bodyStyle={{ padding: 0, overflowX: "auto" }}
                    >
                      {list.length !== 0 ? (
                        <Table
                          columns={columns}
                          dataSource={list}
                          size="small"
                          pagination={false}
                        />
                      ) : (
                        <Empty />
                      )}
                    </Card>
                  </Col>

                  {list.length !== 0 && (
                    <Col span={24}>
                      <Row justify={"space-between"}>
                        <Col>
                          <Text type="secondary">
                            Showing {(current - 1) * 10 + 1} to{" "}
                            {current < pageCount ? current * 10 : total} of{" "}
                            {total} lists
                          </Text>
                        </Col>
                        <Col>
                          <Pagination
                            size="small"
                            current={current}
                            total={total}
                            showSizeChanger={false}
                            onChange={(value) => fetchTransaction(token, value)}
                          />
                        </Col>
                      </Row>
                    </Col>
                  )}
                </Row>
              )}

              {active === "nft_mission" && (
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Card bordered={false}>
                      <Row justify={"space-between"}>
                        <Col>
                          <Title level={5} style={{ margin: 0 }}>
                            NFT Mission
                          </Title>
                        </Col>
                      </Row>
                    </Card>
                  </Col>

                  {list.map((item) => (
                    <Col key={item._id} xs={12} md={6}>
                      <Card bordered={false} size="small">
                        <Link href={`/nft/${item._id}`} target="_blank">
                          <div
                            className="cover"
                            style={{
                              backgroundImage: `url(/api/static/nfts/${item.mission_id}.png)`,
                            }}
                          >
                            <Image
                              src={`/api/media/nft/${item.mission_id}/${item.number}.png`}
                              preview={false}
                            />
                          </div>
                        </Link>
                      </Card>
                    </Col>
                  ))}

                  <Col span={24}>
                    {list.length !== 0 ? (
                      <Row justify={"space-between"}>
                        <Col>
                          <Text type="secondary">
                            Showing {(current - 1) * 8 + 1} to{" "}
                            {current < pageCount ? current * 8 : total} of{" "}
                            {total} lists
                          </Text>
                        </Col>
                        <Col>
                          <Pagination
                            size="small"
                            current={current}
                            total={total}
                            pageSize={8}
                            showSizeChanger={false}
                            onChange={(value) => fetchNFTMission(value)}
                          />
                        </Col>
                      </Row>
                    ) : (
                      <Empty />
                    )}
                  </Col>
                </Row>
              )}

              {active === "nft_achievement" && (
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Card bordered={false}>
                      <Row justify={"space-between"}>
                        <Col>
                          <Title level={5} style={{ margin: 0 }}>
                            NFT Achievement
                          </Title>
                        </Col>
                      </Row>
                    </Card>
                  </Col>

                  {list.map((item) => (
                    <Col key={item._id} md={6}>
                      <Card bordered={false} size="small">
                        <Link href={`/nft/${item._id}`} target="_blank">
                          <Space>
                            <div
                              className="cover"
                              style={{
                                backgroundImage: `url(${item.ipfs_uri}/${item.ipfs_image})`,
                              }}
                            >
                              <Image
                                src={`${item.ipfs_uri}/${item.ipfs_image}`}
                                preview={false}
                              />
                            </div>
                          </Space>
                        </Link>
                      </Card>
                    </Col>
                  ))}

                  <Col span={24}>
                    {list.length !== 0 ? (
                      <Row justify={"space-between"}>
                        <Col>
                          <Text type="secondary">
                            Showing {(current - 1) * 8 + 1} to{" "}
                            {current < pageCount ? current * 8 : total} of{" "}
                            {total} lists
                          </Text>
                        </Col>
                        <Col>
                          <Pagination
                            size="small"
                            current={current}
                            total={total}
                            pageSize={8}
                            showSizeChanger={false}
                            onChange={(value) => fetchNFTAchievement(value)}
                          />
                        </Col>
                      </Row>
                    ) : (
                      <Empty />
                    )}
                  </Col>
                </Row>
              )}
            </Col>
          )}
        </Row>
      </PageContainer>
    );
}
