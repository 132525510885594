import { useRef, useState } from "react";
import {
  DrawerForm,
  PageContainer,
  ProTable,
  ModalForm,
  ProFormSelect
} from "@ant-design/pro-components";
import { Table } from "components";
import {
  Button,
  Space,
  Typography,
  Image,
  Avatar,
  Popconfirm,
  Badge,
} from "antd";
import moment from "moment-timezone";
import { useDocumentTitle } from "hooks";
import { Api } from "services";
import { generateUsername } from "utils";

const { Text } = Typography;

export default function Page() {
  useDocumentTitle("Management Activity");
  const actionRef = useRef();
  const actionHistoryRef = useRef();
  const [current, setCurrent] = useState(1);
  const [total, setTotal] = useState(1);
  const [selectData, setSelectData] = useState(false);

  const fetchData = async (params, id) => {
    var str = Object.keys(params)
      .map((key) => {
        return "" + key + "=" + params[key];
      })
      .join("&");

    try {
      const result = await Api.Get(
        "/activity/history/" + id + "?" + str,
        localStorage.getItem("token")
      );

      if (result.data.status === true) {
        setCurrent(result.data.info ? result.data.info.current : 1);
        setTotal(result.data.info ? result.data.info.rowCount : 1);
        return result.data;
      }
    } catch (error) {
      alert("Please try again");
    }
  };

  const handleApprove = async (id) => {
    const response = await Api.Get(`/activity/history_approve/${id}`);
    if (response.data.status === true) {
      actionHistoryRef.current.reload();
    }
  };

  const handleReject = async (data,id) => {
    await Api.Get(`/activity/history_reject/${id}/${data.reason}`);

     actionHistoryRef.current.reload();

    return true
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: 150,
      ellipsis: true,
    },
    {
      title: "DAO",
      dataIndex: "",
      key: "",
      align: "center",
      render: (_, record) => (
        <Image
          src={`/dao/${record.dao_id?.name}.png`}
          alt=""
          preview={false}
          width={40}
        />
      ),
    },
    {
      title: "Activity Type",
      dataIndex: "",
      key: "",
      align: "center",
      render: (_, record) => (
        <Space>
          <Image
            src={`/activity/${record.activity_type}.png`}
            alt=""
            width={24}
          />
          <Text>{record.activity_type}</Text>
        </Space>
      ),
    },

    {
      title: "Created Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (item) => <Text>{moment(item).format("YYYY-MM-DD, HH:mm")}</Text>,
    },

    {
      title: "Activity Status",
      dataIndex: "activity_status",
      key: "activity_status",
      align: "center",
      render: () => <Badge status="success" text="Approved" />,
    },

    {
      title: "Options",
      key: "",
      align: "center",
      render: (_, record) => (
        <DrawerForm
          title={`${record.title} : ${record.dao_id.name}`}
          resize={{
            maxWidth: window.innerWidth * 0.8,
            minWidth: 300,
          }}
          width={600}
          trigger={
            <Button type="link" icon={<i className="far fa-file-search"></i>} />
          }
          drawerProps={{
            destroyOnClose: true,
            onClose: () => {
              setCurrent(1);
              setSelectData(false)
            },
          }}
          submitter={false}
        >
          <ProTable
            actionRef={actionHistoryRef}
            columns={columnsHistory}
            request={async (params = {}) => {
              setSelectData(record)
              return fetchData(params, record._id);
            }}
            rowKey="_id"
            pagination={{
              pageSize: 10,
              current: current,
              total: total,
              showSizeChanger: false,
            }}
            size="small"
            search={false}
            options={false}
            headerTitle="History"
            toolBarRender={() => [
              <Text key={"date"} type="secondary">
                <small>{`Last updated on: ${moment().format(
                  "DD/MM/YYYY, HH:mm"
                )}`}</small>
              </Text>,
            ]}
          />
        </DrawerForm>
      ),
    },
  ];

  const columnsHistory = [
    {
      title: "Employee",
      dataIndex: "",
      key: "",
      width: 200,
      ellipsis: true,
      render: (_, record) => (
        <Space>
          <Avatar src={record.employee_id.avatar} alt="" />
          <Text>{generateUsername(record.employee_id.company_email)}</Text>
        </Space>
      ),
    },
    {
      title: "Description",
      dataIndex: "form",
      key: "form",
      width: 150,
      align: "center",
      render: (_, record) => (
        <Space direction="vertical">
          <Image
            src={`/api/media/activity/${record.activity_id}/${
              JSON.parse(record.form).image
            }`}
            alt={JSON.parse(record.form).image}
            width={"20%"}
          />
          <Space>
            <Text type="secondary">
              <small>Distance: </small>
            </Text>
            <Text strong>
              <small>{JSON.parse(record.form).distance}</small>
            </Text>
          </Space>
        </Space>
      ),
    },
    {
      title: "Created Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (item) => <Text>{moment(item).format("DD/MM/YYYY, HH:mm")}</Text>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (_, record) => (
        <Space size={4}>
          {record.status === "pending" ? (
            <>
              <Popconfirm
                title="Approve the task"
                description="Are you sure to approve this task?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => handleApprove(record._id)}
              >
                <Button
                  size="small"
                  type="text"
                  icon={<i className="far fa-check"></i>}
                  type="primary"
                  shape="circle"
                />
              </Popconfirm>

          <ModalForm
            width={400}
            title="Reject the task"
            trigger={
              <Button
                size="small"
                type="text"
                icon={<i className="far fa-times"></i>}
                type="primary"
                danger
                shape="circle"
              />
            }
            modalProps={{
              destroyOnClose: true,
            }}
            onFinish={async(values) => {
              await handleReject(values,record._id)
              setSelectData(false)
              return true;
            }}
          >
            <ProFormSelect
                name="reason"
                label="กรณีที่ถูก Reject"
                options={[
                  { label: "อัพระยะการวิ่งเกิน 3 วัน", value: 1 },
                  { label: "หลักฐานการวิ่งไม่มีวันที่", value: 2 },
                  { label: "กิโลเมตรการวิ่งผิดพลาด ", value: 3 },
                  { label: "ส่งการวิ่งมากกว่า 1 Application", value: 4 }
                ]}
                rules={[{ required: true, message: "" }]}
              />
          </ModalForm>

            </>
          ) : record.status === "approved" ? (
            <Badge status="success" text="Approved" />
          ) : (
            <Badge status="error" text="Rejected" />
          )}
        </Space>
      ),
    },
  ];

  return (
    <PageContainer
      header={{
        title: "Activity",
        ghost: true,
        breadcrumb: {
          items: [
            {
              href: "/management",
              title: "Management",
            },
            {
              title: "Activity",
            },
          ],
        },
      }}
    >
      <Table
        columns={columns}
        route={"/activity/list"}
        actionRef={actionRef}
        keyword={`title`}
      />





    </PageContainer>
  );
}
