import "./index.css";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { UserOutlined, TrophyOutlined } from '@ant-design/icons';
import {
  PageContainer,
  DrawerForm,
  ProForm,
  ProFormDigit,
} from "@ant-design/pro-components";
import {
  Row,
  Col,
  Button,
  Space,
  Avatar,
  Typography,
  Image,
  Tooltip,
  Card,
  Tabs,
  Divider,
  Pagination,
  Select,
  Progress,
  Badge,
  Form,
  Modal,
} from "antd";
import { Api } from "services";
import { Avenger, Empty, History, Loading, Upload } from "components";
import { generateUsername, addressFormat } from "utils";
import CountUp from "react-countup";
import { useDocumentTitle } from "hooks";
import moment from "moment-timezone";

const { Title, Text, Paragraph, Link } = Typography;
const { success } = Modal;

export default function Page() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [active, setActive] = useState("member");
  const [data, setData] = useState(null);
  const [list, setList] = useState([]);
  const [current, setCurrent] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [total, setTotal] = useState(1);
  const itemsOwner = [
    {
      label: "Member",
      key: "member",
    },
    {
      label: (
        <Space size={[2]}>
          <Text>Activity</Text>
          <Tooltip title="Only member can see this">
            <Button size="small" type="text">
              <i className="far fa-lock-alt"></i>
            </Button>
          </Tooltip>
        </Space>
      ),
      key: "activity",
    },
  ];

  const itemsOther = [
    {
      label: "Member",
      key: "member",
    },
  ];

  useDocumentTitle(data ? data.name : "");

  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [id]);

  const fetchData = async (id) => {
    const result = await Api.Get(`/dao/info/${id}`);
    if (result.data.status === true) {
      setData(result.data.data);
      fetchMember(1);
    } else {
      navigate(`/404`, {
        replace: true,
      });
    }
  };

  const fetchMember = async (current) => {
    const result = await Api.Get(`/dao/member/${id}?current=${current}`);
    if (result.data.status === true) {
      setList(result.data.data);
      setCurrent(result.data.info.current);
      setPageCount(result.data.info.pageCount);
      setTotal(result.data.info.rowCount);
      setActive("member");
      setLoading(false);
    }
  };

  const fetchActivity = async (current) => {
    const result = await Api.Get(`/dao/activity/${id}?current=${current}`);
    if (result.data.status === true) {
      setList(result.data.data);
      setCurrent(result.data.info.current);
      setPageCount(result.data.info.pageCount);
      setTotal(result.data.info.rowCount);
      setActive("activity");
      setLoading(false);
    }
  };

  const isCalc = (startTime, endTime) => {
    return (
      ((new Date().getTime() - startTime) / (endTime - startTime)) *
      100
    ).toFixed(2);
  };

  const getFile = (image) => {
    form.setFieldsValue({
      image,
    });
  };

  const columns = [
    {
      title: "Member",
      dataIndex: "",
      key: "",
      width: 200,
      ellipsis: true,
      render: (_, record) => (
        <Space>
          <Avatar src={record.employee_id.avatar} style={{ backgroundColor: '#1677ff' }} icon={<UserOutlined />} />
          <Text>{generateUsername(record.employee_id.company_email)}</Text>
        </Space>
      ),
    },
    {
      title: "Description",
      dataIndex: "form",
      key: "form",
      width: 150,
      align: "center",
      render: (_, record) => (
        <Space direction="vertical">
          <Image
            src={`/api/media/activity/${record.activity_id}/${
              JSON.parse(record.form).image
            }`}
            alt={JSON.parse(record.form).image}
            width={"20%"}
          />
          <Space>
            <Text type="secondary">
              <small>Distance: </small>
            </Text>
            <Text strong>
              <small>{JSON.parse(record.form).distance}</small>
            </Text>
          </Space>
        </Space>
      ),
    },
    {
      title: "Created Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (item) => <Text>{moment(item).format("DD/MM/YYYY, HH:mm")}</Text>,
    },
    {
      title: "Status",
      dataIndex: "",
      key: "",
      align: "center",
      tip: "แสดงเฉพาะสถานะ",
      render: (_, record) => {
        return record.status=='approved'? (<Badge status="success" text="Approved" />) :  (record.status=='rejected'? (<Badge status="error" text="Rejected" />) : (<Badge status="processing" text="Waiting" />))
      }
    },
  ];

  const RenderDrawer = ({ data }) => {
    return (
      <DrawerForm
        title={data.title}
        resize={{
          maxWidth: window.innerWidth * 0.8,
          minWidth: 300,
        }}
        form={form}
        trigger={
          <Button type="link" size="small">
            Do It
          </Button>
        }
        drawerProps={{
          destroyOnClose: true,
        }}
        onFinish={async (values) => {
          const response = await Api.Post(
            `/dao/activity_submit/${data._id}`,
            values
          );
          if (response.data.status === true) {
            success({
              centered: true,
              title: "Thank you for your submit.",
              icon: (
                <span className="anticon">
                  <i className="fas fa-check-circle"></i>
                </span>
              ),
              content: (
                <Text>Please wait for an admin to proceed the next step.</Text>
              ),
            });
          }

          return true;
        }}
      >
        <ProForm.Item label="Image" name="image" rules={[{ required: true }]}>
          <Upload getFile={getFile} />
        </ProForm.Item>
        <ProFormDigit
          label="Distance (Km)"
          name="distance"
          min={0.01}
          fieldProps={{ precision: 2 }}
          rules={[{ required: true }]}
        />
      </DrawerForm>
    );
  };

  if (data)
    return (
      <PageContainer className="kuber-dao-detail-page">
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Card
              bordered={false}
              cover={
                <div
                  className="cover"
                  style={{
                    backgroundImage: `url(/api/media/employee/cover-${data._id}.png)`,
                  }}
                >
                  <Image
                    alt="example"
                    src={`/api/media/employee/cover-${data._id}.png`}
                    preview={false}
                  />
                </div>
              }
              bodyStyle={{ padding: "16px 16px 0 " }}
            >
              <Row align={"bottom"}>
                <Col xs={0} md={9}>
                  <Space split={<Divider type="vertical" />}>
                    <Space
                      direction="vertical"
                      size={4}
                      style={{ textAlign: "initial" }}
                    >
                      <Text type="secondary">
                        <small>KBG</small>
                      </Text>
                      <Space size={4}>
                        <Image
                          src={`/token/KBG.png`}
                          width={20}
                          preview={false}
                        />
                        <Text strong>
                          <CountUp end={data.balanceKBG} separator="," />
                        </Text>
                      </Space>
                    </Space>
                    <Space
                      direction="vertical"
                      size={4}
                      style={{ textAlign: "initial" }}
                    >
                      <Text type="secondary">
                        <small>KBC</small>
                      </Text>
                      <Space size={4}>
                        <Image
                          src={`/token/KBC.png`}
                          width={20}
                          preview={false}
                        />
                        <Text strong>
                          <CountUp end={data.balanceKBC} separator="," />
                        </Text>
                      </Space>
                    </Space>

                    <Space
                      direction="vertical"
                      size={4}
                      style={{ textAlign: "initial" }}
                    >
                      <Text type="secondary">
                        <small>Badge</small>
                      </Text>
                      <Space size={4}>
                        <Image
                          src={`/token/${data.token}.png`}
                          width={20}
                          preview={false}
                        />
                        <Text strong>
                          <CountUp end={data.balanceBadge} separator="," />
                        </Text>
                      </Space>
                    </Space>
                  </Space>
                </Col>

                <Col xs={0} md={6} align="center">
                  <div className="avatar">
                    <Avatar src={`/dao/${data.name}.png`} size={100} style={{ backgroundColor: '#1677ff' }} icon={<UserOutlined />}/>
                  </div>
                  <Space direction="vertical">
                    <Title level={5} style={{ margin: 0 }}>
                      {data.name}
                    </Title>
                  </Space>
                </Col>

                <Col xs={24} md={0} align="center">
                  <div className="avatar">
                    <Avatar src={`/dao/${data.name}.png`} size={84} style={{ backgroundColor: '#1677ff' }} icon={<UserOutlined />}/>
                  </div>
                  <Space>
                    <Title level={5} style={{ marginTop: 0 }}>
                      {data.name}
                    </Title>
                  </Space>
                </Col>

                <Col xs={0} md={9} align="right">
                  <Space split={<Divider type="vertical" />}>
                    <Space
                      direction="vertical"
                      size={4}
                      style={{ textAlign: "initial" }}
                    >
                      <Text type="secondary">
                        <small>Wallet</small>
                      </Text>
                      <Paragraph
                        copyable={{
                          text: data.wallet,
                        }}
                        style={{ margin: 0 }}
                      >
                        <small>{addressFormat(data.wallet, 8, 4)}</small>
                      </Paragraph>
                    </Space>
                    <Space
                      direction="vertical"
                      size={4}
                      style={{ textAlign: "initial" }}
                    >
                      <Text type="secondary">
                        <small>Members</small>
                      </Text>
                      <Text strong>
                        <CountUp end={data.members} separator="," />
                      </Text>
                    </Space>
                  </Space>
                </Col>

                <Col xs={24} md={0} align="center">
                  <Space split={<Divider type="vertical" />}>
                    <Space
                      direction="vertical"
                      size={4}
                      style={{ textAlign: "initial" }}
                    >
                      <Text type="secondary">
                        <small>KBG</small>
                      </Text>
                      <Space size={4}>
                        <Image
                          src={`/token/KBG.png`}
                          width={20}
                          preview={false}
                        />
                        <Text strong>
                          <CountUp end={data.balanceKBG} separator="," />
                        </Text>
                      </Space>
                    </Space>
                    <Space
                      direction="vertical"
                      size={4}
                      style={{ textAlign: "initial" }}
                    >
                      <Text type="secondary">
                        <small>KBC</small>
                      </Text>
                      <Space size={4}>
                        <Image
                          src={`/token/KBC.png`}
                          width={20}
                          preview={false}
                        />
                        <Text strong>
                          <CountUp end={data.balanceKBC} separator="," />
                        </Text>
                      </Space>
                    </Space>
                    <Space
                      direction="vertical"
                      size={4}
                      style={{ textAlign: "initial" }}
                    >
                      <Text type="secondary">
                        <small>Badge</small>
                      </Text>
                      <Space size={4}>
                        <Image
                          src={`/token/${data.token}.png`}
                          width={20}
                          preview={false}
                        />
                        <Text strong>
                          <CountUp end={data.balanceBadge} separator="," />
                        </Text>
                      </Space>
                    </Space>
                  </Space>
                </Col>

                <Col xs={24} md={0} align="center">
                  <Paragraph
                    copyable={{
                      text: data.wallet,
                    }}
                    style={{ margin: "8px 0 0" }}
                  >
                    <small>{addressFormat(data.wallet, 8, 8)}</small>
                  </Paragraph>
                </Col>

                <Col span={24}>
                  <Tabs
                    activeKey={active}
                    centered
                    onChange={(key) => {
                      setLoading(true);
                      if (key === "member") fetchMember(1);
                      if (key === "activity") fetchActivity(1);
                    }}
                    items={data.isOwner ? itemsOwner : itemsOther}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={0} md={6}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Avenger data={data.avenger} />
              </Col>
            </Row>
          </Col>

          {loading === true ? (
            <Col xs={24} md={18}>
              <Loading />
            </Col>
          ) : (
            <Col xs={24} md={18}>
              {active === "member" && (
                <Row gutter={[16, 8]}>
                  <Col span={24}>
                    <Card bordered={false} size="small">
                      <Row justify={"space-between"} align={"middle"}>
                        <Col>
                          <Title level={5} style={{ margin: 0 }}>
                            Member
                          </Title>
                        </Col>
                        <Col>
                          <Space align="center">
                            <Text type="secondary">
                              <small>Sort by : </small>
                            </Text>
                            <Select
                              value={"most"}
                              style={{ width: 120 }}
                              options={[{ value: "most", label: "Most Badge" }]}
                            />
                          </Space>
                        </Col>
                      </Row>
                    </Card>
                  </Col>

                  {list.map((item) => (
                    <Col key={item._id} span={24}>
                      <Card
                        bordered={false}
                        size="small"
                        className="card-member"
                      >
                        <div className="cover">
                          <Image
                            alt="example"
                            src={`/api/media/employee/cover-${item._id}.png`}
                            preview={false}
                          />
                        </div>
                        <Row justify={"space-between"} align={"middle"}>
                          <Col>
                            <Space>
                              <Avatar src={item.avatar} size={"large"} style={{ backgroundColor: '#1677ff' }} icon={<UserOutlined />}/>
                              <Space direction="vertical" size={0}>
                                <Link
                                  strong
                                  href={`/profile/${item._id}`}
                                  target="_blank"
                                >
                                  {generateUsername(item.company_email)}
                                </Link>
                                <Paragraph
                                  copyable={{
                                    text: item.wallet[0].address,
                                  }}
                                  style={{ margin: 0 }}
                                >
                                  <small>
                                    {addressFormat(
                                      item.wallet[0].address,
                                      8,
                                      8
                                    )}
                                  </small>
                                </Paragraph>
                              </Space>
                            </Space>
                          </Col>
                          <Col>
                            <Space size={4}>
                              <Text strong>{item.wallet[0].balanceBadge}</Text>
                              <Image
                                src={`/token/${data.token}.png`}
                                width={20}
                                preview={false}
                              />
                            </Space>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  ))}

                  <Col span={24}>
                    {list.length !== 0 ? (
                      <Row justify={"space-between"}>
                        <Col>
                          <Text type="secondary">
                            Showing {(current - 1) * 5 + 1} to{" "}
                            {current < pageCount ? current * 5 : total} of{" "}
                            {total} lists
                          </Text>
                        </Col>
                        <Col>
                          <Pagination
                            size="small"
                            current={current}
                            total={total}
                            pageSize={5}
                            showSizeChanger={false}
                            onChange={(value) => fetchMember(value)}
                          />
                        </Col>
                      </Row>
                    ) : (
                      <Empty />
                    )}
                  </Col>
                </Row>
              )}
              {active === "activity" && (
                <Row gutter={[16, 8]}>
                  <Col span={24}>
                    <Card bordered={false} size="small">
                      <Row justify={"space-between"} align={"middle"}>
                        <Col>
                          <Title level={5} style={{ margin: 0 }}>
                            Activity
                          </Title>
                        </Col>
                        <Col>
                          <Space align="center">
                            <Text type="secondary">
                              <small>Sort by : </small>
                            </Text>
                            <Select
                              value={"latest"}
                              style={{ width: 120 }}
                              options={[{ value: "latest", label: "Latest" }]}
                            />
                          </Space>
                        </Col>
                      </Row>
                    </Card>
                  </Col>

                  {list.map((item) => (
                    <Col key={item._id} span={24}>
                      <Card
                        bordered={false}
                        size="small"
                        bodyStyle={{ padding: "8px 16px" }}
                      >
                        <Row justify={"space-between"} align={"middle"}>
                          <Col>
                            <Space>
                              <Avatar
                                src={`/activity/${item.activity_type}.png`}
                                size={"large"}
                                shape="square"
                                style={{ backgroundColor: '#1677ff' }} icon={<TrophyOutlined />}
                              />
                              <Space direction="vertical" size={0}>
                                <Text strong>{item.title}</Text>
                                <Text type="secondary">
                                  <small>{item.description}</small>
                                </Text>
                              </Space>
                            </Space>
                          </Col>
                          <Col>
                            <Space direction="vertical" size={0}>
                              {new Date(item.start_date).getTime() <=
                              new Date().getTime() ? (
                                new Date(item.end_date).getTime() >
                                new Date().getTime() ? (
                                  <>
                                    <Space size={0}>
                                      <RenderDrawer data={item} />
                                      <History
                                        columns={columns}
                                        route={`/dao/activity_history/${item._id}`}
                                        trigger={
                                          <Button type="text" size="small">
                                            History
                                          </Button>
                                        }
                                      />
                                      <History
                                        columns={columns}
                                        route={`/dao/activity_history_self/${item._id}`}
                                        trigger={
                                          <Button type="text" size="small">
                                            My Record
                                          </Button>
                                        }
                                      />
                                    </Space>
                                    <Progress
                                      percent={isCalc(
                                        new Date(item.start_date).getTime(),
                                        new Date(item.end_date).getTime()
                                      )}
                                      status="active"
                                      format={() => (
                                        <Tooltip
                                          title={`Starting from ${moment(
                                            item.start_date
                                          ).format(
                                            "DD/MM/YYYY, HH:mm"
                                          )} to ${moment(item.end_date).format(
                                            "DD/MM/YYYY, HH:mm"
                                          )}`}
                                        >
                                          <Button
                                            type="text"
                                            size="small"
                                            shape="circle"
                                            icon={
                                              <i className="fad fa-clock"></i>
                                            }
                                          ></Button>
                                        </Tooltip>
                                      )}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <Space size={0}>
                                      <History
                                        columns={columns}
                                        route={`/dao/activity_history/${item._id}`}
                                        trigger={
                                          <Button type="text" size="small">
                                            History
                                          </Button>
                                        }
                                      />
                                      <History
                                        columns={columns}
                                        route={`/dao/activity_history_self/${item._id}`}
                                        trigger={
                                          <Button type="text" size="small">
                                            My Record
                                          </Button>
                                        }
                                      />
                                    </Space>
                                    <Progress percent={100} status="success" />
                                  </>
                                )
                              ) : (
                                <Progress percent={0} />
                              )}
                            </Space>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  ))}

                  <Col span={24}>
                    {list.length !== 0 ? (
                      <Row justify={"space-between"}>
                        <Col>
                          <Text type="secondary">
                            Showing {(current - 1) * 5 + 1} to{" "}
                            {current < pageCount ? current * 5 : total} of{" "}
                            {total} lists
                          </Text>
                        </Col>
                        <Col>
                          <Pagination
                            size="small"
                            current={current}
                            total={total}
                            pageSize={5}
                            showSizeChanger={false}
                            onChange={(value) => fetchActivity(value)}
                          />
                        </Col>
                      </Row>
                    ) : (
                      <Empty />
                    )}
                  </Col>
                </Row>
              )}
            </Col>
          )}
        </Row>
      </PageContainer>
    );
}
