import "./index.css";
import {
  Row,
  Col,
  Carousel,
  Typography,
  Image,
  Card,
  Space,
  Divider,
  Avatar,
  Button,
} from "antd";

const { Title, Text, Paragraph } = Typography;

export default function Page() {
  return (
    <Row gutter={[16, 16]} className="kuber-login-page" align={"middle"}>
      <Col xs={0} md={12} lg={16}>
        <div className="carousel">
          <Carousel effect="fade" autoplay>
            <div>
              <Image src={"/landing/airdrop.png"} preview={false} />
              <Title
                level={4}
                style={{ marginBlockStart: 0, marginBlockEnd: 48 }}
              >
                คุณจะได้รับ 100 KBS (Kuber Silver) ต่อเดือน
                เพื่อส่งให้กับเพื่อนร่วมงานที่คุณ
                <br />
                อยากชื่นชม ขอบคุณ และให้กำลังใจกัน!
              </Title>
            </div>
            <div>
              <Image src={"/landing/send.png"} preview={false} />
              <Title
                level={4}
                style={{ marginBlockStart: 0, marginBlockEnd: 48 }}
              >
                คุณสามารถส่งเหรียญให้กับเพื่อนร่วมงานได้สูงสุด
                <br />
                คนละ 5 KBS/คน/เดือน!
              </Title>
            </div>

            <div>
              <Image src={"/landing/redeem.png"} preview={false} />
              <Title
                level={4}
                style={{ marginBlockStart: 0, marginBlockEnd: 48 }}
              >
                เหรียญ KBS จะถูก Swap เป็น KBG (Kuber Gold)
                <br />
                สามารถสะสมและนำมาแลกของรางวัลที่คุณต้องการได้เลย!
              </Title>
            </div>

            <div>
              <Image src={"/landing/swap.png"} preview={false} />
              <Title
                level={4}
                style={{ marginBlockStart: 0, marginBlockEnd: 48 }}
              >
                เหรียญ KBC (Kuber Coin) จะได้จากการที่ทีมสี (HODL Team)
                ของคุณเป็นผู้ชนะ
                <br />
                หรือจากการ Swap เหรียญ KBG
              </Title>
            </div>

            <div>
              <Image src={"/landing/exchange.png"} preview={false} />
              <Title
                level={4}
                style={{ marginBlockStart: 0, marginBlockEnd: 48 }}
              >
                คุณสามารถนำ KBC มาแลกเป็นเงินสด
                <br />
                หรือแลกของรางวัลอื่นๆได้ใน Version ถัดไป รอติดตามได้เล้ย!
              </Title>
            </div>
          </Carousel>
        </div>
      </Col>
      <Col xs={24} md={12} lg={8} align="center">
        <Card className="card-form" bordered={false}>
          <Space direction="vertical" size={"large"}>
            <Space direction="vertical">
              <Space>
                <Avatar src="/icon-512x512.png" size={"large"} />
                <Title style={{ margin: 0 }} level={3}>
                  KUBER
                </Title>
              </Space>
              <Text type="secondary">Complete FinTech Ecosystem</Text>
            </Space>

            <Space direction="vertical">
              <Title level={2} style={{ margin: 0 }} align="center">
                KUBER for Bitkubers
              </Title>
              <Paragraph align="center" style={{ margin: 0 }} type="secondary">
                To build a strong company's culture and deliver Gamification
                throught Blockchain technology
              </Paragraph>
            </Space>

            <a href="/api/auth/redirect">
              <Button
                icon={<Avatar src="/icon/google.png" size={"small"} />}
                size="large"
                shape="round"
                block
              >
                Sign in with Google
              </Button>
            </a>

            <Space direction="vertical" style={{ display: "flex" }}>
              <Divider plain>
                <Text strong>What we have</Text>
              </Divider>
              <Space align="center" size={24}>
                <Space
                  direction="vertical"
                  style={{ display: "flex", textAlign: "center" }}
                >
                  <i className="fad fa-user-friends"></i>
                  <Text type="secondary">Member</Text>
                </Space>

                <Space
                  direction="vertical"
                  style={{ display: "flex", textAlign: "center" }}
                >
                  <i className="fad fa-send-backward"></i>
                  <Text type="secondary">NFT</Text>
                </Space>
                <Space
                  direction="vertical"
                  style={{ display: "flex", textAlign: "center" }}
                >
                  <i className="fad fa-bullseye-arrow"></i>
                  <Text type="secondary">Mission</Text>
                </Space>
              </Space>
            </Space>
          </Space>
        </Card>
      </Col>
    </Row>
  );
}
