import { useState } from "react";
import { PageContainer } from "@ant-design/pro-components";
import { Link } from "react-router-dom";
import { Download, Table } from "components";
import { Space, Avatar, Typography, Image, Select, Modal, Popconfirm, Button } from "antd";
import { useRef } from "react";
import moment from "moment-timezone";
import { Api } from "services";
import { useDocumentTitle } from "hooks";

const { Text } = Typography;

export default function Page() {
  useDocumentTitle("Management Fee Credit");
  const actionRef = useRef();
  const [active, setActive] = useState("redemption");
  const [statusMessage, setStatusMessage] = useState(false);

  const onChange = (key) => {
    setActive(key);
  };

  const handleStatus = async () => {
    const id = statusMessage
    const response = await Api.Get(`/fee_credit/edit_status/${id}`);
    if (response.data.status === true) {
      setStatusMessage(false)
      actionRef.current.reload();
    }else{
      setStatusMessage(false)
    }
  };

  const changeStatus = async (id) => {
    setStatusMessage(id)
  };



  const columnsRedemption = [
    {
      title: "Date Time",
      dataIndex: "created_at",
      key: "created_at",
      render: (item) => (
        <Text>{moment(item).format("DD/MM/YYYY, HH:mm:ss")}</Text>
      ),
    },

    {
      title: "Employee Id",
      dataIndex: "employee_id",
      key: "employee_id",
      align: "center",
    },
    {
      title: "Employee",
      dataIndex: "",
      key: "",
      render: (_, record) => (
        <Space>
          <Avatar src={record.avatar} alt="" />
          <Text>{record.company_email.split("@")[0]}</Text>
        </Space>
      ),
    },

    {
      title: "Trading Email",
      dataIndex: "trading_email",
      key: "trading_email",
    },
    {
      title: "Item",
      dataIndex: "value",
      key: "value",
      render: (item) => (
        <Space>
          <Image
            src={`/api/static/items/feecredit-${item}.png`}
            alt={item}
            width={28}
          />
          <Text>{`Fee Credit ${item} THB`}</Text>
        </Space>
      ),
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      align: "right",
    },
  ];



  const columnsList=[
    {
      title: "Date Time",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (item) => (
        <Text>{moment(item).format("DD/MM/YYYY, HH:mm:ss")}</Text>
      ),
    },
    {
      title: "Employee",
      dataIndex: "",
      key: "",
      width:400,
      render: (_, record) => (
  
            <Space>
              <Avatar src={record.avatar} alt="" />
              <Space direction="vertical">
                <Text><strong>{record.company_email.split("@")[0]} </strong><br />Trading Email : {record.trading_email}</Text>
              </Space>
            </Space>
      
  
      ),
    },
    {
      title: "Family Information",
      dataIndex: "trading_fullname",
      key: "trading_fullname",
      width:300,
      render: (_, record) => (
        <Space direction="vertical">
          <Link
            to={record.trading_doc}
            target="_blank"
            rel="noopener noreferrer"
          ><Text>{record.trading_fullname} ({record.trading_relation})</Text></Link>
          <Text>Email : {record.trading_email_family}</Text>
        </Space>
      ),
    },
    {
      title: "Document",
      dataIndex: "trading_doc",
      key: "trading_doc",
      align: "center",
      render: (_, record) => (
        <Link
            to={record.trading_doc}
            target="_blank"
            rel="noopener noreferrer"
          ><Button type="primary">Document</Button></Link>
      ),
    },
    {
      title: "Status",
      dataIndex: "",
      key: "",
      align: "center",
      render: (_, record) => {
        return record.trading_status!='approve'? (
          <Select onChange={changeStatus} defaultValue={record._id+'_'+record.trading_status} options={[{ value: record._id+'_waiting', label: 'Waiting' },{ value: record._id+'_approve', label: 'Approve' },{ value: record._id+'_reject', label: 'Reject' }]} />
        ) : (<Text>{record.trading_status.toUpperCase()}</Text>)
      }
    }
  ];



  return (
    <PageContainer
      header={{
        title: "Fee Credit",
        ghost: true,
        breadcrumb: {
          items: [
            {
              href: "/management",
              title: "Management",
            },
            {
              title: "Fee Credit",
            },
          ],
        },
        extra: [
          <Download
            key={"download"}
            route="/fee_credit/download"
            columns={[
              "timestamp",
              "employee_id",
              "company_email",
              "trading_email",
              "item",
              "value",
            ]}
          />,
        ],
      }}
      tabProps={{
        onChange,
      }}
      tabBarExtraContent={<Text>โชว์รายละเอียดเกี่ยวกับ Fee Credit</Text>}
      tabList={[
        {
          tab: "Redemption",
          key: "redemption",
        },
        {
          tab: "List",
          key: "list",
        },
      ]}
    >
      {active === "redemption" && (
        <Table
          columns={columnsRedemption}
          route={"/fee_credit/redemption"}
          actionRef={actionRef}
          keyword={`email`}
        />
      )}
      {active === "list" && (
        <Table
          columns={columnsList}
          route={"/fee_credit/docs_list"}
          actionRef={actionRef}
          keyword={`email`}
        />
      )}
      {statusMessage && (<Modal
          title="Modal"
          open={statusMessage}
          onOk={handleStatus}
          onCancel={()=>setStatusMessage(false)}
          okText="Submit"
          cancelText="Cancel"
        >
          <p>ยืนยันการทำรายการ {statusMessage.split('_')[1]} หรือไม่?</p>
        </Modal>
      )}
    </PageContainer>
  );
}
