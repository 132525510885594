import {
  ModalForm,
  ProFormDatePicker,
  PageContainer,
  ProFormText,
  ProFormSelect,
  ProFormGroup,
} from "@ant-design/pro-components";
import { Table, Drawer, Waiting, Download } from "components";
import {
  Button,
  Space,
  Tag,
  Avatar,
  Typography,
  Tooltip,
  Form,
  message,
} from "antd";
import { useRef } from "react";
import moment from "moment-timezone";
import { useDocumentTitle } from "hooks";
import { Link } from "react-router-dom";

const { Text } = Typography;

export default function Page() {
  useDocumentTitle("Management Survey");
  const actionRef = useRef();

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },

    {
      title: "Created Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (item) => (
        <Text>{moment(item).format("DD-MM-YYYY, HH:mm:ss")}</Text>
      ),
    },
    {
      title: "Start - End",
      dataIndex: "",
      key: "",
      render: (_, record) => (
        <Text>{`${moment(record.start_time).format(
          "DD-MM-YYYY, HH:mm:ss"
        )} - ${moment(record.end_time).format("DD-MM-YYYY, HH:mm:ss")}`}</Text>
      ),
    },

    {
      title: "Anonymous",
      dataIndex: "anonymous",
      key: "anonymous",
      align: "center",
      render: (item) => (
        <Text type={item === true ? "success" : "danger"}>
          <i className={item === true ? "far fa-check" : "far fa-times"}></i>
        </Text>
      ),
    },

    {
      title: "Options",
      key: "",
      align: "center",
      render: (_, record) => (
        <Link to={`/management/survey/${record._id}`}>
          <i className="far fa-file-search"></i>
        </Link>
      ),
    },
  ];

  return (
    <PageContainer
      header={{
        title: "Survey",
        ghost: true,
        breadcrumb: {
          items: [
            {
              href: "/management",
              title: "Management",
            },
            {
              title: "Survey",
            },
          ],
        },
      }}
    >
      <Table
        columns={columns}
        route={"/survey/list_all"}
        actionRef={actionRef}
        keyword={`title`}
      />
    </PageContainer>
  );
}
