import CountUp from "react-countup";
import { Row, Col, Card, Space, Typography, Image } from "antd";

const { Text } = Typography;
const NFTMission = ({ data }) => {
  return (
    <Card title="NFT Mission" bordered={false}>
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <Row justify={"space-between"} align={"middle"}>
            <Col>
              <Image
                src="/nft/common.png"
                width={40}
                preview={false}
                alt="common"
              />
            </Col>
            <Col align="right">
              <Space direction="vertical" size={2}>
                <Text type="secondary">
                  <small>Common Level</small>
                </Text>
                <Text strong>
                  <CountUp end={data["nft_c"]} separator="," />
                </Text>
              </Space>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row justify={"space-between"} align={"middle"}>
            <Col>
              <Image
                src="/nft/rare.png"
                width={40}
                preview={false}
                alt="rare"
              />
            </Col>
            <Col align="right">
              <Space direction="vertical" size={2}>
                <Text type="secondary">
                  <small>Rare Level</small>
                </Text>
                <Text strong>
                  <CountUp end={data["nft_r"]} separator="," />
                </Text>
              </Space>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row justify={"space-between"} align={"middle"}>
            <Col>
              <Image
                src="/nft/superrare.png"
                width={40}
                preview={false}
                alt="superrare"
              />
            </Col>
            <Col align="right">
              <Space direction="vertical" size={2}>
                <Text type="secondary">
                  <small>Super Rare Level</small>
                </Text>
                <Text strong>
                  <CountUp end={data["nft_sr"]} separator="," />
                </Text>
              </Space>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row justify={"space-between"} align={"middle"}>
            <Col>
              <Image
                src="/nft/ultrarare.png"
                width={40}
                preview={false}
                alt="ultrarare"
              />
            </Col>
            <Col align="right">
              <Space direction="vertical" size={2}>
                <Text type="secondary">
                  <small>Ultra Rare Level</small>
                </Text>
                <Text strong>
                  <CountUp end={data["nft_ur"]} separator="," />
                </Text>
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default NFTMission;
