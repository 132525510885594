import "./index.css";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { PageContainer } from "@ant-design/pro-components";
import {
  Typography,
  Card,
  Row,
  Col,
  Space,
  Tabs,
  Tooltip,
  Avatar,
  Button,
  Popconfirm,
  Modal,
} from "antd";
import moment from "moment-timezone";
import { useDocumentTitle } from "hooks";
import { Api } from "services";
import { Loading } from "components";
import { socket } from "socket";
import { Waiting } from "components";

const { success, warning } = Modal;
const { Title, Text, Paragraph } = Typography;

export default function Page({ employee }) {
  useDocumentTitle("Booking");
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [seconds, setSeconds] = useState(0);
  const [intervalId, setIntervalId] = useState(0);
  const [open, setOpen] = useState(true);
  const [waiting, setWaiting] = useState(false);

  useEffect(() => {
      fetchData();
  }, []);

  const fetchData = async () => {
    const response = await Api.Get(`/booking/list`);
    if (response?.data?.status === true) {
      setData(response.data.data);
      setLoading(false);
      setWaiting(false);
    }
  };

  if (loading) return <Loading />;

  return (
    <PageContainer
      className="kuber-booking-page"
      header={{
        title: "Booking",
        ghost: true,
        breadcrumb: {
          items: [
            {
              href: "/profile",
              title: "My Profile",
            },
            {
              title: "Booking",
            },
          ],
        },
      }}
    >
      <Row gutter={[16, 16]}>
          {data.map((item) => {
            var expired = (moment(item.end_time).unix() < moment().unix() )
            return (
            <Col key={item._id} xs={12} sm={8} md={6}>
              <Card bordered={false} size="small">
                <Row gutter={[8, 8]}>
                  {/*<Col span={24}>
                    <div
                      className="cover"
                      style={{
                        backgroundImage: `url(/api/static/items/feecredit-${item.value}.png)`,
                      }}
                    >
                      <Image
                        src={`/api/static/items/feecredit-${item.value}.png`}
                        alt={item.name}
                        preview={false}
                      />
                    </div>
                  </Col>*/}
                  <Col span={24} style={{minHeight:120}}>
                    <Space direction="vertical" size={0}>
                      <Text strong>
                        {item.name}
                      </Text>
                    </Space>
                  </Col>
                  <Col span={24}>
                    <Space direction="vertical" size={0}>
                      <Text type="secondary">
                      Start : {moment(item.start_time).format('YYYY-MM-DD HH:mm:ss')}
                      </Text>
                      <Text type="secondary">
                      End : {moment(item.end_time).format('YYYY-MM-DD HH:mm:ss')}
                      </Text>
                    </Space>
        
                  </Col>

                  <Col span={24}>
                    {(moment(item.start_time).unix() > moment().unix()) ? (
                    <Link to={`/booking/${item._id.toString()}`}>
                      <Button
                        block
                      >
                        Soon
                      </Button>
                    </Link>
                    ) : (
                      <Link to={`/booking/${item._id.toString()}`}>
                        <Button
                          block
                          type={expired? "button" : "primary"}
                        >
                          {expired? "Expired" : "Booking"}
                        </Button>
                      </Link>
                    )}
                  </Col>
                </Row>
              </Card>
            </Col>
          )})}
        </Row>
    </PageContainer>
  );
}
