import "./index.css";
import { useState, useEffect } from "react";
import {
  PageContainer,
  ProCard,
  ProForm,
  ProFormSelect,
  ProFormTextArea,
  ProFormRadio,
  ProDescriptions,
} from "@ant-design/pro-components";
import { Row, Col, Typography, Card, Modal, Form, Alert } from "antd";
import { Api } from "services";
import { useDocumentTitle } from "hooks";

const { Title, Text } = Typography;
const { success } = Modal;

export default function Page() {
  useDocumentTitle("Survey");
  const [form] = Form.useForm();
  const [list, setList] = useState([]);
  const [selected, setSelected] = useState();

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setSelected(list[0]);
  }, [list]);

  const BuildForm = ({ item, number }) => {
    if (item.type === "topic")
      return (
        <ProForm.Item>
          <Title level={5} style={{ margin: 0 }}>
            {item.label}
          </Title>
        </ProForm.Item>
      );

    if (item.type === "subTopic" && item.hidden === false)
      return (
        <ProForm.Item>
          <Text strong>{item.label}</Text>
        </ProForm.Item>
      );

    if (item.type === "select" && item.hidden === false)
      return (
        <ProFormSelect
          name={`${item.type.toLowerCase()}_${number}`}
          label={item.label}
          options={item.options.map((subItem, index) => ({
            label: subItem,
            value: index,
          }))}
          rules={[
            {
              required: true,
              message: "",
            },
          ]}
        />
      );

    if (item.type === "textArea" && item.hidden === false)
      return (
        <ProFormTextArea
          name={`${item.type.toLowerCase()}_${number}`}
          label={item.label}
          rules={[
            {
              required: true,
              message: "",
            },
          ]}
        />
      );

    if (item.type === "radio")
      return (
        <ProFormRadio.Group
          name={`${item.type.toLowerCase()}_${number}`}
          label={item.label}
          options={item.options.map((subItem, index) => ({
            label: subItem,
            value: index,
          }))}
          rules={[
            {
              required: true,
              message: "",
            },
          ]}
          onChange={(e) => {
            if (item.conditions && item.conditions.length !== 0) {
              let newValue = [...list];

              let allArray = item.conditions[0].concat(item.conditions[1]);

              for (let index = 0; index < allArray.length; index++) {
                newValue[0].form.map((subItem) => {
                  if (subItem.label === allArray[index]) {
                    subItem.hidden = true;
                  }
                });
              }

              for (
                let index = 0;
                index < item.conditions[e.target.value].length;
                index++
              ) {
                newValue[0].form.map((subItem) => {
                  if (
                    subItem.label === item.conditions[e.target.value][index]
                  ) {
                    subItem.hidden = false;
                  }
                });
              }
              setList(newValue);
            }
          }}
        />
      );

    if (item.type === "multiSelect")
      return (
        <ProFormSelect
          max={3}
          mode="multiple"
          name={`${item.type.toLowerCase()}_${number}`}
          label={item.label}
          options={item.options.map((subItem, index) => ({
            label: subItem,
            value: index,
          }))}
          rules={[
            {
              required: true,
              message: "",
            },
            {
              validator: (_, value) => {
                if (value) {
                  if (value.length !== 3) return Promise.reject();
                  return Promise.resolve();
                }
              },
            },
          ]}
        />
      );
  };

  const fetchData = async () => {
    const response = await Api.Get(`/survey/list`);
    if (response.data.status === true) {
      setList(response.data.data);
      handleSelect(response.data.data[0]);
    }
  };

  const handleSelect = (data) => {
    if (data.answer) {
      form.setFieldsValue(data.answer);

      for (let index = 0; index < data.form.length; index++) {
        const element = data.form[index];
        if (element.type === "radio") {
          const value = data.answer[element.type + "_" + (index + 1)];
          for (
            let subIndex = 0;
            subIndex < element.conditions[value].length;
            subIndex++
          ) {
            const subElement = element.conditions[value][subIndex];
            data.form.map((subItem) => {
              if (subItem.label === subElement) {
                subItem.hidden = false;
              }
            });
          }
        }
      }
    }

    setSelected(data);
  };

  const handleFinish = async (values) => {
    const response = await Api.Post(`/survey/submit`, { form: values });
    if (response.data.status === true) {
      success({
        title: "The survey has been submitted successfully.",
        onOk: () => {
          fetchData();
        },
      });
    }
  };

  return (
    <PageContainer
      className="kuber-survey-page"
      header={{
        title: "Survey",
        ghost: true,
        breadcrumb: {
          items: [
            {
              path: "",
              title: "My Profile",
            },
            {
              title: "Survey",
            },
          ],
        },
      }}
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <ProCard split="vertical">
            <ProCard
              title={<Text>All Survey</Text>}
              extra={<Text>{list.length}</Text>}
              colSpan="30%"
            >
              <ul className="list">
                {list.map((item, index) => (
                  <li key={index}>
                    <i className="fad fa-poll-h"></i>
                    <small>{item.title}</small>
                  </li>
                ))}
              </ul>
            </ProCard>
            <ProCard
              title={<Text strong>{selected?.title}</Text>}
              headerBordered
            >
              <div className="container">
                <Card bordered={false}>
                  {selected && (
                    <ProForm
                      form={form}
                      onFinish={handleFinish}
                      readonly={selected.answer}
                      submitter={selected.answer ? false : true}
                    >
                      <ProForm.Item>
                        <ProDescriptions
                          title={"Personal Information"}
                          bordered
                          size="small"
                        >
                          <ProDescriptions.Item label="Name - Surname">
                            {`${selected.name} ${selected.surname}`}
                          </ProDescriptions.Item>
                          <ProDescriptions.Item label="Team">
                            {selected.team}
                          </ProDescriptions.Item>
                        </ProDescriptions>
                      </ProForm.Item>

                      {selected.form.map((item, index) => (
                        <BuildForm key={index} item={item} number={index + 1} />
                      ))}

                      {selected.created_at && (
                        <Alert
                          message={`You submitted this form on ${selected.created_at}`}
                          type="success"
                          showIcon
                        />
                      )}
                    </ProForm>
                  )}
                </Card>
              </div>
            </ProCard>
          </ProCard>
        </Col>
      </Row>
    </PageContainer>
  );
}
