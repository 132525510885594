import { useRef } from "react";
import { useParams } from "react-router-dom";
import { PageContainer, ProDescriptions } from "@ant-design/pro-components";
import { Table } from "components";
import { Space, Avatar, Typography, Button, Col, Card, Row } from "antd";
import moment from "moment-timezone";
import { useDocumentTitle } from "hooks";
import { Api } from "services";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

const { Text } = Typography;

export default function Page() {
  const { id } = useParams();
  useDocumentTitle(`Survey ID: ${id}`);
  const actionRef = useRef();

  const columns = [
    {
      title: "Email",
      dataIndex: "",
      key: "",
      render: (_, record) => (
        <Space>
          <Avatar src={record.employee_id.avatar} alt="" />
          <Text>{record.employee_id.company_email}</Text>
        </Space>
      ),
    },

    {
      title: "First Name",
      dataIndex: "",
      key: "",
      render: (_, record) => <Text>{record.employee_id.first_name_en}</Text>,
    },

    {
      title: "Last Name",
      dataIndex: "",
      key: "",
      render: (_, record) => <Text>{record.employee_id.last_name_en}</Text>,
    },

    {
      title: "Created Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (item) => (
        <Text>{moment(item).format("DD-MM-YYYY, HH:mm:ss")}</Text>
      ),
    },
  ];

  const handleDownload = async () => {
    const response = await Api.Get(`/survey/download/${id}`);
    if (response.data.status === true) {
      let history = response.data.history;
      const wb = new ExcelJS.Workbook();
      const worksheet = wb.addWorksheet();
      worksheet.columns = response.data.columns;
      for (let index = 0; index < history.length; index++) {
        const element = history[index];
        let data = JSON.parse(element.form);
        data.company_email = element.employee_id.company_email;
        data.created_at = element.created_at;
        worksheet.addRow(data);
      }

      var buf = await wb.xlsx.writeBuffer();
      saveAs(new Blob([buf]), `report.xlsx`);
    }
  };

  return (
    <PageContainer
      header={{
        ghost: true,
        breadcrumb: {
          items: [
            {
              href: "/management",
              title: "Management",
            },
            {
              href: "/management/survey",
              title: "Survey",
            },
            {
              title: `Survey ID: ${id}`,
            },
          ],
        },
      }}
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card bordered={false}>
            <ProDescriptions title="Survey Infomation">
              <ProDescriptions.Item valueType="option">
                <Button
                  key="primary"
                  type="primary"
                  icon={<i className="far fa-arrow-to-bottom"></i>}
                  onClick={() => handleDownload()}
                >
                  Download .xlsx
                </Button>
              </ProDescriptions.Item>
            </ProDescriptions>
          </Card>
        </Col>
        <Col span={24}>
          <Table
            columns={columns}
            route={`/survey/history/${id}`}
            actionRef={actionRef}
            keyword={`email`}
          />
        </Col>
      </Row>
    </PageContainer>
  );
}
