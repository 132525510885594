const Loading = ({ color }) => {
  return (
    <div className="preloader">
      <div className="preloader__ring">
        <div
          className="preloader__sector"
          style={{ color: color ? color : "#24d37d" }}
        >
          L
        </div>
        <div
          className="preloader__sector"
          style={{ color: color ? color : "#24d37d" }}
        >
          o
        </div>
        <div
          className="preloader__sector"
          style={{ color: color ? color : "#24d37d" }}
        >
          a
        </div>
        <div
          className="preloader__sector"
          style={{ color: color ? color : "#24d37d" }}
        >
          d
        </div>
        <div
          className="preloader__sector"
          style={{ color: color ? color : "#24d37d" }}
        >
          i
        </div>
        <div
          className="preloader__sector"
          style={{ color: color ? color : "#24d37d" }}
        >
          n
        </div>
        <div
          className="preloader__sector"
          style={{ color: color ? color : "#24d37d" }}
        >
          g
        </div>
        <div
          className="preloader__sector"
          style={{ color: color ? color : "#24d37d" }}
        >
          .
        </div>
        <div
          className="preloader__sector"
          style={{ color: color ? color : "#24d37d" }}
        >
          .
        </div>
        <div
          className="preloader__sector"
          style={{ color: color ? color : "#24d37d" }}
        >
          .
        </div>
        <div className="preloader__sector"></div>
        <div className="preloader__sector"></div>
        <div className="preloader__sector"></div>
        <div className="preloader__sector"></div>
        <div className="preloader__sector"></div>
        <div className="preloader__sector"></div>
        <div className="preloader__sector"></div>
        <div className="preloader__sector"></div>
        <div className="preloader__sector"></div>
        <div className="preloader__sector"></div>
        <div className="preloader__sector"></div>
        <div className="preloader__sector"></div>
        <div className="preloader__sector"></div>
        <div className="preloader__sector"></div>
        <div className="preloader__sector"></div>
        <div className="preloader__sector"></div>
        <div className="preloader__sector"></div>
        <div className="preloader__sector"></div>
        <div className="preloader__sector"></div>
        <div className="preloader__sector"></div>
      </div>
      <div className="preloader__ring">
        <div
          className="preloader__sector"
          style={{ color: color ? color : "#24d37d" }}
        >
          L
        </div>
        <div
          className="preloader__sector"
          style={{ color: color ? color : "#24d37d" }}
        >
          o
        </div>
        <div
          className="preloader__sector"
          style={{ color: color ? color : "#24d37d" }}
        >
          a
        </div>
        <div
          className="preloader__sector"
          style={{ color: color ? color : "#24d37d" }}
        >
          d
        </div>
        <div
          className="preloader__sector"
          style={{ color: color ? color : "#24d37d" }}
        >
          i
        </div>
        <div
          className="preloader__sector"
          style={{ color: color ? color : "#24d37d" }}
        >
          n
        </div>
        <div
          className="preloader__sector"
          style={{ color: color ? color : "#24d37d" }}
        >
          g
        </div>
        <div
          className="preloader__sector"
          style={{ color: color ? color : "#24d37d" }}
        >
          .
        </div>
        <div
          className="preloader__sector"
          style={{ color: color ? color : "#24d37d" }}
        >
          .
        </div>
        <div
          className="preloader__sector"
          style={{ color: color ? color : "#24d37d" }}
        >
          .
        </div>
        <div className="preloader__sector"></div>
        <div className="preloader__sector"></div>
        <div className="preloader__sector"></div>
        <div className="preloader__sector"></div>
        <div className="preloader__sector"></div>
        <div className="preloader__sector"></div>
        <div className="preloader__sector"></div>
        <div className="preloader__sector"></div>
        <div className="preloader__sector"></div>
        <div className="preloader__sector"></div>
        <div className="preloader__sector"></div>
        <div className="preloader__sector"></div>
        <div className="preloader__sector"></div>
        <div className="preloader__sector"></div>
        <div className="preloader__sector"></div>
        <div className="preloader__sector"></div>
        <div className="preloader__sector"></div>
        <div className="preloader__sector"></div>
        <div className="preloader__sector"></div>
        <div className="preloader__sector"></div>
      </div>
    </div>
  );
};

export default Loading;
