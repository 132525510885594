const LayoutOther = ({ children }) => {
  return (
    <div
      style={{
        height: "100vh",
        position: "relative",
        backgroundColor: "#24d37d",
        overflow: "hidden",
      }}
    >
      <ul className="bg-bubbles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
      {children}
    </div>
  );
};

export default LayoutOther;
