import { Result, Image } from "antd";

export const NotFound = () => {
  return (
    <Result
      icon={
        <Image src="/status/404.png" preview={false} alt="404" width={"30%"} />
      }
      title="Sorry, the page you visited does not exist."
    />
  );
};
