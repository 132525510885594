import { Upload as AntUpload, Space } from "antd";
import ImgCrop from "antd-img-crop";
import { useState } from "react";
import { Api } from "services";

const Upload = ({ aspect, getFile }) => {
  const [fileList, setFileList] = useState([]);

  const uploadImage = async (options) => {
    const { onSuccess, onProgress, setProgress } = options;
    const fmData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" },
      onUploadProgress: (event) => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        setProgress(percent);
        if (percent === 100) {
          setTimeout(() => setProgress(0), 1000);
        }
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };
    fmData.append("file", options.file);
    try {
      const res = await Api.Post("/upload/temp", fmData, config);
      if (res.data.status === true) {
        setFileList([...fileList, res.data.data]);
        onSuccess("Ok");
        getFile(res.data.data.name);
      } else {
        //setMsg(res.data.msg);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const onRemove = async (file) => {
    const response = await Api.Get(`/media/delete_temp/${file.name}`);
    if (response.data.status === true) {
      setFileList([]);
      getFile(null);
    }
  };

  if (aspect)
    return (
      <ImgCrop rotationSlider aspect={aspect}>
        <AntUpload
          accept="image/png, image/jpg"
          listType="picture-card"
          fileList={fileList}
          customRequest={uploadImage}
          onRemove={onRemove}
        >
          {fileList.length < 1 && (
            <Space direction="vertical" size={0}>
              <span>+ Upload</span>
              <small>{"ไม่เกิน 2MB"}</small>
            </Space>
          )}
        </AntUpload>
      </ImgCrop>
    );

  return (
    <AntUpload
      accept="image/png, image/jpg"
      listType="picture-card"
      fileList={fileList}
      customRequest={uploadImage}
      onRemove={onRemove}
    >
      {fileList.length < 1 && (
        <Space direction="vertical" size={0}>
          <span>+ Upload</span>
          <small>{"ไม่เกิน 2MB"}</small>
        </Space>
      )}
    </AntUpload>
  );
};
export default Upload;
